import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { getFileUrl, storagePaths } from './model/storage/storage'
import { downloadFile, downloadFromApi } from './download'

export const DATE_FORMAT = 'YYYY/MM/DD'
export const DATE_TIME_FORMAT = DATE_FORMAT + ' HH:mm:ss'
export const PLATFORM_DATE_TIME_FORMAT = 'MMM D, YYYY h:mm:ss A'
export const PLATFORM_DATE_FORMAT = 'MMM DD, YYYY'

const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

dayjs.extend(utc)
dayjs.extend(timezone)

// parse UTC time only
export function getFormattedDateTime(
  dateTime,
  inputPattern,
  outputPattern,
  toUtc
) {
  const obj = dayjs.utc(dateTime ?? undefined, inputPattern ?? undefined)
  if (toUtc) {
    return obj.format(outputPattern ?? DATE_TIME_FORMAT)
  } else {
    return obj.tz(timeZone).format(outputPattern ?? DATE_TIME_FORMAT)
  }
}
export function getCurrUtcDateTime() {
  return getFormattedDateTime(null, null, null, true)
}
export function getFormattedDate(date, inputPattern, outputPattern, toUtc) {
  const obj = dayjs.utc(date ?? undefined, inputPattern ?? undefined)
  if (toUtc) {
    return obj.format(outputPattern ?? DATE_FORMAT)
  } else {
    return obj.tz(timeZone).format(outputPattern ?? DATE_FORMAT)
  }
}
export function getCurrUtcDate() {
  return getFormattedDate(null, null, null, true)
}

export function toUtcDateTime(dateTime) {
  return dayjs(dateTime).utc().format(DATE_TIME_FORMAT)
}

export function getUserId(email, group_id) {
  return group_id + '_' + email
}
export const currencyFormatter = new Intl.NumberFormat('TW', {
  style: 'currency',
  currency: 'TWD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})
export const pointsFormatter = new Intl.NumberFormat('TW', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
})
export function getTimestamp() {
  return dayjs.utc().format('YYYYMMDDHHmmss')
}

export function getDaysDiff(date1, date2) {
  return dayjs(date1).diff(dayjs(date2), 'day')
}

export function i18n(intl, translateId) {
  if (!translateId) return ''
  const isString = typeof translateId === 'string'
  if (isString) return intl.formatMessage({ id: translateId })
  const isArray = typeof translateId === 'object' && translateId.length > 0
  if (isArray) {
    const str = translateId.reduce((acc, crr) => {
      acc += intl.formatMessage({ id: crr })
      return acc
    }, '')
    return str
  }
}

export function deepCopy(obj) {
  const data = JSON.parse(JSON.stringify(obj))
  return data
}

export function getType(variable) {
  const typeStr = Object.prototype.toString.call(variable)
  const matchResult = typeStr.match(/^\[object\s(\w+)\]$/)
  return matchResult[1]
}

export function focusOnContactForm() {
  const inputData = document.querySelector('#first_name')
  inputData.focus()
}

export function emailVerify(email) {
  const emailRule = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
  return emailRule.test(String(email))
}

export function countStrLenth(str = '') {
  let result = 0
  for (const word of str) {
    const code = word.charCodeAt()
    if (code > 127 || code === 12288 || word.match(/[\uff00-\uffff]/g))
      result += 2
    else result++
  }
  return result
}

export function isAlphanumeric(str) {
  const regularExpression = /^[a-zA-Z0-9]+$/
  return regularExpression.test(String(str))
}

export function includeChinese(str) {
  return escape(str).indexOf('%u') >= 0
}

export function countStrRows(singleRowLengthLimit, str = '') {
  const rows = str.split('\n').reduce((acc, crr) => {
    const length = countStrLenth(crr)
    if (length > singleRowLengthLimit)
      acc += Math.ceil(length / singleRowLengthLimit)
    else acc++
    return acc
  }, 0)
  return rows
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const blob = new Blob([ia], { type: mimeString })
  return URL.createObjectURL(blob)
}

export function scrollToElement(id) {
  const element = document.querySelector(`#${id}`)
  element.scrollIntoView({ behavior: 'smooth' })
}

export function formatPackage(price_package) {
  const { package_name, price, package_id, project_type } = price_package
  let { points } = price_package
  const bonusPoint = Number((points - price / 5000).toFixed(1))
  points -= bonusPoint
  const bonusPercent = bonusPoint
    ? (100 / (points / bonusPoint)).toFixed(1)
    : null
  return {
    package_id,
    project_type,
    package_name,
    points,
    bonusPoint,
    bonusPercent,
    price,
  }
}

export async function downloadQuotation(order_id) {
  const url = await getFileUrl(storagePaths['orig_quotation'].getPath(order_id))
  const filename = storagePaths['orig_quotation'].getFileName(order_id)
  return downloadFromApi(url, filename)
}

export function secToMin(sec) {
  return Math.ceil(sec / 60)
}

const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
export function formatFileSize(bytes, decimals = 2) {
  if (isNaN(bytes) || bytes < 0) {
    throw new Error('argument <bytes> is not a positive number')
  }

  let result = bytes
  let level = 0
  const divideBy = 1024
  const dm = decimals < 0 ? 0 : decimals

  while (result >= divideBy) {
    result /= divideBy
    level++
  }

  return result.toFixed(dm) + ' ' + units[level]
}
