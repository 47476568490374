import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import customFetch from '../../fetch'

export async function ajax(url, option) {
  let data
  try {
    const response = await customFetch(url, option)
    data = await response.json()
  } catch (error) {
    const newError = new ExtendedError(ERROR_CODE_MAP.PlatformApiException.id, {
      error,
    })
    newError.print()
    throw newError
  }
  if (data.type === 0) {
    return data.data
  } else {
    const newError = new ExtendedError(
      ERROR_CODE_MAP.PlatformApiFailResponse.id,
      {
        data: data.data,
        type: data.type,
      }
    )
    newError.print()
    throw newError
  }
}
