import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import { getCurrUtcDateTime, getType } from '../../util'
import databaseCrud from './crud'

function getUserRefStr(uid) {
  return `/user/${uid}`
}
function getGroupRefStr(group_id) {
  return `/group/${group_id}`
}
export async function fetchUser(email) {
  const response = await databaseCrud.filterByChildProp('/user', 'email', email)
  return response ? Object.values(response)[0] : null
}
export async function checkIfUserDisabled(email) {
  const user = await fetchUser(email)
  if (!user) {
    throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id, {
      email,
    })
  }
  return user.disabled ?? false
}
export async function fetchUserAndGroup({ uid, email } = {}) {
  let user
  if (uid) {
    user = await databaseCrud.read(getUserRefStr(uid))
  } else if (email) {
    user = await fetchUser(email)
  }
  if (user?.group_id) {
    const group = await databaseCrud.read(getGroupRefStr(user.group_id))
    return { ...user, group }
  } else {
    return user
  }
}
export async function fetchGroupUser(group_id) {
  if (!group_id) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'group_id',
      expected_type: 'String',
      curr_type: getType(group_id),
    })
  }
  const ref = '/user'
  const value = await databaseCrud.filterByChildProp(ref, 'group_id', group_id)
  return value ? Object.values(value) : []
}
export async function fetchGroupAdministrator(group_id) {
  const user = await fetchGroupUser(group_id)
  return user.find(({ is_group_admin }) => is_group_admin)
}
export function checkIfGroupExist(group_id) {
  return databaseCrud.checkIsExist(getGroupRefStr(group_id))
}
export async function checkIfUserExist(email) {
  const response = await databaseCrud.filterByChildProp('/user', 'email', email)
  return response !== null
}
export async function createUserAndGroup(group_id, uid, option = {}) {
  const now = getCurrUtcDateTime()
  const { user_name, password, email } = option
  const group = {
    group_id,
    agent_id: group_id,
    create_time: now,
    modify_time: now,
    contact_person_email: email,
    contact_person_name: user_name,
    status: 0,
  }

  await databaseCrud.create(getGroupRefStr(group_id), group)

  const user = await createUser(group_id, uid, user_name, password, email, true)

  return {
    group,
    user,
  }
}
export async function createUser(
  group_id,
  uid,
  user_name,
  encodedPassword,
  email,
  is_group_admin = false
) {
  const now = getCurrUtcDateTime()
  const user = {
    user_id: uid,
    user_name,
    email,
    status: 0,
    is_group_admin,
    group_id,
    create_time: now,
    modify_time: now,
    password: encodedPassword,
  }

  await databaseCrud.create(getUserRefStr(uid), user)

  return user
}
export async function updateGroup(group_id, option = {}) {
  const now = getCurrUtcDateTime()
  const group = {
    modify_time: now,
    ...option,
  }
  const ref = getGroupRefStr(group_id)

  await databaseCrud.update(ref, group)

  return databaseCrud.read(ref)
}
export async function updateUser(uid, option = {}) {
  const refStr = getUserRefStr(uid)
  const now = getCurrUtcDateTime()
  const data = {
    modify_time: now,
    ...option,
  }

  await databaseCrud.update(refStr, data)

  return databaseCrud.read(refStr)
}
export async function checkIfUserReadTerms(email) {
  const user = await fetchUser(email)
  if (!user) {
    throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id, {
      email,
    })
  }
  return user.terms_read !== undefined ? user.terms_read : true
}
export async function setAlreadyReadTerms(email) {
  const user = await fetchUser(email)
  const uid = user.user_id
  await updateUser(uid, { terms_read: true })
}
