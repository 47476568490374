export const SESSION_EXPIRE_MIN =
  process.env.REACT_APP_PRODUCTION === 'true' ? 30 : 480
export const TRIAL_DOMAIN_KEY = 'trial_public'
export const DOMAIN_MAP = {
  gcp: {
    text: 'GCP',
    domain: 'https://portal1.musesai.com',
    scapi_key: 'dbbdbf8f550fe63b4ab5b7840b52611d',
    disabled: false,
  },
  azure: {
    text: 'Azure',
    domain: 'https://portal2.musesai.com',
    scapi_key: 'dbbdbf8f550fe63b4ab5b7840b52611d',
    disabled: false,
  },
  trial: {
    text: 'Servtech 內部',
    domain: 'https://cloud.servtech.com.tw:59090',
    scapi_key: '1448e4369aae19695ec23bb213aef211',
    disabled: false,
  },
  [TRIAL_DOMAIN_KEY]: {
    text: '試用',
    domain: 'https://cloud.servtech.com.tw:57070',
    scapi_key: '697bb25691d5c4a713be73451faba0f4',
    disabled: false,
  },
}
export const ORDER_STATUS_MAP = {
  0: {
    text: '待確認訂單',
    color: 'red',
    editableMap: {
      confirm_order: {
        10: true,
        99: true,
      },
    },
    order: 1,
  },
  1: {
    text: '完成',
    color: 'green',
    editableMap: {},
    order: 4,
  },
  2: {
    text: '未完成付款方式的選擇',
    color: 'red',
    editableMap: {},
    order: 8,
  },
  10: {
    text: '待付款',
    color: 'red',
    editableMap: {
      confirm_payment: {
        1: true,
        15: true,
      },
    },
    order: 2,
  },
  15: {
    text: '待收款',
    color: 'red',
    editableMap: {
      confirm_payment: {
        1: true,
      },
    },
    order: 3,
  },
  98: {
    text: '交易失敗',
    color: 'grey',
    editableMap: {},
    order: 9,
  },
  99: {
    text: '取消',
    color: 'grey',
    editableMap: {},
    order: 10,
  },
}

export const USER_STATUS_MAP = {
  0: {
    remark: '未啟用',
    text: 'NotActivated',
    color: 'red',
  },
  1: {
    remark: '啟用',
    text: 'Activated',
    color: 'green',
  },
  9: {
    remark: '拒絕',
    text: 'Refuse',
    color: 'red',
  },
}

export const GROUP_STATUS_MAP = {
  0: { remark: '未啟用', color: 'red' },
  1: { remark: '付費', color: 'green' },
  2: { remark: '試用', color: 'gray' },
}

export const PACKAGE_STATUS_MAP = {
  0: {
    text: '未啟用',
  },
  1: {
    text: '啟用',
  },
}

export const PACKAGE_TYPE_MAP = {
  general: {
    text: '一般',
    description: '（預設）對所有會員開放',
  },
  trial: {
    text: '試用',
    description: '用於系統發放試用點數，同時間只能有一個「啟用」的試用方案',
  },
  bonus: {
    text: '福利',
    description: '用於系統贈送點數，金額固定為 0',
  },
}

export const CUSTOMER_MESSAGE_STATUS_MAP = {
  0: '開立',
  1: '已回覆',
}

export const projectTypeMap = {
  0: {
    text: 'Data',
    value: 'data',
    hide: true,
    general: false,
  },
  1: {
    text: 'Video',
    value: 'video',
    hide: true,
    general: false,
  },
  2: {
    text: 'Image',
    value: 'image',
    hide: true,
    general: false,
  },
  3: {
    text: 'Formula',
    value: 'formula',
    hide: true,
    general: false,
  },
  9: {
    text: 'M Point',
    value: 'M Point',
    hide: false,
    general: true,
  },
}

export const pointsActionNameMap = {
  'video-train': '人因模型訓練',
  'video-prediction': '人因影像回測',
  'image-prediction': '圖像回測',
  'data-prediction': '數據回測',
  'data-train': '數據模型訓練',
  'data-feature': '數據特徵化處理',
  'formula-train': '配方模型訓練',
  'formula-prediction': '配方回測',
}

export const pointsActionExeMap = {
  'train.bat': 'video-train',
  'predict_video.exe': 'video-prediction',
  'predict_graph.exe': 'image-prediction',
  'Autofs_AIP.exe': 'data-feature',
  'data_predict.py': 'data-train',
  'data_train.py': 'data-prediction',
  'formula_train.py': 'formula-train',
  'formula_predict.py': 'formula-prediction',
}

export const messageTypeList = [
  {
    id: 0,
    text: '產品問題',
    hide: true,
  },
  {
    id: 1,
    text: '申請 demo',
    hide: false,
  },
  {
    id: 3,
    text: '課程洽詢',
    ide: false,
  },
  {
    id: 2,
    text: '其他',
    ide: false,
  },
]

export const TEAM_AUTH_MAP = {
  0: '管理員',
  1: '副管理員',
  2: '成員',
  9: '被踢出',
  10: '自願離開',
}

export const COURSE_CANCEL_REASON = {
  0: '時間衝突',
  1: '未繳費',
  2: '使用者報錯梯次',
  9: '其他',
}

export const PAYMENT_TYPE_MAP = {
  SERVTECH: '科智企業匯款',
  NEWEBPAY_VACC: '藍新金流 ATM 轉帳',
  NONE: 'N/A',
}
export const INVOICE_TYPE_MAP = {
  PERSONAL: '個人',
  COMPANY: '公司',
}

export const BANK_CODE_MAP = {
  '004': '臺灣銀行',
  '812': '台新銀行',
  '008': '華南銀行',
  '017': '兆豐銀行',
  '007': '第一銀行',
}

export const NEWS_TYPE = [
  {
    id: 9,
    text: '最新消息',
    hide: false,
  },
  {
    id: 0,
    text: '新品發表',
    hide: false,
  },
  {
    id: 1,
    text: '優惠活動',
    hide: false,
  },
  {
    id: 2,
    text: '維護更新',
    hide: false,
  },
  {
    id: 3,
    text: '問答',
    hide: true,
  },
]

export const NEWS_STATUS = {
  0: {
    text: '啟用',
    color: 'green',
  },
  9: {
    text: '隱藏',
    color: 'grey',
  },
}

export const PLAN_EXCHANGE_STATUS_MAP = {
  'pending': { text: '待處理', color: 'red' },
  'processing': { text: '處理中', color: 'grey' },
  'on-hold': { text: '保留', color: 'red' },
  'completed': { text: '完成', color: 'green' },
  'cancelled': { text: '取消', color: 'red' },
  'refunded': { text: '退款', color: 'red' },
  'failed': { text: '失敗', color: 'red' },
  'trash': { text: '垃圾', color: 'red' },
}

export const authMap = {
  confirm_order: '確認訂單',
  confirm_payment: '確認到帳',
  // 簡化流程後已不使用
  // apply_points: '確認點數變更',
  customer_service: '回覆顧客意見',
  manage_course: '課程管理',
  activate_account: '啟用帳號',
  content_management: '內容管理',
  agent_management: '代理商管理',
  points_management: '點數管理',
}

export const MARKET_URL = process.env.REACT_APP_MARKET_URL

export const hostInfoTypeMap = {
  login_info: '登入資訊',
  remote_connection_info: '連線遠端資訊',
  musesai_inference_info: '推論平台登入資訊',
}

export const MEMBER_CLASS_HISTORY_TYPE = {
  upgrade: '升等',
  reset: '重置',
  renew: '續會',
  downgrade: '降等',
}
