import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { ReactComponent as TelIcon } from '../../assets/img/icon/001-phone-call.svg'
import { ReactComponent as FacebookIcon } from '../../assets/img/icon/002-facebook.svg'
import { ReactComponent as YoutubeIcon } from '../../assets/img/icon/003-youtube.svg'
import { ReactComponent as LocationIcon } from '../../assets/img/icon/004-pin.svg'
import { ReactComponent as RecruitIcon } from '../../assets/img/icon/104-icon.svg'
import ContactForm from '../ContactForm/ContactForm'
import { Link } from 'react-router-dom'
import { LogContext } from '../../Main'
import { MARKET_URL } from '../../module/constants'

function FrontFooter() {
  const history = useHistory()
  const { loginStatus } = useContext(LogContext)

  const webGuideArray = [
    {
      topic: 'NavBar',
      items: [
        {
          title: '購物中心',
          link: MARKET_URL,
          self: true,
        },
        {
          title: '支援服務',
          link: '/support/videos',
        },
        {
          title: '新聞中心',
          link: '/news',
        },
        {
          title: '會員註冊',
          link: '/signup',
          hide: loginStatus,
        },
      ],
    },
    {
      topic: '產品應用',
      items: [
        {
          title: 'Solution',
          link: '/plan-intro/solution',
        },
        {
          title: 'Editor',
          link: '/plan-intro/editor',
        },
        {
          title: 'Tool',
          link: '/plan-intro/tool',
        },
      ],
    },
    {
      topic: '關於 MusesAI',
      items: [
        {
          title: '線上資源',
          link: '/support',
        },
        // {
        //   title: '課程專區',
        //   link: '/classes-list',
        // },
        {
          title: '新聞專區',
          link: '/news',
        },
        {
          title: '品牌歷程',
          link: '/about',
        },
      ],
    },
    {
      topic: '關於科智',
      items: [
        {
          title: '前往科智',
          link: 'http://www.servtech.com.tw/',
        },
        {
          title: '關於我們',
          link: 'http://www.servtech.com.tw/',
        },
        {
          title: '成功案例',
          link: 'http://www.servtech.com.tw/cny',
        },
      ],
    },
  ]

  return (
    <div className="front-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-sm-12 pl-0">
            <div className="brand-intro">
              <h3 className="brand-intro__title">MusesAI</h3>
              <p className="brand-intro__subtitle">最佳的產線 AI 工具</p>
              <p className="brand-intro__content">
                我們希望 MusesAI 能將複雜、繁瑣的製造業製成影像 AI
                開發專案，轉變成能讓您立即展開使用、簡單上手、快速開發的開發工具，讓您創造出屬於自己的最佳
                AI 模型。
              </p>
            </div>
            <div className="web-guide row">
              {webGuideArray.map(({ topic, items }, i) => {
                return (
                  <div className="col-lg-3 col-sm-12 container" key={i}>
                    <div className="web-guide__topic">{topic}</div>
                    {items.map((el) => {
                      return el.hide ? null : el.link.includes('http') ? (
                        <a
                          className="web-guide__item"
                          key={el.title}
                          href={el.link}
                          target={el.self ? '_self' : '_blank'}
                          rel="noreferrer noopener"
                        >
                          {el.title}
                        </a>
                      ) : (
                        <Link
                          className="web-guide__item"
                          key={el.title}
                          to={el.link}
                        >
                          {el.title}
                        </Link>
                      )
                    })}
                  </div>
                )
              })}
              <div className="company-info company-info--top col-12">
                <div className="company-info__name">科智企業股份有限公司</div>
                <div className="contact-info__content d-flex mb-3">
                  <div className="tel">
                    <TelIcon className="footer-icon disable-hover-effect" />
                    <span className="tel-num">( 02 ) 2562 - 2733</span>
                  </div>
                  <div>
                    <FacebookIcon
                      className="footer-icon"
                      onClick={() =>
                        window.open(
                          'https://www.facebook.com/servtech2014/',
                          '_blank',
                          'noopener'
                        )
                      }
                    />
                  </div>
                  <div>
                    <YoutubeIcon
                      className="footer-icon"
                      onClick={() =>
                        window.open(
                          'https://www.youtube.com/channel/UCWN-Vk6-Q_gKXZ-Z6CDqB-A/featured',
                          '_blank',
                          'noopener'
                        )
                      }
                    />
                  </div>
                  <div>
                    <RecruitIcon
                      className="footer-icon"
                      onClick={() =>
                        window.open(
                          'https://www.104.com.tw/company/1a2x6bj1aq',
                          '_blank',
                          'noopener'
                        )
                      }
                    />
                  </div>
                </div>
                <div className="company-address">
                  <LocationIcon
                    className="footer-icon"
                    onClick={() =>
                      window.open(
                        'https://goo.gl/maps/Qj7UEoanM5daxVN96',
                        '_blank',
                        'noopener'
                      )
                    }
                  />
                  <span>臺北市中山區南京東路二段97號5樓</span>
                </div>
                <div className="policy-rules">
                  © 2023 Servtech Inc. All Rights Reserved.{' '}
                  <Link className="policy-rules__link" to="/term">
                    會員條款與隱私權政策
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12 contact-form mb-4">
            <ContactForm />
            <div className="company-info company-info--bottom">
              <div className="company-info__name">科智企業股份有限公司</div>
              <div className="contact-info__content d-flex mb-3">
                <div className="tel">
                  <TelIcon className="footer-icon disable-hover-effect" />
                  <span className="tel-num">( 02 ) 2562 - 2733</span>
                </div>
                <div>
                  <FacebookIcon
                    className="footer-icon"
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com/servtech2014/',
                        '_blank',
                        'noopener'
                      )
                    }
                  />
                </div>
                <div>
                  <YoutubeIcon
                    className="footer-icon"
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/channel/UCWN-Vk6-Q_gKXZ-Z6CDqB-A/featured',
                        '_blank',
                        'noopener'
                      )
                    }
                  />
                </div>
                <div>
                  <RecruitIcon
                    className="footer-icon"
                    onClick={() =>
                      window.open(
                        'https://www.104.com.tw/company/1a2x6bj1aq',
                        '_blank',
                        'noopener'
                      )
                    }
                  />
                </div>
              </div>
              <div className="company-address">
                <LocationIcon
                  className="footer-icon"
                  onClick={() =>
                    window.open(
                      'https://goo.gl/maps/Qj7UEoanM5daxVN96',
                      '_blank',
                      'noopener'
                    )
                  }
                />
                <span>臺北市中山區南京東路二段97號5樓</span>
              </div>
              <div className="policy-rules">
                © 2023 Servtech Inc. All Rights Reserved.{' '}
                <span
                  className="policy-rules__link"
                  onClick={() => history.push('/term')}
                >
                  會員條款與隱私權政策
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FrontFooter
