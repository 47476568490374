import credential from '../Credential/credential'
import {
  checkIsAdmin,
  fetchAdminInfo,
  removeAdministrator,
  setAdmin,
} from '../../model/database/admin'
import {
  createTrialOrder,
  insertOrderLog,
  updateOrder as updateOrderToDatabase,
} from '../../model/database/order'
import {
  fetchGroupAdministrator,
  fetchGroupUser,
  fetchUser,
  updateGroup,
  updateUser,
} from '../../model/database/userAndGroup'
import {
  applyTrialPoints,
  updateOrder as updateOrderToPlatform,
} from '../../model/platform/order'
import { signUp } from '../../model/platform/signUp'
import {
  DATE_FORMAT,
  getCurrUtcDate,
  getCurrUtcDateTime,
  getType,
} from '../../util'
import {
  sendAccountActivatedNotification,
  sendApplicationResult,
  sendApplyPointsConfirmation,
  sendOrderApprovedNotification,
  sendPaymentConfirmation,
} from '../Mail/Delivery'
import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import { fetchGroupById } from '../../model/database/group'
import { updateTrialPointApplication } from '../../model/database/trialPointApplication'
import { TRIAL_DOMAIN_KEY } from '../../constants'
import Emitter from '../../Emitter'
import dayjs from 'dayjs'
import customFetch from '../../fetch'

class Admin extends Emitter {
  constructor() {
    super(['userIdChange', 'adminInfoChange'])
    this._user_id = null
    this._adminInfo = null
  }
  get user_id() {
    return this._user_id
  }
  set user_id(id) {
    this._user_id = id
    this.emit('userIdChange', [id])
  }
  get adminInfo() {
    return this._adminInfo
  }
  set adminInfo(data) {
    this._adminInfo = data
      ? new Proxy(data, {
          set: (obj, key, newValue) => {
            // The default behavior to store the value
            obj[key] = newValue

            this.emit('adminInfoChange', [obj])

            // Indicate success
            return true
          },
        })
      : data
    this.emit('adminInfoChange', [data])
  }
  /**
   * 給予點數
   * 狀態從待付款 10 變成已完成 1 或是待收款 15
   * @param {string} order_id
   * @param {object} order
   * @param {boolean} [isPayed]
   */
  async enableOrder(order_id, order, isPayed = false) {
    const { create_time: pur_time, group_id, remark = '', total: price } = order
    const { group } = credential
    const { group_id: admin_group_id, domain_key } = group || {}
    const payment_time = getCurrUtcDateTime()
    if (!admin_group_id || !domain_key || !this.user_id) {
      throw new ExtendedError(ERROR_CODE_MAP.MissingAuthentication.id)
    }
    const newStatus = isPayed ? 1 : 15
    await updateOrderToDatabase(order_id, {
      remark,
      status: newStatus,
    })
    await updateOrderToPlatform(group_id, pur_time, {
      project_type: '9',
      payment_time,
    })
    await insertOrderLog(order_id, 10, newStatus, this.user_id)

    const groupOfBuyer = await fetchGroupById(group_id)
    if (groupOfBuyer.status !== 1) {
      await updateGroup(group_id, {
        status: 1,
      })
    }
    const groupUserList = await fetchGroupUser(group_id)
    const user = groupUserList.find((obj) => obj.is_group_admin)
    const { user_name, email } = user

    await sendOrderApprovedNotification(email, user_name, {
      create_time: pur_time,
      points: order.points,
      price,
      order_id,
    })
  }
  /**
   * 完成訂單
   * 狀態從待收款 15 變成已完成 1
   * @param {string} order_id
   */
  async completeOrder(order_id) {
    await updateOrderToDatabase(order_id, {
      status: 1,
    })
    await insertOrderLog(order_id, 15, 1, this.user_id)
  }
  /**
   * 確認訂單
   * 狀態從待確認訂單 0 變成待付款 10
   * @param {string} order_id
   * @param {object} order
   */
  async confirmOrder(order_id, order) {
    await updateOrderToDatabase(order_id, {
      status: 10,
    })
    await insertOrderLog(order_id, 0, 10, this.user_id)
    await sendPaymentConfirmation(order_id, {
      group_id: order.group_id,
      create_time: order.create_time,
      points: order.points,
      price: order.total,
      invoice_type: order.invoice_type,
      invoice_company_name: order.invoice_company_name,
      invoice_tax_id: order.invoice_tax_id,
    })
  }
  /**
   * 取消訂單
   * 狀態從待確認訂單 0 變成取消 99
   * @param {string} order_id
   * @param {object} order
   */
  async cancelOrder(order_id, order) {
    await updateOrderToDatabase(order_id, {
      status: 99,
    })
    await insertOrderLog(order_id, 0, 99, this.user_id)
  }
  /**
   * [DEPRECATED] 確認付款
   * 狀態從待付款 10 變成待更新點數 20
   * @param {string} order_id
   * @param {object} order
   */
  async confirmPayment(order_id, order) {
    await updateOrderToDatabase(order_id, {
      status: 20,
    })
    await insertOrderLog(order_id, 10, 20, this.user_id)
    await sendApplyPointsConfirmation(order_id, {
      group_id: order.group_id,
      create_time: order.create_time,
      points: order.points,
      price: order.total,
    })
  }
  async enableGroup(
    group_id,
    domain_key,
    default_project_type = 1,
    is_agent = false
  ) {
    if (!group_id) {
      throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
        variable: 'group_id',
        expected_type: 'String',
        curr_type: getType(group_id),
      })
    } else if (!domain_key) {
      throw new ExtendedError(ERROR_CODE_MAP.UndefinedDomain.id)
    }

    await this.enableGroupAdministrator(group_id, domain_key)

    const { modify_time } = await updateGroup(group_id, {
      status: 2,
      domain_key,
      is_agent,
      default_project_type,
      member_class: 'normal',
      class_effective_from: getCurrUtcDate(),
      class_expire_date: dayjs.utc().add(12, 'month').format(DATE_FORMAT),
      class_acc_consumption: 0,
      class_last_modified_time: getCurrUtcDateTime(),
    })
    return modify_time
  }
  async enableGroupAdministrator(group_id, domain_key) {
    const user = await fetchGroupAdministrator(group_id)
    if (!user) {
      throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id)
    }
    const { user_id: uid, user_name, password, email } = user

    await signUp({
      user_id: email,
      password: atob(password),
      user_name,
      group_id,
      domain_key,
      isTeam: true,
    })
    await updateUser(uid, { status: 1 })
    await sendAccountActivatedNotification(email, user_name)
  }
  async checkIsAdmin(user_id) {
    return await checkIsAdmin(user_id)
  }
  async updateAdminInfo() {
    this.adminInfo = await fetchAdminInfo(credential.user.uid)
  }
  async setAdmin(email, uid) {
    return await setAdmin(email, uid)
  }
  removeAdmin(uid) {
    if (uid === this.adminInfo.uid) {
      throw new ExtendedError(ERROR_CODE_MAP.OperationError.id, {
        reason: '不能刪除自己',
      })
    }
    return removeAdministrator(uid)
  }
  fetchUserAuthData(uid) {
    return customFetch('/api/user/' + uid).then((data) => data.json())
  }
  async approveTrialPoint(id, email) {
    const {
      user_name,
      trial_platform_status,
      user_id,
      password,
      group_id,
    } = await fetchUser(email)

    if (trial_platform_status !== 1) {
      await signUp({
        user_id: email,
        password: atob(password),
        user_name,
        group_id,
        domain_key: TRIAL_DOMAIN_KEY,
        isTeam: true,
      })
      await updateUser(user_id, {
        trial_platform_status: 1,
      })
    }
    const { points } = await createTrialOrder(group_id, this.user_id)
    await applyTrialPoints(group_id, points)
    await updateTrialPointApplication(id, 1, this.user_id)
    await sendApplicationResult(email, user_name)
  }
}

const admin = new Admin()

export default admin
