import _ from 'lodash'
import { customAlphabet } from 'nanoid'
import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import { getCurrUtcDateTime, getType, getFormattedDate } from '../../util'
import databaseCrud from './crud'
import { fetchPackage } from './package'
import credential from '../../controller/Credential/credential'

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const customNanoid = customAlphabet(alphabet, 8)

export async function fetchOrderById(order_id) {
  const ref = '/order/' + order_id
  const value = await databaseCrud.read(ref)
  return value
}

export async function fetchAllOrder() {
  const ref = '/order'
  const value = await databaseCrud.read(ref)
  return value ? Object.values(value) : []
}

export async function fetchOrderByGroup(group_id) {
  if (!group_id) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'group_id',
      expected_type: 'String',
      curr_type: getType(group_id),
    })
  }
  const ref = '/order'
  const value = await databaseCrud.filterByChildProp(ref, 'group_id', group_id)
  return value ? Object.values(value) : []
}

export async function fetchOrderByStatus(status) {
  if (status === undefined) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'status',
      expected_type: 'Number',
      curr_type: getType(status),
    })
  }
  const ref = '/order'
  const value = await databaseCrud.filterByChildProp(ref, 'status', status)
  return value ? Object.values(value) : []
}

export async function fetchOrderLog(order_id) {
  if (order_id === undefined) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'order_id',
      expected_type: 'String',
      curr_type: getType(order_id),
    })
  }
  const ref = '/order_log'
  const value = await databaseCrud.filterByChildProp(ref, 'order_id', order_id)
  return value ? Object.values(value) : []
}

export async function fetchOrderDetail(order_id) {
  if (order_id === undefined) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'order_id',
      expected_type: 'String',
      curr_type: getType(order_id),
    })
  }
  const ref = '/order_detail'
  const value = await databaseCrud.filterByChildProp(ref, 'order_id', order_id)
  return value ? Object.values(value) : []
}

export function checkIfOrderExist(order_id) {
  const ref = '/order/' + order_id
  return databaseCrud.checkIsExist(ref)
}
export async function getOrderSerialNumber(date) {
  const idArr = Object.keys(await databaseCrud.read('/order'))
  const serialNumberArr = idArr
    .filter((d) => d.substring(1, 9) === date)
    .map((id) => (id = id.substring(9, 12)))
  const lastSerialNumber = Number(serialNumberArr.sort((a, b) => b - a)[0] ?? 0)
  const newSerialNumber = lastSerialNumber + 1
  if (newSerialNumber < 10) {
    return `00${newSerialNumber}`
  } else if (newSerialNumber >= 10 && newSerialNumber < 100) {
    return `0${newSerialNumber}`
  }
  return newSerialNumber
}
export async function getOrderId() {
  const date = getFormattedDate().replaceAll('/', '')
  const serialNumber = await getOrderSerialNumber(date)
  const randomNumber = Math.floor(Math.random() * 10)
  const order_id = 'M' + date + serialNumber + randomNumber
  return order_id
}
export async function createOrder(
  group_id,
  user_id,
  detail,
  total,
  payment_type,
  points,
  invoice_type,
  companyDetail,
  bonus_points = 0
) {
  const order_id = await getOrderId()
  const now = getCurrUtcDateTime()
  const orderRef = `/order/${order_id}`
  let initStatus

  if (payment_type === 'NEWEBPAY_VACC') {
    initStatus = 2
  } else if (payment_type === 'NONE') {
    initStatus = 1
  } else {
    initStatus = 0
  }

  const order = {
    order_id,
    group_id,
    status: initStatus,
    total,
    points,
    bonus_points,
    payment_type,
    create_by: user_id,
    modify_by: user_id,
    create_time: now,
    modify_time: now,
  }
  if (invoice_type) {
    order.invoice_type = invoice_type
    if (invoice_type === 'COMPANY') {
      order.invoice_company_name = companyDetail.company_name
      order.invoice_tax_id = companyDetail.tax_id
    }
  }

  await databaseCrud.create(orderRef, order)
  await Promise.all(
    detail.map(({ package_id, amount }) => {
      const orderDetailRef = `/order_detail/${order_id}_${package_id}`
      return databaseCrud.create(orderDetailRef, {
        order_id,
        package_id,
        amount,
      })
    })
  )
  await insertOrderLog(order_id, null, initStatus, user_id)
  return order
}

export async function createTrialOrder(group_id, approve_by) {
  // 第一個啟用的試用點數方案
  const trialPackageMap = await fetchPackage(1, 'trial')
  const trialPackage = Object.values(trialPackageMap)[0]
  if (!trialPackage) {
    throw new Error('Trial package is not found or inactive')
  }
  const { package_id, price, points } = trialPackage
  const now = getCurrUtcDateTime()
  const order_id = await getOrderId()
  const ref = `/order/${order_id}`
  const orderDetailRef = `/order_detail/${order_id}_${package_id}`
  const status = 1
  const order = {
    order_id,
    group_id,
    status,
    create_by: approve_by,
    modify_by: approve_by,
    payment_type: 'NONE',
    create_time: now,
    modify_time: now,
    total: price,
    points,
  }
  // await databaseCrud.create(ref, order)
  // await databaseCrud.create(orderDetailRef, {
  //   order_id,
  //   package_id,
  //   amount: 1,
  // })
  // await insertOrderLog(order_id, null, status, approve_by)
  return order
}

export function updateOrder(order_id, option = {}) {
  const now = getCurrUtcDateTime()
  const ref = `/order/${order_id}`
  const data = {
    ...option,
    modify_time: now,
    modify_by: credential.user.email,
  }
  return databaseCrud.update(ref, data)
}

export function updateOrderFileUrl(order_id, type, url) {
  return updateOrder(order_id, { [`${type}_url`]: url })
}

export function insertOrderLog(order_id, orig_status, chg_status, user_id) {
  const now = getCurrUtcDateTime()
  const ref = `/order_log`
  const data = {
    create_time: now,
    create_by: user_id,
    orig_status,
    chg_status,
    order_id,
  }
  return databaseCrud.push(ref, data)
}
