import _ from 'lodash'
import * as yup from 'yup'
import databaseCrud from './crud'

const rootRef = '/package'

export async function fetchPackage(status, type) {
  let packageData
  try {
    if (_.isNumber(status)) {
      packageData = await databaseCrud.filterByChildProp(
        rootRef,
        'status',
        status
      )
    } else {
      packageData = await databaseCrud.read(rootRef)
    }
  } catch (error) {
    console.warn('取得點數方案失敗', error.code, error.message)
  }
  if (type) {
    packageData = _.pickBy(packageData, (obj) => obj.type === type)
  }
  return packageData ?? {}
}

export async function fetchPackageById(package_id) {
  let packageData
  try {
    packageData = await databaseCrud.filterByChildProp(
      rootRef,
      'package_id',
      package_id
    )
  } catch (error) {
    console.warn('取得點數方案失敗', error.code, error.message)
  }
  return packageData ?? {}
}

const packageOptionSchema = yup.object().shape({
  create_time: yup.string().required(),
  package_id: yup.string().required(),
  points: yup.number().required(),
  price: yup.number().required(),
  project_type: yup.number().required(),
})
export async function createPackage(option) {
  try {
    await packageOptionSchema.validate(option)
    await databaseCrud.create(`${rootRef}/${option.package_id}`, option)
    return { type: 0, data: option }
  } catch (error) {
    if (error.name === 'ValidationError') {
      console.warn('新增點數方案驗證失敗', error.errors)
      return {
        type: 1,
        data: {
          title: '參數錯誤',
          content: error.errors.join('\n'),
        },
      }
    } else {
      console.warn('建立點數方案失敗', error.code, error.message)
      return {
        type: 1,
        data: {
          title: '發生錯誤',
          content: '請聯絡系統管理員',
        },
      }
    }
  }
}

export async function updatePackage(option) {
  try {
    const refStr = `${rootRef}/${option.package_id}`
    await databaseCrud.update(refStr, option)
    return { type: 0, data: true }
  } catch (error) {
    console.warn('更新點數方案失敗', error.code, error.message)
    return {
      type: 1,
      data: {
        title: '系統錯誤',
        content: '請聯絡系統管理員',
      },
    }
  }
}
