import React, { useState, useEffect } from 'react'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false)

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

  useEffect(() => {
    function scrollHandler(e) {
      if (window.pageYOffset > 300) setIsVisible(true)
      else setIsVisible(false)
    }
    function detectPosition() {
      document.addEventListener('scroll', scrollHandler)
    }
    function removeListener() {
      document.removeEventListener('scroll', scrollHandler)
    }
    detectPosition()
    return removeListener
  }, [])

  return (
    <div
      className={isVisible ? 'scroll-top-button' : 'scroll-top-button d-none'}
      title="scroll to top"
      onClick={scrollToTop}
    >
      <VerticalAlignTopIcon />
    </div>
  )
}
