import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import { getType } from '../../util'
import databaseCrud from './crud'

export async function fetchGroupById(group_id) {
  if (group_id === undefined) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'group_id',
      expected_type: 'String',
      curr_type: getType(group_id),
    })
  }
  const ref = '/group/' + group_id
  const value = await databaseCrud.read(ref)
  return value
}

export async function fetchGroupByStatus(status) {
  if (status === undefined) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'status',
      expected_type: 'Number',
      curr_type: getType(status),
    })
  }
  const ref = '/group'
  const value = await databaseCrud.filterByChildProp(
    ref,
    'status',
    status.toString()
  )
  return value ? Object.values(value) : []
}

export async function fetchGroupByAgent(group_id) {
  if (group_id === undefined) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'group_id',
      expected_type: 'String',
      curr_type: getType(group_id),
    })
  }
  const value = await databaseCrud.filterByChildProp(
    '/group',
    'ref_agent_id',
    group_id
  )
  return value ? Object.values(value) : []
}

export async function fetchAgent() {
  const value = await databaseCrud.filterByChildProp('/group', 'is_agent', true)
  return value ? Object.values(value) : []
}

export async function fetchAllGroup() {
  const ref = '/group'
  const value = await databaseCrud.read(ref)
  return value ? Object.values(value) : []
}
