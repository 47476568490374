import _ from 'lodash'

/* eslint no-template-curly-in-string: 0 */

export const ERROR_CODE_MAP = {
  InvalidParameterType: {
    id: 'InvalidParameterType',
    text:
      '參數格式錯誤: ${variable} 預期是 ${expected_type} 但拿到的是 ${curr_type}',
  },
  IncorrectValue: {
    id: 'IncorrectValue',
    text: '${variable} 的值 ${value} 不在允許範圍內',
  },
  OutOfRange: {
    id: 'OutOfRange',
    text: '${variable} (${value}) 超出範圍，合法範圍為 ${min} - ${max}',
  },
  UndefinedDomain: {
    id: 'UndefinedDomain',
    text: '無法取得 Domain',
  },
  IncorrectProjectType: {
    id: 'IncorrectProjectType',
    text: '不正確的專案類型。project_type: ${project_type}',
  },
  DatabaseCrudError: {
    id: 'DatabaseCrudError',
    text: 'database 操作失敗。method: ${method} | ref: ${ref}',
    i18n: 'database 操作失敗',
  },
  PlatformApiFailResponse: {
    id: 'PlatformApiFailResponse',
    text: '系統平台 API 回傳錯誤。type: ${type}',
  },
  PlatformApiException: {
    id: 'PlatformApiException',
    text: '系統平台 API 發生例外錯誤',
  },
  Unknown: {
    id: 'Unknown',
    text: '例外錯誤',
  },
  EnrollmentClosed: {
    id: 'EnrollmentClosed',
    text: '報名已結束',
    i18n: '報名已結束',
  },
  CourseRegistered: {
    id: 'CourseRegistered',
    text: '已經報名',
    i18n: '已經報名',
  },
  OperationError: {
    id: 'OperationError',
    text: '操作錯誤: ${reason}',
    i18n: '無法進行此項操作',
  },
  Unauthorized: {
    id: 'Unauthorized',
    text: '無權限進行此項操作: ${action_name}，因為 ${reason}',
    i18n: '您沒有權限進行此項操作',
  },
  ExceedEnrolleeLimit: {
    id: 'ExceedEnrolleeLimit',
    text: '報名人數已達上限: ${limit}',
    i18n: '報名人數已達上限',
  },
  ExceedAdministratorLimit: {
    id: 'ExceedAdministratorLimit',
    text: '管理員人數超過上限: ${limit}',
    i18n: '管理員人數超過上限',
  },
  ExceedDeputyAdministratorLimit: {
    id: 'ExceedDeputyAdministratorLimit',
    text: '副管理員人數超過上限: ${limit}',
    i18n: '副管理員人數超過上限',
  },
  ExceedTeamMemberLimit: {
    id: 'ExceedTeamMemberLimit',
    text: '團隊人數超過上限: ${limit}',
    i18n: '團隊人數超過上限',
  },
  ExceedTeamCreateLimit: {
    id: 'ExceedTeamCreateLimit',
    text: '團隊創建數量超過上限: ${limit}',
    i18n: '團隊創建數量超過上限',
  },
  UserNotInTeam: {
    id: 'UserNotInTeam',
    text: '此用戶不是團隊成員。email ${email}',
    i18n: '此用戶不是團隊成員',
  },
  AlreadyInTeam: {
    id: 'AlreadyInTeam',
    text: '此用戶已經是團隊成員。email: ${email}',
    i18n: '此用戶已經是團隊成員',
  },
  TeamNotFound: {
    id: 'TeamNotFound',
    text: '查無團隊。team_id: ${team_id}',
  },
  CourseTimeNotFound: {
    id: 'CourseTimeNotFound',
    text: '查無此課程時間。course_time: ${course_time} course_id: ${course_id}',
    i18n: '查無此課程',
  },
  CourseNotFound: {
    id: 'CourseNotFound',
    text: '查無此課程。course_id: ${course_id}',
    i18n: '查無此課程',
  },
  UserNotFound: {
    id: 'UserNotFound',
    text: '查無使用者。email: ${email}',
    i18n: '查無使用者',
  },
  AccountNotActivated: {
    id: 'AccountNotActivated',
    text: '帳戶未啟用',
    i18n: '帳戶未啟用',
  },
  IncorrectPassword: {
    id: 'IncorrectPassword',
    text: '密碼錯誤。email: ${email}',
    i18n: '密碼錯誤',
  },
  WeakPassword: {
    id: 'WeakPassword',
    text: '密碼強度太低',
    i18n: '密碼強度太低',
  },
  GroupAdminNotFound: {
    id: 'GroupAdminNotFound',
    text: '查無 Group 管理員。group_id: ${group_id}',
  },
  MissingAuthentication: {
    id: 'MissingAuthentication',
    text: '沒有取得使用者資訊',
    i18n: '請登入後再重新操作',
  },
  TransferPointOutOfRange: {
    id: 'TransferPointOutOfRange',
    text: '轉移點數只能是正整數，卻收到 ${points}',
    i18n: '轉移點數只能是正整數',
  },
  InvalidUrl: {
    id: 'TransferPointOutOfRange',
    text: '轉移點數只能是正整數，卻收到 ${points}',
    i18n: '轉移點數只能是正整數',
  },
  ExpiredActionCode: {
    id: 'ExpiredActionCode',
    text: '操作逾時',
    i18n: '操作逾時',
  },
  FileExist: {
    id: 'FileExist',
    text: '檔案已存在：${ref}',
    i18n: '檔案已存在',
  },
}

export class ExtendedError extends Error {
  constructor(code, option) {
    const errorMap = ERROR_CODE_MAP[code] ?? ERROR_CODE_MAP.Unknown
    const { text, i18n } = errorMap

    super(i18n ?? '系統錯誤')

    this.text = _.template(text)(option)
    this.code = code
    this.i18n = i18n
    this.data = option?.data
    this.error = option?.error
    this.print()
  }
  print() {
    const warningArgs = []
    const msg = `${this.code}: ${this.text}`

    warningArgs.push(msg)
    if (this.error) {
      warningArgs.push(this.error)
    }
    if (this.data) {
      warningArgs.push(this.data)
    }
    console.warn(...warningArgs)
  }
}
