import logger from '@servtech/client-logger'
import emailjs from 'emailjs-com'
import { template } from 'lodash'
import { fetchAllAdministrator } from '../../model/database/admin'
import { fetchMailAvailability } from '../../model/database/mail'
import { authMap } from '../../constants'

// {{ variable }} 代表要被替換的變數
const interpolate = /{{([\s\S]+?)}}/g
export const shouldSendNotify = {
  lineNotify: ['true', 'linenotify'].includes(
    process.env.REACT_APP_SEND_EMAIL_AND_LINE_NOTIFY
  ),
  email: ['true', 'email'].includes(
    process.env.REACT_APP_SEND_EMAIL_AND_LINE_NOTIFY
  ),
}

class Notify {
  /**
   * 通知類別
   * @param {string} name - 通知名稱
   * @param {object} options  - 選項
   * @param {string} options.subject - 信件主旨
   * @param {string} options.html_content - 信件內容，為 html 字串
   * @param {function} options.param - 取得信件內容的參數
   */
  constructor(name, options = {}) {
    this.name = name
    this.options = options
    this.getSubject = template(this.options.subject ?? '', {
      interpolate,
    })
    this.getHtmlContent = template(this.options.html_content ?? '', {
      interpolate,
    })
  }
  async getParam(...args) {
    try {
      const resp = await this.options.param(...args)
      return resp
    } catch (error) {
      console.error('Fail to get param...', error)
      return {}
    }
  }
  async send(recipient, ...args) {
    if (!recipient) {
      return
    }
    const param = await this.getParam(...args)
    const subject = this.getSubject(param)
    const html_content = this.getHtmlContent(param)
    return Notify.sendMail(recipient, subject, html_content)
  }
  /**
   * 寄送信件（透過 emailjs，使用通用客製化模板，由程式統一管理不同郵件）
   * @param {string | string[]} to_email - 收件者，可一次寄給多人
   * @param {string} subject - 信件主旨
   * @param {string} html_content - 信件內容，為 html 字串
   * @param {object} options - 其他參數
   * @param {string} options.from_name - 寄件者名稱
   * @param {string} options.reply_to - 回覆信箱
   * @param {string} options.cc - 副本
   * @param {string} options.bcc - 密件副本
   * @returns
   */
  static sendMail(
    to_email,
    subject,
    html_content,
    options = {},
    force = false
  ) {
    if (!force && !shouldSendNotify.email) {
      return
    }
    const userId = 'user_ukRhAyq0GepgMKJtYUlS7'
    const serviceId = 'service_h41yg3a'
    const templateId = 'template_yhxer9o'
    const templateParams = {
      to_email:
        process.env.REACT_APP_TEST_EMAIL ||
        (typeof to_email === 'string' ? to_email : to_email.join(',')),
      from_name: 'MusesAI',
      reply_to: 'no_reply',
      html_content,
      subject,
      ...options,
    }
    try {
      return emailjs.send(serviceId, templateId, templateParams, userId)
    } catch (error) {
      logger.error('Fail to sending mail...', error)
    }
  }
}

class AdminNotify extends Notify {
  constructor(name, mail_id, options = {}) {
    super(name, {
      html_content: [
        '<h3>{{ subTitle }}</h3>',
        '<ul>',
        '  <% Object.entries(content).forEach(([title, value]) => {',
        '    if (title === "內容" || title === "原因") {',
        '      %><li style="white-space: pre-wrap;"><%- title %>: <br><%- value %></li><%',
        '    } else {',
        '      %><li><%- title %>: <%- value %></li><%',
        '    }',
        '  })%>',
        '</ul>',
      ].join('\n'),
      ...options,
    })
    this.getSubject = (param) => '[MusesAI] ' + param.title
    this.mail_id = mail_id
  }
  async send(...args) {
    const promises = []
    const recipient = await this.getAdminRecipient()
    if (!recipient.length) {
      return
    }
    const param = await this.getParam(...args)
    const subject = '[MusesAI] ' + this.getSubject(param)
    const html_content = this.getHtmlContent(param)
    promises.push(Notify.sendMail(recipient, subject, html_content))
    promises.push(AdminNotify.sendLineNotify(param, recipient))
    return Promise.all(promises)
  }
  async getAdminRecipient() {
    const { mail_id } = this
    const [adminList, mail] = await Promise.all([
      fetchAllAdministrator(),
      fetchMailAvailability().then((mailList) =>
        mailList.find((item) => item.mail_id === mail_id)
      ),
    ])
    const availableAuthList = Object.keys(authMap).filter((auth) => mail[auth])
    return adminList.reduce((a, admin) => {
      const hasAuth = availableAuthList.find((auth) => admin[auth])
      return hasAuth ? [...a, admin.email] : a
    }, [])
  }
  static getLineMessage(param, recipient) {
    const { title, content, subTitle, department = 'PM' } = param
    const lineBreak = '\n'
    const divider = '---'
    const subject = `${title}通知`
    let msg =
      subject +
      lineBreak +
      lineBreak +
      `部門：${department}` +
      lineBreak +
      `收件者：${recipient.join(', ') || 'N/A'}` +
      lineBreak +
      divider +
      lineBreak
    if (subTitle) {
      msg += subTitle + lineBreak + divider + lineBreak
    }
    if (content) {
      msg += '以下為相關資訊' + lineBreak
      msg += Object.entries(content)
        .map(([title, value], idx) => ` - ${title}: ${value}`)
        .join(lineBreak)
    }
    return msg
  }
  /**
   * 透過 Firebase callable function 呼叫 line notify api
   * @param {object} props
   * @param {string} props.title - 通知標題
   * @param {string} props.subTitle - 通知副標題
   * @param {object} props.content - 通知內容，為鍵值形式，最後會輸出內容是條列式
   * @param {string} props.message - 自訂訊息，若有則會忽略 title, content, subTitle
   */
  static sendLineNotify(props, recipient, force = false) {
    if (!force && !shouldSendNotify.lineNotify) {
      return
    }
    const args = {}

    if (process.env.REACT_APP_LINE_NOTIFY_TOKEN) {
      args.token = process.env.REACT_APP_LINE_NOTIFY_TOKEN
    }
    if (props.message) {
      args.message = props.message
    } else {
      args.message = AdminNotify.getLineMessage(props, recipient)
    }
    return firebase.functions().httpsCallable('lineNotify')(args)
  }
}

export { AdminNotify, Notify }
