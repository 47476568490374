import admin from '../Admin/Admin'
import { checkIsAdmin } from '../../model/database/admin'
import {
  checkIfGroupExist,
  checkIfUserDisabled,
  createUserAndGroup,
  fetchUser,
  fetchUserAndGroup,
  updateGroup,
  updateUser,
  checkIfUserReadTerms,
} from '../../model/database/userAndGroup'
import _ from 'lodash'
import {
  getCurrUtcDateTime,
  getFormattedDateTime,
  getType,
  getUserId,
} from '../../util'
import {
  DOMAIN_MAP,
  SESSION_EXPIRE_MIN,
  TRIAL_DOMAIN_KEY,
} from '../../constants'
import { customAlphabet } from 'nanoid'
import swal from 'sweetalert'
import {
  checkIfTeamExist,
  createTeam,
  fetchTeamByUser,
  fetchTeamMemberAuth,
  insertMember as insertMemberToDb,
  updateMember as updateMemberToDb,
} from '../../model/database/team'
import { signUp } from '../../model/platform/signUp'
import {
  disableUser,
  enableUser,
  updatePassword,
  updateUser as updateUserToPlatform,
} from '../../model/platform/user'
import { transferPointsToTeam } from '../../model/platform/order'
import {
  sendCourseRegistrationConfirmation,
  sendCourseRegistrationNotification,
  sendUserSignUpNotification,
} from '../Mail/Delivery'
import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import { fetchCourse, registerCourse } from '../../model/database/course'
import Emitter from '../../Emitter'
import logger from '@servtech/client-logger'
import customFetch from '../../fetch'
import databaseCrud from '../../model/database/crud'

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const customNanoid = customAlphabet(alphabet, 14)
const CREATE_TEAM_LIMIT = 1

async function createGroupId() {
  let group_id = customNanoid()
  let isExist = await checkIfGroupExist(group_id)
  while (isExist) {
    group_id = customNanoid()
    isExist = await checkIfGroupExist(group_id)
  }
  return group_id
}

async function createTeamId() {
  let team_id = 'T' + customNanoid()
  let isExist = await checkIfTeamExist(team_id)
  while (isExist) {
    team_id = 'T' + customNanoid()
    isExist = await checkIfTeamExist(team_id)
  }
  return team_id
}

function headToPlatform({
  email,
  group_id,
  domain,
  pw,
  default_project_type,
  funcId = '',
  sameWindow = false,
  viewOnly = false,
}) {
  const user_id = getUserId(email, group_id)
  // 30 分鐘內登入才有效，Date.prototype.getTime 是以 UTC 時間計算，所以不用考慮時區
  const time = new Date().getTime()
  const param = new URLSearchParams({
    id: btoa(user_id),
    pw,
    time: btoa(time),
    project_type: default_project_type,
    funcId,
    appId: funcId ? 'AI' : '',
  })
  if (viewOnly) {
    param.append('view', '')
  }
  const fullUrl = `${domain}/login.html?${param.toString()}`
  if (sameWindow) {
    window.location.href = fullUrl
  } else {
    window.open(fullUrl)
  }
}

class Credential extends Emitter {
  constructor() {
    super(['authChange', 'userChange', 'groupChange', 'teamChange'])
    this._user = null
    this._group = null
    this._team = {}
    this.authenticated = false
    this.sessionExpireHandler = null
    this.auth = firebase.auth()
    firebase.auth().onAuthStateChanged((user) => {
      this.emit('authChange', [user])
    })
  }
  get user() {
    return this._user
  }
  set user(userData) {
    this._user = userData
      ? new Proxy(userData, {
          set: (obj, key, newValue) => {
            // The default behavior to store the value
            obj[key] = newValue

            this.emit('userChange', [obj])
            // Indicate success
            return true
          },
        })
      : userData
    this.emit('userChange', [userData])
  }
  get group() {
    return this._group
  }
  set group(groupData) {
    this._group = groupData
      ? new Proxy(groupData, {
          set: (obj, key, newValue) => {
            // The default behavior to store the value
            obj[key] = newValue

            this.emit('groupChange', [obj])

            // Indicate success
            return true
          },
        })
      : groupData
    this.emit('groupChange', [groupData])
  }
  get team() {
    return this._team
  }
  set team(teamData) {
    this._team = teamData
      ? new Proxy(teamData, {
          set: (obj, key, newValue) => {
            // The default behavior to store the value
            obj[key] = newValue

            this.emit('teamChange', [obj])

            // Indicate success
            return true
          },
        })
      : teamData
    this.emit('teamChange', [teamData])
  }
  async createTeam(team_name, team_description) {
    const createdTeamsCount = Object.values(this.team).reduce(
      (a, { team_create_by }) =>
        a + (team_create_by === this.user.email ? 1 : 0),
      0
    )
    if (
      createdTeamsCount >= (this.user.create_team_limit ?? CREATE_TEAM_LIMIT)
    ) {
      throw new ExtendedError(ERROR_CODE_MAP.ExceedTeamCreateLimit.id, {
        limit: 1,
      })
    } else if (this.group.status !== 1) {
      throw new ExtendedError(ERROR_CODE_MAP.Unauthorized.id, {
        action_name: '新增團隊',
        reason: '非付費帳號無法新增團隊',
      })
    }
    const team_id = await createTeamId()
    const create_time = getCurrUtcDateTime()
    const { email } = this.user
    const { domain_key } = this.group
    const teamData = {
      team_id,
      team_name,
      team_description,
      admin: email,
      domain_key,
      create_time,
      modify_time: create_time,
      create_by: email,
      modify_by: email,
    }

    await createTeam(team_id, teamData)
    await insertMemberToDb(team_id, { email, create_by: email, auth: 0 })
    await signUp({
      user_id: email,
      password: atob(this.user.pw),
      user_name: this.user.user_name,
      group_id: team_id,
      domain_key,
      isTeam: true,
    })
    await this.updateTeamInfo()
    return teamData
  }
  async insertMember(team_id, email) {
    const currAuthInTeam = this.team[team_id]?.auth
    if (_.isNil(currAuthInTeam)) {
      throw new ExtendedError(ERROR_CODE_MAP.UserNotInTeam.id, {
        email: this.user.email,
      })
    } else if (![0, 1].includes(currAuthInTeam)) {
      throw new ExtendedError(ERROR_CODE_MAP.Unauthorized.id, {
        action_name: 'insertMember',
        reason: '只有管理員和副管理員可以新增成員',
      })
    }
    const userData = await fetchUser(email)
    if (_.isNil(userData)) {
      throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id, {
        email,
      })
    }

    const currUserAuth = await fetchTeamMemberAuth(team_id, email)
    const isNewMember = _.isNil(currUserAuth)
    const isAlreadyInTeam = [0, 1, 2].includes(currUserAuth)

    if (isAlreadyInTeam) {
      throw new ExtendedError(ERROR_CODE_MAP.AlreadyInTeam.id, {
        email,
      })
    } else if (isNewMember) {
      await signUp({
        user_id: email,
        password: atob(userData.password),
        user_name: userData.user_name,
        group_id: team_id,
        domain_key: this.team[team_id].domain_key,
        isTeam: true,
      })
      await insertMemberToDb(team_id, {
        email,
        create_by: this.user.email,
        auth: 2,
      })
    } else {
      await enableUser({
        email,
        group_id: team_id,
        modify_by: getUserId(this.user.email, team_id),
        domain_key: this.team[team_id].domain_key,
      })
      await updateMemberToDb(
        team_id,
        {
          email,
          auth: 2,
          modify_by: this.user.email,
        },
        true
      )
    }
  }
  async leaveTeam(team_id) {
    const currAuthInTeam = this.team[team_id].auth
    if (currAuthInTeam === 0) {
      throw new ExtendedError(ERROR_CODE_MAP.OperationError.id, {
        reason: '團隊管理員不能自行退出團隊',
      })
    }
    await updateMemberToDb(team_id, {
      email: this.user.email,
      auth: 10,
      modify_by: this.user.email,
    })
    await disableUser({
      email: this.user.email,
      group_id: team_id,
      modify_by: getUserId(this.user.email, team_id),
      domain_key: this.team[team_id].domain_key,
    })
    await this.updateTeamInfo()
  }
  async kickOutMember(team_id, email) {
    const currAuthInTeam = this.team[team_id].auth
    if (currAuthInTeam !== 0) {
      throw new ExtendedError(ERROR_CODE_MAP.Unauthorized.id, {
        action_name: 'kickOutMember',
        reason: '只有管理員可以踢成員',
      })
    } else if (email === this.user.email) {
      throw new ExtendedError(ERROR_CODE_MAP.OperationError.id, {
        reason: '團隊不可剔除自己',
      })
    }

    await updateMemberToDb(team_id, {
      email,
      auth: 9,
      modify_by: this.user.email,
    })
    await disableUser({
      email,
      group_id: team_id,
      modify_by: getUserId(this.user.email, team_id),
      domain_key: this.team[team_id].domain_key,
    })
  }
  async assignDeputyAdministrator(team_id, email) {
    const currAuthInTeam = this.team[team_id]?.auth

    if (_.isNil(currAuthInTeam)) {
      throw new ExtendedError(ERROR_CODE_MAP.UserNotInTeam.id, {
        email: this.user.email,
      })
    } else if (currAuthInTeam !== 0) {
      throw new ExtendedError(ERROR_CODE_MAP.Unauthorized.id, {
        action_name: 'assignDeputyAdministrator',
        reason: '只有團隊管理員可以指派副管理員',
      })
    } else if (email === this.user.email) {
      throw new ExtendedError(ERROR_CODE_MAP.OperationError.id, {
        reason: '不可指派自己為副管理員',
      })
    }
    await updateMemberToDb(team_id, {
      email,
      auth: 1,
      modify_by: this.user.email,
    })
  }
  transferPoints(team_id, transferMap) {
    const currAuthInTeam = this.team[team_id]?.auth
    if (_.isNil(currAuthInTeam)) {
      throw new ExtendedError(ERROR_CODE_MAP.UserNotInTeam.id, {
        email: this.user.email,
      })
    } else if (currAuthInTeam !== 0) {
      throw new ExtendedError(ERROR_CODE_MAP.Unauthorized.id, {
        action_name: 'transferPoints',
        reason: '只有管理員可以轉移點數',
      })
    }
    return transferPointsToTeam(
      this.group.group_id,
      team_id,
      getUserId(this.user.email, this.group.group_id),
      Object.entries(transferMap).map(([project_type, points]) => ({
        project_type: project_type.toString(),
        transfer_point: points,
      }))
    )
  }
  async registerCourse(course_id, start_time) {
    const response = await registerCourse({
      email: this.user.email,
      course_id,
      course_time: start_time,
      uid: this.user.uid,
    })
    await this.updateGroup()
    fetchCourse(course_id).then((data) => {
      const { course_name, course_time: courseTimeMap } = data
      const courseTimeData = Object.values(courseTimeMap).find(
        (obj) => obj.start_time === start_time
      )
      const { end_time } = courseTimeData
      sendCourseRegistrationConfirmation(
        {
          user_name: this.user.user_name,
          email: this.user.email,
          company_name: this.group.company_name,
          tax_id: this.group.tax_id,
          contact_person_tel: this.group.contact_person_tel,
          // 報名時間
          create_time: getFormattedDateTime(response.create_time),
        },
        {
          course_id,
          course_name,
          start_time: getFormattedDateTime(start_time),
          end_time: getFormattedDateTime(end_time),
        }
      )
      sendCourseRegistrationNotification(
        {
          user_name: this.user.user_name,
          email: this.user.email,
        },
        {
          course_name,
          start_time: getFormattedDateTime(start_time),
          end_time: getFormattedDateTime(end_time),
        }
      )
    })
    return response
  }
  updateAuthenticated() {
    this.authenticated = this.user?.status === 1 && this.user?.emailVerified
  }
  async init() {
    const user = firebase.auth().currentUser
    if (user && this.user?.uid !== user.uid) {
      this.user = user
      const isAdmin = await checkIsAdmin(user.email)
      await this.updateGroup()
      await admin.updateAdminInfo()
      admin.user_id = isAdmin ? user.email : null
      await this.updateTeamInfo()
    } else if (!user) {
      this.user = null
      this.group = null
    }
    this.updateAuthenticated()
  }
  async signIn(email, password) {
    const formattedEmail = email.toLowerCase()
    const isDisabled = await checkIfUserDisabled(formattedEmail)
    if (isDisabled) {
      throw new ExtendedError(ERROR_CODE_MAP.Unauthorized.id, {
        action_name: '登入',
        reason: '帳號禁用',
      })
    }
    const alreadyReadTerms = await checkIfUserReadTerms(formattedEmail)
    if (!alreadyReadTerms) throw new ExtendedError('shouldReadTerms')
    const userCredential = await firebase
      .auth()
      .signInWithEmailAndPassword(formattedEmail, password)
    const idToken = await userCredential.user.getIdToken()
    await customFetch('/api/user/login', {
      headers: {
        Authorization: 'Bearer ' + idToken,
      },
    })
    await this.init()
  }
  async authenticate(currentUser) {
    try {
      const customToken = await customFetch('/api/user/token').then((data) =>
        data.text()
      )
      // 全域會話存在，局部會話不存在
      if (customToken && !currentUser) {
        const userCredential = await firebase
          .auth()
          .signInWithCustomToken(customToken)
        return userCredential.user
      } else if (
        !customToken &&
        currentUser &&
        process.env.REACT_APP_PRODUCTION === 'true'
      ) {
        await this.signOut()
        return false
      } else {
        return currentUser
      }
    } catch (error) {
      logger.warn('Failed authenticate...', error)
      return false
    }
  }
  async signOut() {
    await Promise.all([
      customFetch('/api/user/logout'),
      await firebase.auth().signOut(),
    ])
    this.user = null
    this.group = null
    this.team = {}
    this.updateAuthenticated()
    admin.user_id = null
    admin.adminInfo = null
    this.removeSessionExpireTimer()
  }
  async signUp(email, password, option = {}) {
    const group_id = await createGroupId()
    const formattedEmail = email.toLowerCase()
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(formattedEmail, password)
    const { user } = userCredential
    const encodedPassword = btoa(password)
    const { group } = await createUserAndGroup(group_id, user.uid, {
      password: encodedPassword,
      email: formattedEmail,
      ...option,
    })
    await this.init()
    await this.sendEmailVerification()
    await sendUserSignUpNotification({
      email: formattedEmail,
      user_name: option.user_name,
      create_time: group.create_time,
    })
    return this
  }
  toPlatform(funcId, sameWindow = false, viewOnly = false) {
    if (!this.group || !this.user) {
      throw new ExtendedError(ERROR_CODE_MAP.MissingAuthentication.id)
    }
    const { domain_key, group_id, default_project_type = 1 } = this.group
    const { domain } = DOMAIN_MAP[domain_key]
    const { email, pw } = this.user
    headToPlatform({
      email,
      group_id,
      domain,
      pw,
      default_project_type,
      funcId,
      sameWindow,
      viewOnly,
    })
  }
  toTrialPlatform() {
    if (!this.group || !this.user) {
      throw new ExtendedError(ERROR_CODE_MAP.MissingAuthentication.id)
    }
    const { group_id } = this.group
    const default_project_type = 1
    const { domain } = DOMAIN_MAP[TRIAL_DOMAIN_KEY]
    const { email, pw } = this.user
    headToPlatform({ email, group_id, domain, pw, default_project_type })
  }
  toPlatformAsTeamMember(team_id) {
    if (!this.group || !this.user) {
      throw new ExtendedError(ERROR_CODE_MAP.MissingAuthentication.id)
    }
    const { domain_key } = this.team[team_id]
    const { domain } = DOMAIN_MAP[domain_key]
    const { email, pw } = this.user

    headToPlatform({
      email,
      group_id: team_id,
      domain,
      pw,
      default_project_type: 1,
    })
  }
  async updateGroup() {
    const {
      group,
      user_name,
      password,
      status,
      is_group_admin,
      create_time,
      create_team_limit,
      course_registration,
      uploaded_business_card,
      trial_platform_status,
      bonus,
      group_id,
    } = await fetchUserAndGroup({ uid: this.user.uid })
    this.group = group
    this.user = Object.assign({}, this.user, {
      user_name,
      pw: password,
      status: status,
      is_group_admin: is_group_admin,
      create_time: create_time,
      create_team_limit: create_team_limit,
      course_registration: course_registration,
      uploaded_business_card: uploaded_business_card,
      trial_platform_status: trial_platform_status,
      bonus: bonus ?? {},
      group_id,
    })
  }
  async updateTeamInfo() {
    const teamMap = await fetchTeamByUser(this.user.email)
    _.each(this.team, (val, key) => delete this.team[key])
    Object.assign(this.team, teamMap)
  }
  async updateGroupToDb(group_id, option) {
    try {
      const response = await updateGroup(group_id, option)
      this.group = response
      return response
    } catch (error) {
      swal('錯誤', '更新失敗', 'error')
      logger.error('Failed update group to db...', error)
    }
  }
  async updateUserToDb(uid, option) {
    try {
      const response = await updateUser(uid, option)
      await updateUserToPlatform({
        email: this.user.email,
        group_id: this.group.group_id,
        modify_by: getUserId(this.user.email, this.group.group_id),
        domain_key: this.group.domain_key,
        user_name: option.user_name,
      })
      this.user.user_name = response.user_name
      return response
    } catch (error) {
      swal('錯誤', '更新失敗', 'error')
      logger.error('Failed update user to db...', error)
    }
  }
  sendEmailVerification() {
    const user = firebase.auth().currentUser
    if (user) {
      firebase.auth().languageCode = navigator.language
      return user.sendEmailVerification()
    } else {
      console.warn('沒有登入不能寄送驗證信')
    }
  }
  setSessionExpireTimer(handler) {
    this.sessionExpireHandler = _.debounce(() => {
      this.signOut()
      handler()
    }, SESSION_EXPIRE_MIN * 60 * 1000)
    this.sessionExpireHandler()
    window.document.addEventListener('click', this.sessionExpireHandler)
  }
  removeSessionExpireTimer() {
    if (!this.sessionExpireHandler) {
      return
    }
    window.document.removeEventListener('click', this.sessionExpireHandler)
    this.sessionExpireHandler.cancel()
    this.sessionExpireHandler = null
  }
  async sendPasswordResetEmail(email) {
    if (!email) {
      throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
        variable: 'email',
        expected_type: 'string',
        curr_type: getType(email),
      })
    }
    const userAndGroup = await fetchUserAndGroup({
      email,
    })
    if (!userAndGroup) {
      throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id, {
        email,
      })
    }
    const { domain_key } = userAndGroup.group
    if (!domain_key) {
      throw new ExtendedError(ERROR_CODE_MAP.AccountNotActivated.id)
    }
    firebase.auth().languageCode = navigator.language
    return firebase.auth().sendPasswordResetEmail(email)
  }
  /**
   * user 需要新增的資料： pwd_modify_time,
   */
  async updatePassword(oldPassword, newPassword) {
    const user = firebase.auth().currentUser
    const email = user?.email
    if (!email) {
      throw new ExtendedError(ERROR_CODE_MAP.MissingAuthentication.id)
    }
    const { group_id, domain_key } = this.group
    const domainKeyMap = {}
    if (domain_key) {
      domainKeyMap[domain_key] = [{ group_id, email }]
      const teamMap = await fetchTeamByUser(email, true)

      _.each(teamMap, ({ domain_key }, team_id) => {
        if (domainKeyMap[domain_key]) {
          domainKeyMap[domain_key].push({ email, group_id: team_id })
        } else {
          domainKeyMap[domain_key] = [{ email, group_id: team_id }]
        }
      })
    }

    try {
      const authCredential = await firebase.auth.EmailAuthProvider.credential(
        email,
        oldPassword
      )
      await user.reauthenticateWithCredential(authCredential)
      await user.updatePassword(newPassword)

      await Promise.all(
        _.map(domainKeyMap, (userInfoList, domain_key) =>
          updatePassword({
            userInfoList,
            newPassword,
            oldPassword,
            domain_key,
          }).catch((error) => {
            console.warn('update password fail.', domain_key)
            throw new ExtendedError(ERROR_CODE_MAP.Unknown.id)
          })
        )
      )
      await updateUser(user.uid, {
        password: btoa(newPassword),
      })
      // 新增  pwd_modify_time
      await databaseCrud.update(`/user/${user.uid}`, {
        pwd_modify_time: getCurrUtcDateTime(),
      })
    } catch (error) {
      switch (error.code) {
        // firebase re-authenticate
        case 'auth/user-mismatch':
        case 'auth/user-not-found':
          console.warn(error.code)
          throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id, { email })
        case 'auth/wrong-password':
          console.warn(error.code)
          throw new ExtendedError(ERROR_CODE_MAP.IncorrectPassword.id, {
            email,
          })
        // firebase update password
        case 'auth/weak-password':
          console.warn(error.code)
          throw new ExtendedError(ERROR_CODE_MAP.WeakPassword.id)
        default: {
          logger.error('Failed update password...', error)
          if (error instanceof ExtendedError) {
            // 更新平台密碼失敗
            error.print()
            throw error
          } else {
            // 更新 firebase DB 密碼失敗
            throw new ExtendedError(ERROR_CODE_MAP.Unknown.id)
          }
        }
      }
    }
  }
}
const credential = new Credential()

export default credential
