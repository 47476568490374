import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import customFetch from '../../fetch'
import { useEmulator } from '../../firebase.init'

// 模擬器不能瀏覽中文路徑
const getOrderPdfName = function (order_id) {
  return `${useEmulator ? this.prefix : this.text}-${order_id}.pdf`
}
const getOrderPdfPath = function (order_id) {
  return `${this.prefix}/${this.getFileName(order_id)}`
}
const getOrderFilePath = function (order_id, filename) {
  const ext = filename.split('.').pop()
  return `${this.prefix}/${useEmulator ? this.prefix : this.text}-${order_id}${
    ext ? '.' + ext : ''
  }`
}
const storagePaths = {
  // 可以事後回推路徑，但無副檔名，建議只用來檢視
  businessCard: {
    prefix: 'business-card',
    text: '名片',
    getPath(uid) {
      return this.prefix + '/' + uid
    },
    upload(file, uid) {
      return uploadFileToStorage(this.getPath(uid), file, true)
    },
  },
  // 無法事後回推路徑，需儲存路徑
  bannerImage: {
    prefix: 'bannerImage',
    text: '公告圖片',
    getPath(filename) {
      return this.prefix + '/' + filename
    },
    upload(file) {
      return uploadFileToStorage(this.getPath(file.name), file, false)
    },
    checkIfExist(filename) {
      return checkIfExist(this.getPath(filename))
    },
  },
  // 無法事後回推路徑，需儲存路徑
  newsImage: {
    prefix: 'newsImage',
    text: '新聞圖片',
    getPath(filename) {
      return this.prefix + '/' + filename
    },
    upload(file) {
      return uploadFileToStorage(this.getPath(file.name), file, false)
    },
  },
  // 無法事後回推路徑，需儲存路徑
  newsCoverImage: {
    prefix: 'newsImage/coverImage',
    text: '新聞封面圖片',
    getPath(filename) {
      return this.prefix + '/' + filename
    },
    upload(file) {
      return uploadFileToStorage(this.getPath(file.name), file, true)
    },
  },
  // 可能是 PDF 或圖片，無法事後回推路徑，需儲存路徑
  invoice: {
    prefix: 'invoice',
    text: '發票',
    getPath: getOrderFilePath,
    upload(file, order_id) {
      return uploadFileToStorage(this.getPath(order_id, file.name), file, true)
    },
    checkIfExist(filename, order_id) {
      return checkIfExist(this.getPath(order_id, filename))
    },
  },
  // 可能是 PDF 或圖片，無法事後回推路徑，需儲存路徑
  quotation: {
    prefix: 'quotation',
    text: '回傳報價單',
    getPath: getOrderFilePath,
    upload(file, order_id) {
      return uploadFileToStorage(this.getPath(order_id, file.name), file, true)
    },
    checkIfExist(filename, order_id) {
      return checkIfExist(this.getPath(order_id, filename))
    },
  },
  // 可能是 PDF 或圖片，無法事後回推路徑，需儲存路徑
  remittance: {
    prefix: 'remittance',
    text: '匯款單',
    getPath: getOrderFilePath,
    upload(file, order_id) {
      return uploadFileToStorage(this.getPath(order_id, file.name), file, true)
    },
    checkIfExist(filename, order_id) {
      return checkIfExist(this.getPath(order_id, filename))
    },
  },
  // 固定為 PDF 檔案，可以事後回推路徑
  orig_quotation: {
    prefix: 'orig-quotation',
    text: '原始報價單',
    getFileName: getOrderPdfName,
    getPath: getOrderPdfPath,
    upload(file, order_id) {
      return uploadFileToStorage(this.getPath(order_id, file.name), file, true)
    },
  },
  // 固定為 PDF 檔案，可以事後回推路徑
  terms: {
    prefix: 'terms',
    text: '購買同意條款',
    getFileName: getOrderPdfName,
    getPath: getOrderPdfPath,
    upload(file, order_id) {
      return uploadFileToStorage(this.getPath(order_id, file.name), file, true)
    },
  },
  // 單純用於顯示資料夾中所有圖片，無實作上傳功能，需至 Firebase console 上傳
  citdProcess: {
    prefix: 'citdProcess',
    text: '工業館',
    getPath() {
      return this.prefix
    },
  },
}
/**
 * 上傳檔案到 Storage
 * 只開放給後台開發人員使用，一般上傳請用 storagePaths 中的 upload 方法
 * @param {string} path
 * @param {File} file
 * @param {boolean} isOverwrite - 是否覆蓋
 * @param {object} metadata - 檔案的 metadata，參考 {@link https://firebase.google.com/docs/storage/web/upload-files#add_file_metadata | Firebase 文件}
 * @returns
 */
async function uploadFileToStorage(
  path,
  file,
  isOverwrite = true,
  metadata = {}
) {
  try {
    if (!isOverwrite && (await checkIfExist(path))) {
      throw new ExtendedError(ERROR_CODE_MAP.FileExist.id, {
        ref: path,
      })
    }
    const storageRef = firebase.storage().ref(path)
    const uploadTask = storageRef.put(file, metadata)
    const fileUrl = await uploadTask.then((snapshot) =>
      snapshot.ref.getDownloadURL()
    )

    return fileUrl
  } catch (error) {
    if (error instanceof ExtendedError) {
      error.print()
    } else {
      console.warn('上傳檔案失敗', error)
    }
    throw error
  }
}
/**
 * 刪除檔案
 * @param {string} path
 */
async function deleteFileFromStorage(path) {
  try {
    const storageRef = firebase.storage().ref(path)
    const deleteTask = storageRef.delete()
    await deleteTask
  } catch (error) {
    if (error instanceof ExtendedError) {
      error.print()
    } else {
      console.warn('刪除檔案失敗', error)
    }
    throw error
  }
}
/**
 * 檢查檔案是否存在
 * @param {string} path
 * @returns {boolean}
 */
async function checkIfExist(path) {
  const ref = firebase.storage().ref(path)
  try {
    const url = await ref.getDownloadURL()
    return true
  } catch (error) {
    // 路徑不存在也會噴 unauthorized 錯誤
    if (
      error.code === 'storage/object-not-found' ||
      error.code === 'storage/unauthorized'
    ) {
      return false
    } else {
      throw error
    }
  }
}

function checkIfPathExist(path) {
  const ref = firebase.storage().ref(path)
  if (ref) {
    return true
  } else {
    return false
  }
}

/**
 * 取得檔案的（下載）連結，等同 `ref().getDownloadURL()`
 * @param {string} path
 * @returns {string | undefined}
 */
async function getFileUrl(path) {
  try {
    const url = await firebase.storage().ref(path).getDownloadURL()
    return url
  } catch (error) {
    console.warn(error)
  }
}
/**
 * 取得指定路徑下的所有檔案/資料夾，等同 `ref().listAll()`
 * @param {string} path
 * @returns {Reference[]}
 */
function getStorageFolder(path) {
  return firebase.storage().ref(path).listAll()
}
function getStorageClientFolder(path) {
  return firebase.storage().ref(`/client/${path}`).listAll()
}
/**
 * 移動檔案或重新命名
 * （下載檔案後重新上傳再刪除原本的檔案）
 * @param {string} currPath
 * @param {string} newPath
 */
async function moveFile(currPath, newPath) {
  const url = await getFileUrl(currPath)
  if (!url) {
    throw new ExtendedError(ERROR_CODE_MAP.FileNotExist.id, {
      ref: currPath,
    })
  }
  if (await checkIfExist(newPath)) {
    throw new ExtendedError(ERROR_CODE_MAP.FileExist.id, {
      ref: newPath,
    })
  }
  const response = await customFetch(url)
  const blob = await response.blob()
  await uploadFileToStorage(newPath, blob)
  await deleteFileFromStorage(currPath)
}

export {
  storagePaths,
  deleteFileFromStorage,
  getFileUrl,
  getStorageFolder,
  getStorageClientFolder,
  moveFile,
  uploadFileToStorage,
  checkIfPathExist,
}
