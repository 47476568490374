const en = {
  AboutUs: 'About Us',
  UseCase: 'Use Case',
  Pricing: 'Pricing',
  Solution: 'Solution',
  ContactUs: 'Contact Us',
  Name: 'Name',
  LastName: 'Last Name',
  Phone: 'Phone',
  ProductKnowHow: 'Question about Product',
  Description: 'Description',
  MemberProfile: 'Member Profile',
  MemberAccount: 'Member Account',
  OrderRecord: 'Order History',
  BuyPoint: 'Buy Point',
  PointsRecord: 'Point Transaction History',
  certificationLetterCannotSentContinuouslyPleaseTryAgainLater:
    'The certification letter cannot be sent continuously, please try again later!',
  Account: 'Account',
  AccountManagement: 'Account Management',
  Password: 'Password',
  UseAiPlatform: 'Use Platform',
  SignOut: 'Log Out',
  Login: 'Log In',
  LoginSuccess: 'Login Successful',
  LogoutSuccess: 'Logout Successful',
  SignUp: 'Register',
  SignUpSuccess: 'Registration Successful',
  TypeOfQuestion: 'Question Type',
  Submit: 'Submit',
  RequestDemo: 'Request Demo',
  StartForFree: 'Start Free Trial',
  ProductFeature: 'Product Feature',
  MusesAIFeature: 'MusesAI Feature',
  RedirectToHomePageAfterThreeSec: 'Redirecting to Home Page in 3 Seconds',
  PleaseEnterCorrectEmail: 'Please enter email in the correct format',
  WrongPassword: 'Password Error',
  AccountNotExist: 'Account Does Not Exist',
  Error: 'Error',
  UnexpectedErrorPleaseContactSystemAdmin:
    'Unexpected error！ Please contact system admin！',
  PleaseEnterYourAccount: 'Enter Your Account',
  RequiredFields: 'Required Fields',
  PasswordMustBeAtLeastSixDigits:
    'The password must be at least 6 characters long',
  PleaseEnterAlphabetOrNumbers: 'Enter alphanumeric characters',
  PleaseContinueEmailAuth: 'Please complete email verification process',
  AlreadyGotAccount: 'Already have an account?',
  UserName: 'Username',
  SetAUniqueIdentificationCodeForAGroupOfCompanies:
    'Set Unique Enterprise Identification Code',
  PleaseEnterAlphabetAndNumbersCaseSensitive:
    'Enter alphanumeric characters (case sensitive)',
  SignUpNewEnterpriseAccount: 'Register New Enterprise Account',
  CompanyName: 'Company Name',
  CompanyAddress: 'Company Address',
  ContactPersonName: 'Contact Person Name',
  ContactPersonEmail: 'Contact Person Email',
  ContactPersonPhone: 'Contact Person Phone Number',
  SameAsUserName: 'Same as Username',
  SameAccount: 'Same Account',
  TaxId: 'Tax ID',
  RefAgentId: 'Referrer Number',
  SendVerificationLetter: 'Send Verification Email',
  SendVerificationLetterError: 'Error Sending Verification Email',
  MailboxVerification: 'Email address verification',
  Verified: 'Verified',
  Unverified: 'Unverified',
  Activated: 'Activated',
  NotActivated: 'Not Activated',
  Refuse: 'Refuse',
  SendingSuccess: 'Sent Successfully',
  PleaseCheckTheVerificationLetterInYourMailbox:
    'Check your inbox for the verification email',
  NeedToWaitForXSecToSendAgain: 'Wait {sec} seconds before sending again',
  PleaseContactTheSystemAdmin: 'Please contact the system administrator',
  FailedToGetTheRemainingPoints: 'Failed to retrieve remaining points',
  RemainingPoints: 'Remaining points',
  Status: 'Status',
  CreateTime: 'Time of Creation',
  EnterpriseInformation: 'Business Information',
  EnterpriseAccount: 'Business Account',
  EnterpriseName: 'Business Name',
  EnterpriseAddress: 'Business Address',
  EnterpriseTel: 'Business Phone Number',
  OrderNumber: 'Order Number',
  Type: 'Type',
  PriceA: 'Price',
  PriceB: 'Price',
  Amount: 'Quantity',
  SubTotal: 'SubTotal',
  TotalPoints: 'Total Points',
  Cancel: 'Cancel',
  OrderCreateTime: 'Time of Order',
  // BuyPoints.js
  user_name: 'User Name',
  contact_person_name: 'Contact Person',
  contact_person_tel: 'Contact Phone Number',
  ChoosePlan: 'Choose Plan',
  ConfirmOrder: 'Confirm Order',
  OrderEstablished: 'Order Established',
  DownloadQuotation: 'Download Quotation',
  DownloadTerms: 'Download Terms',
  SignBackTheQuotationAndSendItBackToServtech:
    'Sign back the quotation and send it back to Servtech',
  SendMoneyAccordingToTheQuotedAccountAndNotifyServtechViaEmail:
    'Remit funds according to the quotation account and notify Servtech via email',
  ServtechConfirmReceiptOfMoney: 'Servtech confirms receipt of payment',
  ServtechProvidePointsAndEmailNotification:
    'Servtech provides points and email notification',
  PurchaseProcess: 'Purchase process',
  IAlreadyUnderstandThePurchaseProcedure:
    'I already understand the purchase procedure',
  NextStep: 'Next',
  And: 'and',
  Points: 'Points',
  Dot: 'points',
  PleaseDragTheScrollToViewTheFullForm:
    'Please drag the scroll to view the full form',
  TurnBack: 'Back',
  YourSystemIsIOS: 'Your System Is IOS',
  CurrentlyDoesNotSupportIOSSystemOperationPleaseUseComputerToOperate:
    'Currently does not support IOS system operation, please use a computer to operate, thank you!',
  Quotation: 'Quotation',
  Terms: 'Terms',
  ProjectPoint: 'Project Point',
  Fail: 'Fail',
  ErrorOccurred: 'Error',
  PleaseSelectAtLeastOnePlan: 'Please select at least one plan',
  FailedToPlaceOrder: 'Failed to place order',
  OrderCompleted: 'Order completed',
  ThanksForPurchase: 'Thanks for purchase',
  RedirectToOrderPageInXSec: 'Will go to the order page in {sec} seconds',
  ConfirmEdit: 'Confirm edit',
  Edit: 'Edit',
  ManagementRights: 'Management Rights',
  True: 'Yes',
  False: 'Not',
  Verify: 'Review',
  ProjectId: 'Project ID',
  ProjectType: 'Project Type',
  ActionType: 'Action Type',
  StartTime: 'Start Time',
  EndTime: 'End Time',
  TotalTimeMinutes: 'Total Time (min)',
  UsePoints: 'Use Points',
  EditSuccess: 'Edit Successfully',
  Mode: 'Mode',
  YourMemberDataIsIncomplete: 'Your member profile is incomplete',
  YourMemberDataIsIncompletePleaseFillInCompleteInformationBeforeBuyPoint:
    'Your member/enterprise information is incomplete, please fill in the complete information before proceeding to purchase points, thank you!',
  PleaseFillInTheRequiredFields: 'Please fill in the required fields!',
  IncorrectPattern: 'Incorrect format!',
  AgentIdCanOnlyEditOnce: 'Agent Id can only edit once',
  YouCanNotEditAgainAfterConfirm:
    'You will not be able to modify the Agent Id after confirmation. Are you sure you want to change it?',
  MustBeCompanyMail: 'Must be company email',
  PleaseFillInTheCompanyMail: 'Please fill in the company mail',
  MySpace: 'My Space',
  GroupInfo: '團隊資料',
  GroupMembers: '團隊成員',
  PointsTransfer: '轉移點數',
  CreateSuccess: '新增成功',
  TeamName: '團隊名稱',
  EnterTeamName: '請輸入團隊名稱',
  TeamDescription: '團隊簡介',
  OptionalField: '此為選填欄位',
  CreateConfirm: '建立確認',
  CreateTeam: '新增團隊',
  GroupList: '團隊列表',
  ProductDescription: 'Product Description',
  AboutPage: {
    'About-Content':
      '    The MusesAI platform allows creation of models through a webpage interface that does not require any programming, enabling true AI democratization. The AI platform requires labelling only few images to begin visual recognition, making it simple to deploy on the production line and quick to reach the factory ROI set by the client. \n\r\n\r    There are few AI application tools made for the manufacturing industry currently on the market. Webpage design, in the early days, required programming. But with the introduction of tools such as FrontPage and Dreamweaver, webpage development became much more accessible, allowing people from all fields of expertise to realize the ideas in their mind and setting the foundation for the explosive growth of the internet. Our mission for the AI industry is to achieve the same. Similar to web design tools, AI development tools are transforming various industries and MusesAI is particularly adapted to workstations in the manufacturing industry that rely on manpower. Through an AI tool made for the manufacturing industry, we hope you can experience how AI can bring benefits to the production line in a quick and simple way. We hope to have you with us as we march forward on the frontiers of AI tool development and grow together.',
    'CompanyProfile': 'Company Profile',
    'IntroCardInfo-Automation-Title':
      'Automation Integration (Hardware/Software Integration)',
    'IntroCardInfo-Automation-Content':
      'Taiwan Machine Tool Connect (TMTC), a communication protocol developed entirely in-house extends across various hardware and equipment by effectively collecting data, thereby achieving an equipment interface rate of over 90%, regardless of brand and model, and subsequently launching in the Asian, European, and American markets',
    'IntroCardInfo-SystemIntegration-Title':
      'System Integration (Software Integration)',
    'IntroCardInfo-SystemIntegration-Content':
      'Integrating and interfacing related customer software from all domains, including systems such as ERP, MES, and APS, into our system for visualization on a uniform platform.',
    'IntroCardInfo-Management-Title':
      'Management Consultant (IT+IE Consultant Service)',
    'IntroCardInfo-Management-Content':
      'Adopting lean management principles to cut right to the core of customer issues. We bring administrative consultancy resources to help the client deliver solutions to problems from all aspects of the company, starting with the most basic "personnel problems". We dissect the situation into different facets and effectively address the customer\'s pain points.',
    'IntroCardInfo-DataAnalysis-Title': 'Smartification (Data Analysis)',
    'IntroCardInfo-DataAnalysis-Content':
      "From automation, system integration, management consultancy, to platform applications, we hope to leverage the company's core competency to maximize the benefits of smartification, extending vertically from the factory itself to the supply chain both upstream and downstream to create a customized intelligent factory for the client",
  },
  UseCasePage: {
    'Banner-Title':
      'A simple image annotation service that uses deep learning technology and action logic recognition templates to reduce difficulty of development and time investment',
    'Banner-SubTitle':
      'Using AI Framework, UTILIZING AI has never been EASIER...',
    'SolutionInfo-PCB-Title': 'PCB Assembly',
    'SolutionInfo-PCB-Content': {
      Part_1: 'SOP Check',
      Part_2: 'Standard Time of Operation Calculation',
    },
    'SolutionInfo-Metal-Title': 'Metal Processing',
    'SolutionInfo-Metal-Content': {
      Part_1: 'SOP Check',
      Part_2: 'Standard Time of Operation Calculation',
    },
    'SolutionInfo-Electronic-Title': 'Electronics Product Processing',
    'SolutionInfo-Electronic-Content': {
      Part_1: 'SOP Check',
      Part_2: 'Standard Time of Operation Calculation',
    },
    'SolutionInfo-Factory-Title': 'Factory Monitoring',
    'SolutionInfo-Factory-Content': {
      Part_1: 'Idle Personnel',
      Part_2: 'Equipment Anomaly Detection',
      Part_3: 'Equipment Warning Light Recognition',
      Part_4: 'Task and Object Position Monitoring',
    },
  },
  PricingPage: {
    'Banner-Title': 'Start for free, pay as you grow',
    'Banner-SubTitle': 'State of the art solutions for every AI skill level',
    'Price-Description': 'Pricing',
    'Price-Description-Content': {
      content_1:
        '1. NTD 5000 per point. Each point can be used to execute model training or prediction for 1 hour',
      content_2: '2. Minimum purchase is 40 points',
      content_3:
        '3. Points for different projects must be purchased separately and cannot be transferred from one project to another',
    },
    'ContactUsToKnowDetail': 'Learn More',
    'PricePlan-Free-Title': 'Trial Plan',
    'PricePlan-Free-PaymentNum': '0',
    'PricePlan-Free-PaymentUnit': 'NT',
    'PricePlan-Free-Info': {
      Info_1: 'Register Business Account',
    },
    'PricePlan-Base-Title': 'Basic Plan',
    'PricePlan-Base-PaymentNum': '40',
    'PricePlan-Base-PaymentUnit': 'Points',
    'PricePlan-Base-Info': {
      Info_1: 'Best for small businesses',
    },
    'PricePlan-Advanced-Title': 'Advanced Plan',
    'PricePlan-Advanced-PaymentNum': '80',
    'PricePlan-Advanced-PaymentUnit': 'Points',
    'PricePlan-Advanced-Info': {
      Info_1: 'Best for medium-sized businesses',
    },
    'PricePlan-Compare': 'Plan Comparison',
    'PricePlan-FreePlan': 'Free Plan',
    'PricePlan-PayPlan': 'Paid Plan',
    'PricePlan-Compare-TrainSOP-Title': 'Training Model SOP Troubleshooting',
    'PricePlan-Compare-ShowResult-Title': 'View Training Results Report',
    'PricePlan-Compare-DownloadTrainModule-Title': 'Download Training Model',
  },
  HomePage: {
    BannerTitleOnTop: 'Make your life easier',
    BannerTitleOnBottom: 'Make things simpler',
    BannerSubtitle:
      'Make your own AI application without writing a single line of code. Come try our one-stop AI tool',
    DiverseUsageScenarios: 'Diverse Usage Scenarios',
    FeatureCardInfo: {
      Card_1: {
        Title: 'No Coding',
        Content:
          'The AI platform is controlled through a webpage and allows you to create an AI model on your own without requiring any programming',
      },
      Card_2: {
        Title: 'Fast Development',
        Content:
          'The AI platform requires just a few images to start performing visual recognition and quickly produce a model to achieve your desired ROI.',
      },
      Card_3: {
        Title: 'Ease of Use',
        Content:
          'Straightforward webpage interface with a one-step process allows the user to stay focused on the solution to the problem.',
      },
    },
    SituationCardInfo: {
      Card_1: {
        Topic: 'Human',
        TextArea_1: {
          Title: 'Electronic Fence',
          Content:
            'Human-machine collaboration in safety supervision and guidance',
        },
        TextArea_2: {
          Title: 'SOP Check',
          Content:
            'Checking for omissions in finished products and enhance product quality',
        },
        TextArea_3: {
          Title: 'Idle Personnel/Standard Operation Time Calculation',
          Content: 'Improving Production Line Efficiency and Capacity',
        },
      },
      Card_2: {
        Topic: 'Machine',
        TextArea_1: {
          Title: 'Equipment Anomaly Detection',
          Content: 'Actively detect non-standard behavior of equipment',
        },
        TextArea_2: {
          Title: 'Equipment Warning Light Recognition',
          Content: 'Contactless real-time monitoring of equipment status',
        },
      },
      Card_3: {
        Topic: 'Material',
        TextArea_1: {
          Title: 'Work Piece/Material Location Monitoring',
          Content: 'Material Location Tracking and Cost Control',
        },
        TextArea_2: {
          Title: 'Work Piece/Material Code Identification',
          Content: 'Recognize codes illegible after processing',
        },
        TextArea_3: {
          Title: 'Work Piece/Material Defect Inspection',
          Content: 'Picking out defect or flawed location',
        },
      },
      Card_4: {
        Topic: 'Environment',
        TextArea_1: {
          Title: 'Equipment fluid leak',
          Content:
            'Dynamically perform equipment maintenance to reduce production defects',
        },
        TextArea_2: {
          Title: 'Anomalous temperature/smoke/foreign object detection',
          Content: 'Prevent hazards in the working environment',
        },
      },
    },
  },
}

export default en
