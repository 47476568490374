import Decimal from 'decimal.js'
import _ from 'lodash'
import { DOMAIN_MAP, projectTypeMap, TRIAL_DOMAIN_KEY } from '../../constants'
import credential from '../../controller/Credential/credential'
import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import {
  getCurrUtcDateTime,
  getFormattedDateTime,
  getType,
  getUserId,
} from '../../util'
import databaseCrud from '../database/crud'
import { ajax } from './ajax'

/**
 * 訓練平台資料表 `a_application_group_purchase_record` 的資料結構
 * 紀錄（購買、贈送、轉移）點數的紀錄
 * @typedef {object} PurchaseRecord
 * @property {string} group_id - 群組ID
 * @property {string} project_type - 專案類型
 * @property {decimal} pur_point - 購買點數
 * @property {datetime} pur_time - 購買時間
 * @property {datetime} payment_time - 付款時間
 * @property {string} pur_by - 購買者
 * @property {"Y" | "N"} is_remit - 是否已匯款
 */
/**
 * @param {string} group_id
 * @param {string} domain_key
 * @returns {Promise<PurchaseRecord[]>}
 */
export async function fetchPurchaseRecord(group_id, domain_key) {
  if (!group_id || !domain_key || !DOMAIN_MAP[domain_key]) {
    return []
  }
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  const url = `${domain}/scapi/v1/ai/get-purchase-record?group_id=${group_id}&key=${key}`
  const response = await ajax(url)
  return response
}
/**
 * 從系統平台上取得點數轉移紀錄，包含轉出、轉入、轉入對象
 * 過濾掉轉正、贈點、購買、試用點數紀錄
 * ---
 * SQL 語法如下
 * ```
 * SELECT * FROM `servcloud`.`a_application_group_purchase_record`
 *  WHERE `is_remit` = "Y"
 *  AND `payment_time` IS NULL
 *  AND `pur_by` <> "TrialPoint"
 *  AND (
 *    `group_id` = "{groupId}"
 *    OR
 *    `pur_by` LIKE "{groupId}_%"
 *  )
 *  ORDER BY `pur_time` DESC
 * ```
 * @param {string} group_id
 * @param {string} domain_key
 * @returns {Promise<PurchaseRecord[]>}
 */
export async function fetchTransferRecord(group_id, domain_key) {
  if (!group_id || !domain_key || !DOMAIN_MAP[domain_key]) {
    return []
  }
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  const url = `${domain}/scapi/v1/ai/transfer-record?group_id=${group_id}&key=${key}`
  const response = await ajax(url)
  // 再過濾掉轉正與贈點紀錄與非通用點數的紀錄
  return response.filter(
    ({ pur_by, project_type }) =>
      !['ConvertGroup', 'Bonus'].includes(pur_by) && +project_type === 9
  )
}
export async function fetchUseRecord(group_id, domain_key) {
  if (!domain_key || !DOMAIN_MAP[domain_key]) {
    return []
  }
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  let whereClause = `?key=${key}`
  if (group_id) {
    whereClause += `&group_id=${group_id}`
  }
  const url = `${domain}/scapi/v1/ai/get-point-record${whereClause}`
  const response = await ajax(url)
  return response
}
/**
 * 插入或更新購買紀錄的通用方法
 * @param {string} domain_key
 * @param {PurchaseRecord} purchaseRecord
 */
function insertOrUpdatePurchaseRecord(domain_key, purchaseRecord) {
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  const url = `${domain}/scapi/v1/ai/purchase-record`
  const requestParam = {
    key,
    ...purchaseRecord,
  }
  return ajax(url, {
    body: JSON.stringify(requestParam),
    headers: {
      'content-type': 'application/json',
    },
    method: 'PUT',
  })
}
/**
 * 建立訂單（尚未付款）
 * @param {object} arg1
 * @param {string} arg1.group_id
 * @param {string} arg1.domain_key
 * @param {number} arg1.pur_point
 * @param {string} arg1.pur_time
 * @param {string} arg1.project_type
 * @returns {Promise<any>}
 */
export function createOrder({
  group_id,
  domain_key,
  pur_point,
  pur_time,
  project_type,
}) {
  if (!group_id) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'group_id',
      expected_type: 'String',
      curr_type: getType(group_id),
    })
  } else if (!domain_key) {
    throw new ExtendedError(ERROR_CODE_MAP.UndefinedDomain.id)
  } else if (!credential.user) {
    throw new ExtendedError(ERROR_CODE_MAP.MissingAuthentication.id)
  }
  const { user } = credential
  const { email: user_id } = user
  const pur_by = `${group_id}_${user_id}`
  const purchaseRecord = {
    group_id,
    pur_point,
    pur_time,
    pur_by,
    is_remit: 'N',
    project_type,
  }
  return insertOrUpdatePurchaseRecord(domain_key, purchaseRecord)
}
/**
 * 更新訂單為已付款
 * @param {string} group_id
 * @param {string} pur_time
 * @param {object} option - partial PurchaseRecord
 */
export async function updateOrder(group_id, pur_time, option = {}) {
  if (!group_id) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'group_id',
      expected_type: 'String',
      curr_type: getType(group_id),
    })
  }
  const domain_key = await databaseCrud.read(`/group/${group_id}/domain_key`)
  const purchaseRecord = {
    group_id,
    pur_time,
    is_remit: 'Y',
    ...option,
  }
  return insertOrUpdatePurchaseRecord(domain_key, purchaseRecord)
}

export async function fetchLeftPoints(group_id, isTeam = false) {
  if (!group_id) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'group_id',
      expected_type: 'String',
      curr_type: getType(group_id),
    })
  }
  const baseUrl = isTeam ? '/team' : '/group'
  const domain_key = await databaseCrud.read(
    `${baseUrl}/${group_id}/domain_key`
  )
  if (!domain_key) {
    throw new ExtendedError(ERROR_CODE_MAP.UndefinedDomain.id)
  }
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  const url = `${domain}/scapi/v1/ai/group-buy-and-usage?key=${key}&group_id=${group_id}`
  try {
    const response = await ajax(url)
    const generalPointId = _.findKey(projectTypeMap, ({ general }) => general)
    const result = _.mapValues(projectTypeMap, (val, projectTypeId) => {
      const obj = response.find(
        ({ project_type }) => project_type === projectTypeId
      )
      const pointsBought = obj?.buy_points ?? 0
      const pointsUsed = obj?.used_points ?? 0
      return new Decimal(pointsBought).sub(pointsUsed).toNumber()
    })

    // 通用點數不會有使用點數紀錄，需將其他專案類型的使用紀錄加總
    result[generalPointId] = _.reduce(
      result,
      (a, points, id) => new Decimal(a).add(points).toNumber(),
      0
    )
    return result
  } catch (error) {
    const result = _.mapValues(projectTypeMap, () => 0)
    console.warn('取得剩餘點數失敗', error)
    return result
  }
}
export async function fetchTrialPoints(group_id) {
  const { domain, scapi_key: key } = DOMAIN_MAP[TRIAL_DOMAIN_KEY]
  const url = `${domain}/scapi/v1/ai/group-buy-and-usage?key=${key}&group_id=${group_id}`
  try {
    const response = await ajax(url)
    const generalPointId = _.findKey(projectTypeMap, ({ general }) => general)
    const result = _.mapValues(projectTypeMap, (val, projectTypeId) => {
      const obj = response.find(
        ({ project_type }) => project_type === projectTypeId
      )
      const pointsBought = obj?.buy_points ?? 0
      const pointsUsed = obj?.used_points ?? 0
      return new Decimal(pointsBought).sub(pointsUsed).toNumber()
    })

    // 通用點數不會有使用點數紀錄，需將其他專案類型的使用紀錄加總
    result[generalPointId] = _.reduce(
      result,
      (a, points, id) => new Decimal(a).add(points).toNumber(),
      0
    )

    return result
  } catch (error) {
    const result = _.mapValues(projectTypeMap, () => 0)
    console.warn('取得剩餘點數失敗', error)
    return result
  }
}
/**
 * @typedef {object} Transfer
 * @property {string} project_type - 專案類型
 * @property {string} transfer_point - 轉移點數
 */
/**
 * 轉移點數通用方法
 * @param {string} from - 轉出人，group_id
 * @param {string} [to] - 轉入人，group_id；不填的話只會有轉出紀錄，適用於綁定裝置
 * @param {string} transfer_by - 轉移人
 * @param {Transfer[]} transfer_list - 轉移點數清單（改成通用點數之後應該只會有一筆資料）
 * @returns
 */
export async function transferPoints(from, to, transfer_by, transfer_list) {
  if (!from) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'from',
      expected_type: 'String',
      curr_type: getType(from),
    })
  } else if (
    !transfer_list ||
    !_.isArray(transfer_list) ||
    transfer_list.length === 0
  ) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'transfer_list',
      expected_type: 'Array',
      curr_type: getType(transfer_list),
    })
  } else if (
    transfer_list.some(
      ({ project_type }) =>
        !Object.prototype.hasOwnProperty.call(projectTypeMap, project_type)
    )
  ) {
    throw new ExtendedError(ERROR_CODE_MAP.IncorrectProjectType.id, {
      project_type: _.map(transfer_list, 'project_type').join(', '),
    })
  } else if (
    transfer_list.some(
      ({ transfer_point }) =>
        isNaN(transfer_point) ||
        transfer_point <= 0 ||
        !_.isInteger(transfer_point)
    )
  ) {
    throw new ExtendedError(ERROR_CODE_MAP.TransferPointOutOfRange.id, {
      points: _.map(transfer_list, 'transfer_point').join(', '),
    })
  }
  const domain_key = await databaseCrud.read(`/group/${from}/domain_key`)
  if (!domain_key) {
    throw new ExtendedError(ERROR_CODE_MAP.UndefinedDomain.id)
  }
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  const url = `${domain}/scapi/v1/ai/point-transfer`
  const pur_time = getCurrUtcDateTime().replace(/\//g, '-')
  const requestParam = {
    key,
    transfer_by,
    from,
    transfer_list: transfer_list.map((obj) => ({
      ...obj,
      // 因為傳到平台端的數字會變成浮點數，所以轉成字串好解析數字
      transfer_point: obj.transfer_point.toString(),
    })),
    pur_time,
  }
  if (to) {
    requestParam.to = to
  }
  await ajax(url, {
    body: JSON.stringify(requestParam),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  })
  return pur_time
}
/**
 * 轉移點數至團隊
 * @param {string} group_id - 轉出人，group_id
 * @param {string} team_id - 轉入團隊，team_id
 * @param {string} transfer_by - 轉移人
 * @param {Transfer[]} transfer_list - 轉移點數清單（改成通用點數之後應該只會有一筆資料）
 * @returns
 */
export const transferPointsToTeam = (
  group_id,
  team_id,
  transfer_by,
  transfer_list
) => {
  if (!team_id) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'team_id',
      expected_type: 'String',
      curr_type: getType(team_id),
    })
  }
  return transferPoints(group_id, team_id, transfer_by, transfer_list)
}
/**
 * 核發試用點數（試用平台），預設為 2 點
 * @param {string} group_id
 * @param {decimal} pur_point
 * @returns
 */
export function applyTrialPoints(group_id, pur_point = 2) {
  const pur_time = getCurrUtcDateTime()
  // 試用點數固定套用在試用平台
  const domain_key = TRIAL_DOMAIN_KEY
  const purchaseRecord = {
    group_id,
    pur_point,
    pur_time,
    pur_by: 'TrialPoint',
    is_remit: 'Y',
    project_type: '9',
  }
  return insertOrUpdatePurchaseRecord(domain_key, purchaseRecord)
}
/**
 * 核發獎勵點數
 * @param {string} group_id
 * @param {string} domain_key
 * @param {decimal} pur_point
 * @returns
 */
export function applyBonusPoints(group_id, domain_key, pur_point) {
  const pur_time = getCurrUtcDateTime()
  const purchaseRecord = {
    group_id,
    pur_point,
    pur_time,
    pur_by: 'Bonus',
    is_remit: 'Y',
    project_type: '9',
  }
  return insertOrUpdatePurchaseRecord(domain_key, purchaseRecord)
}
/**
 * 轉移商城點數，包含轉入、轉出
 * @param {string} group_id
 * @param {string} domain_key
 * @param {decimal} pur_point
 * @returns
 */
export async function transferOfficialPoints(group_id, domain_key, pur_point) {
  const pur_time = getCurrUtcDateTime()
  const purchaseRecord = {
    group_id,
    pur_point: pur_point.toString(),
    pur_time,
    pur_by: 'Market',
    is_remit: 'Y',
    project_type: '9',
  }
  await insertOrUpdatePurchaseRecord(domain_key, purchaseRecord)
  return pur_time
}

const marketApi = firebase.functions().httpsCallable('marketApi')

export async function fetchMarketPoints(email) {
  try {
    // const response = await api.get('coupons', undefined)
    const response = await marketApi({
      method: 'get',
      endpoint: 'coupons',
    })
    const coupons = response.data
    let total_points = 0
    coupons.forEach((coupon) => {
      if (coupon.email_restrictions.includes(email)) {
        total_points += Number(coupon.amount)
      }
    })

    return { total_points, coupons }
  } catch (error) {
    console.warn('取得商城點數失敗', error)
    return { total_points: 0, coupons: [] }
  }
}

export async function updateMarketPoints(id, amount) {
  try {
    const time = getFormattedDateTime(null, null, 'YYYY-MM-DDTHH:mm:ss', true)
    await marketApi({
      method: 'put',
      endpoint: `coupons/${id}`,
      params: {
        amount,
        date_modified: time,
        date_modified_gmt: time,
      },
    })
  } catch (error) {
    throw new Error(error.message)
  }
}

export async function createSmartCoupon(amount, email) {
  try {
    await marketApi({
      method: 'post',
      endpoint: `sc/coupons`,
      params: {
        discount_type: 'smart_coupon',
        amount,
        email_restrictions: email,
      },
    })
  } catch (error) {
    throw new Error(error.message)
  }
}
