import databaseCrud from './model/database/crud'
import { getCurrUtcDateTime, getDaysDiff, getFormattedDateTime } from './util'

/**
 * 系統功能開啟關閉
 */

// 取得系統是否開啟密碼強度提示功能
export const getPasswordStrengthStatus = async () => {
  try {
    const securityConfig = await databaseCrud.read(`/features/security`)
    return securityConfig.passwordStrength
  } catch (error) {
    console.error(error)
  }
}

// 取得系統是否開啟變更密碼提示
export const getPasswordDaysLimit = async () => {
  try {
    const securityConfig = await databaseCrud.read(`/features/security`)
    const days = securityConfig.passwordMaxDaysLimit
    return days
  } catch (error) {
    console.error(error)
  }
}

// 取得系統是否開啟 TOTP 功能
export const getUserTotpVerifyStatus = async () => {
  try {
    const securityConfig = await databaseCrud.read(`/features/security`)
    return securityConfig.userTotpVerify
  } catch (error) {
    console.error(error)
  }
}

// TODO: 確認密碼是否過期（先放這裡之後）
export const checkPasswordIsExpired = async (uid) => {
  try {
    const userData = await databaseCrud.read(`/user/${uid}`)
    const passwordModifyTime = userData?.pwd_modify_time || null
    const today = getCurrUtcDateTime()
    const daysDiff = getDaysDiff(
      today,
      getFormattedDateTime(passwordModifyTime)
    )
    if (!passwordModifyTime) {
      return true
    } else {
      return daysDiff > 90 ? true : false
    }
  } catch (error) {
    console.error(error)
  }
}
