/**
 * Wrap dynamic import function to handle chunkLoadError
 * @see {@link https://www.codemzy.com/blog/fix-chunkloaderror-react how to fix chunkLoadError}
 * @param {Function} dynamicImport - The dynamic import function
 * @returns Promise
 */
const sessionKey = 'retry-lazy-refreshed'
const importPage = (dynamicImport) => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(sessionKey) || 'false'
    )
    // try to import the component
    dynamicImport()
      .then((component) => {
        window.sessionStorage.setItem(sessionKey, 'false') // success so reset the refresh
        resolve(component)
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(sessionKey, 'true') // we are now going to refresh
          console.warn('Lazy load failed, refreshing the page', error)
          return window.location.reload() // refresh the page
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
}
export { importPage }
