import React, { useState, useEffect, useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { LogContext } from '../../Main'
import credential from '../../module/controller/Credential/credential'
import { Link, useHistory } from 'react-router-dom'

// i18n
import { FormattedMessage, injectIntl } from 'react-intl'
import { i18n } from '../../module/util'

// icon
import HomeIcon from '@material-ui/icons/Home'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import BusinessIcon from '@material-ui/icons/Business'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { ReactComponent as PlatformIcon } from '../../assets/img/icon/platform.svg'

function SideMenuPhoneVer(props) {
  const { intl } = props
  const [spaceTitle, setSpaceTitle] = useState(props.mySpaceData.team_name)
  const [teamList, setTeamList] = useState([])
  const {
    currentTeamId,
    setCurrentTeamId,
    currentTeamData,
    nowSpace,
    setNowSpace,
  } = useContext(LogContext)
  const [menuDrawerOnOpen, setMenuDrawerOnOpen] = useState(false)
  const history = useHistory()
  const nowPath = history.location.pathname

  const drawerToggle = () => setMenuDrawerOnOpen(!menuDrawerOnOpen)

  function directPlatform(teamId) {
    if (teamId === props.mySpaceData.team_id) {
      credential.toPlatform()
    } else {
      credential.toPlatformAsTeamMember(teamId)
    }
  }

  useEffect(() => {
    setNowSpace('memberPages')
    const data = Object.values(credential.team)
    setTeamList(data)
  }, [])

  useEffect(() => {}, [spaceTitle, currentTeamId, nowSpace, currentTeamData])

  return (
    <div className="side-menu">
      <Dropdown
        className="d-none"
        onClick={() => {
          const data = Object.values(credential.team)
          setTeamList(data)
        }}
      >
        <Dropdown.Toggle className="space-toggle-btn">
          {nowSpace === 'memberPages' ? <HomeIcon /> : null}
          {nowSpace === 'groupPages' ? <BusinessIcon /> : null}
          {nowSpace === 'addNewGroupPages' ? <GroupAddIcon /> : null}
          <span className="ml-2 mr-2 space-toggle-btn__title">
            {spaceTitle}
          </span>
          <Dropdown.Menu renderOnMount={true}>
            <Dropdown.Item
              className="my-space-tab"
              onClick={() => {
                setSpaceTitle(props.mySpaceData.team_name)
                setCurrentTeamId(props.mySpaceData.team_id)
                setNowSpace('memberPages')
                history.push('/management-center/member')
              }}
            >
              <span className="ml-2 mr-2">{props.mySpaceData.team_name}</span>
            </Dropdown.Item>
            <div className="teams-container">
              {teamList && teamList.length > 0
                ? teamList.map((item, i) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          setSpaceTitle(item.team_name)
                          setNowSpace('groupPages')
                          setCurrentTeamId(item.team_id)
                          history.push('/management-center/group-info')
                        }}
                        title={item.team_name}
                        key={i}
                      >
                        {item.team_name}
                      </Dropdown.Item>
                    )
                  })
                : null}
            </div>
            {props.addGroupMenu.length > 0 ? (
              <>
                <Dropdown.Item
                  className="add-new-group-btn"
                  onClick={() => {
                    setSpaceTitle(props.createNewTeamData.team_name)
                    setNowSpace('addNewGroupPages')
                    history.push('/management-center/create-group')
                  }}
                >
                  <AddCircleIcon />
                  <span className="ml-2 mr-2">
                    <FormattedMessage
                      id={'CreateTeam'}
                      defaultMessage="新增團隊"
                    />
                  </span>
                </Dropdown.Item>
              </>
            ) : null}
          </Dropdown.Menu>
        </Dropdown.Toggle>
      </Dropdown>
      <div
        className={
          menuDrawerOnOpen ? 'menu-drawer menu-drawer--active' : 'menu-drawer'
        }
      >
        <div className="drawer-toggle-btn" onClick={drawerToggle}>
          {menuDrawerOnOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
        </div>
        <div className="space-pages">
          {nowSpace && nowSpace === 'memberPages'
            ? props.memberPages
                .filter(({ hide }) => !hide)
                .map(({ label, icon, path }, index) => {
                  return (
                    <Link
                      className={
                        nowPath === path
                          ? 'page-label page-label--active'
                          : 'page-label'
                      }
                      key={index}
                      to={path}
                      title={label}
                    >
                      <div className="page-label__icon">{icon}</div>
                      <span>{label}</span>
                    </Link>
                  )
                })
            : null}
          {nowSpace === 'groupPages'
            ? props.groupMenu.map(({ label, icon, path }, index) => {
                return (
                  <Link
                    className={
                      nowPath === path
                        ? 'page-label page-label--active'
                        : 'page-label'
                    }
                    key={index}
                    to={path}
                    title={label}
                  >
                    <div className="page-label__icon">{icon}</div>
                    <span>{label}</span>
                  </Link>
                )
              })
            : null}
          {nowSpace === 'addNewGroupPages'
            ? props.addGroupMenu.map(({ label, icon, path }, index) => {
                return (
                  <Link
                    className={
                      nowPath === path
                        ? 'page-label page-label--active'
                        : 'page-label'
                    }
                    key={index}
                    to={path}
                    title={label}
                  >
                    <div className="page-label__icon">{icon}</div>
                    <span>{label}</span>
                  </Link>
                )
              })
            : null}
          {nowSpace !== 'addNewGroupPages' ? (
            <>
              <div
                className={
                  credential?.user?.status === 1
                    ? 'use-platform-btn page-label'
                    : 'd-none'
                }
                onClick={() => directPlatform(currentTeamId)}
              >
                <div
                  className="use-platform-btn__icon"
                  title={i18n(intl, 'UseAiPlatform')}
                >
                  <PlatformIcon />
                </div>
                <span>
                  <FormattedMessage
                    id={'UseAiPlatform'}
                    defaultMessage="使用系統"
                  />
                </span>
              </div>
              <div
                className={
                  credential?.user?.trial_platform_status === 1
                    ? 'use-platform-btn page-label'
                    : 'd-none'
                }
                onClick={() => credential.toTrialPlatform()}
              >
                <div
                  className="use-platform-btn__icon"
                  title={i18n(intl, 'UseAiPlatform')}
                >
                  <PlatformIcon />
                </div>
                <span>使用系統 (試用)</span>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default injectIntl(SideMenuPhoneVer)
