import { getCurrUtcDateTime, getTimestamp } from '../../util'
import databaseCrud from './crud'

export async function fetchMessage(message_id) {
  const data = await databaseCrud.read('/customer_messages/' + message_id)
  return data
}

export async function fetchMessages() {
  const data = await databaseCrud.read('/customer_messages')
  return data ? Object.values(data) : []
}

export async function updateMessage(message_id, option) {
  await databaseCrud.update(`/customer_messages/${message_id}`, option)
}

export async function insertMessage(option) {
  const message_id = getTimestamp()
  await databaseCrud.create(`/customer_messages/${message_id}`, {
    ...option,
    message_id,
    status: 0,
    create_time: getCurrUtcDateTime(),
  })
  return message_id
}
