import { times, random } from 'lodash'
import ContentLoader from 'react-content-loader'

const productFeatureArr = new Array(3).fill('')

function Skeleton({ className }) {
  return (
    <ContentLoader
      viewBox="0 0 100% 100%"
      width={'100%'}
      speed={2}
      backgroundColor="#ccc"
      foregroundColor="#eee"
      className={className}
      style={{ display: 'block' }}
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
    </ContentLoader>
  )
}

export function HomePageLoading(props) {
  return (
    <div className="loading-page-container container">
      <Skeleton className={'header'} />
      <div className="home-video">
        <Skeleton className={'title'} />
        <Skeleton className={'sub-title'} />
        <Skeleton className={'home-video__video'} />
      </div>
      <div className="product-feature">
        <Skeleton className={'title'} />
        <Skeleton className={'sub-title'} />
        <div className="product-feature__feature-container">
          {productFeatureArr.map((i) => {
            return (
              <div
                className="product-feature__feature-container__feature col-md-4 col-sm-12"
                key={random(0, 1, true)}
              >
                <Skeleton className="product-feature__feature-container__feature__img" />
                <Skeleton className="product-feature__feature-container__feature__title" />
                <Skeleton className="product-feature__feature-container__feature__content" />
              </div>
            )
          })}
        </div>
      </div>
      <div className="product-application">
        <Skeleton className={'title'} />
        <div className="product-application__product-container">
          {productFeatureArr.map((i) => {
            return (
              <div
                className="product-application__product-container__product col-md-4 col-sm-12"
                key={random(0, 1, true)}
              >
                <Skeleton className="product-application__product-container__product__title" />
                <Skeleton className="product-application__product-container__product__img" />
                <Skeleton className="product-application__product-container__product__name" />
                <Skeleton className="product-application__product-container__product__sub-title" />
                <Skeleton className="product-application__product-container__product__content" />
                <Skeleton className="product-application__product-container__product__button" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export function ManagementCenterLoader(props) {
  return (
    <div className="management-center">
      <div className="w-100 d-flex">
        <ContentLoader
          viewBox="0 0 200 100vh"
          height={'100vh'}
          width={'200'}
          speed={2}
          backgroundColor="#ccc"
          foregroundColor="#eee"
          {...props}
        >
          <rect x="0" y="0" rx="0" ry="0" width="200" height="10vh" />
          <rect x="0" y="200" rx="0" ry="0" width="200" height="10vh" />
          <rect x="198" y="10vh" rx="0" ry="0" width="2" height="200" />
          <rect x="0" y="21%" rx="0" ry="0" width="200" height="80vh" />
        </ContentLoader>
        <ContentLoader
          viewBox="0 0 100% 100vh"
          height={'100%'}
          width={'calc(100% - 200px)'}
          speed={2}
          backgroundColor="#ccc"
          foregroundColor="#eee"
          {...props}
        >
          <rect x="20%" y="30" rx="5" ry="5" width="60%" height="80vh" />
        </ContentLoader>
      </div>
    </div>
  )
}

export function NewsCardCarouselLoader(props) {
  const { isMobile } = props
  return (
    <div className="custom-news-carousel-card">
      {!isMobile ? (
        <ContentLoader width={'100%'} height={'100%'} viewBox="0 0 100% 100%">
          <rect x="0" y="0" rx="5" ry="5" width="50%" height="100%" />
          <rect x="52%" y="20" rx="5" ry="5" width="25%" height="8%" />
          <rect x="52%" y="50" rx="5" ry="5" width="28%" height="10%" />
          <rect x="52%" y="90" rx="5" ry="5" width="48%" height="40%" />
          <rect x="77%" y="220" rx="5" ry="5" width="23%" height="18%" />
        </ContentLoader>
      ) : (
        <ContentLoader width={'100%'} height={'100%'} viewBox="0 0 100% 100%">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="75%" />
          <rect x="0" y="80%" rx="5" ry="5" width="100%" height="20%" />
        </ContentLoader>
      )}
    </div>
  )
}

export function NewspaperLoader(props) {
  const { isMobile } = props
  return isMobile ? (
    <>
      <div className="article-card">
        <ContentLoader width={'100%'} height={'100%'} viewBox="0 0 100% 100%">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="75%" />
          <rect x="0" y="80%" rx="5" ry="5" width="100%" height="20%" />
        </ContentLoader>
      </div>
      <div className="article-card">
        <ContentLoader width={'100%'} height={'100%'} viewBox="0 0 100% 100%">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="75%" />
          <rect x="0" y="80%" rx="5" ry="5" width="100%" height="20%" />
        </ContentLoader>
      </div>
      <div className="article-card">
        <ContentLoader width={'100%'} height={'100%'} viewBox="0 0 100% 100%">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="75%" />
          <rect x="0" y="80%" rx="5" ry="5" width="100%" height="20%" />
        </ContentLoader>
      </div>
    </>
  ) : (
    <>
      <div className="article-card">
        <ContentLoader width={'100%'} height={'100%'} viewBox="0 0 100% 100%">
          <rect x="0" y="0" rx="5" ry="5" width="50%" height="100%" />
          <rect x="52%" y="10" rx="5" ry="5" width="25%" height="8%" />
          <rect x="52%" y="30" rx="5" ry="5" width="28%" height="10%" />
          <rect x="52%" y="55" rx="5" ry="5" width="48%" height="40%" />
          <rect x="77%" y="140" rx="5" ry="5" width="23%" height="18%" />
        </ContentLoader>
      </div>
      <div className="article-card">
        <ContentLoader width={'100%'} height={'100%'} viewBox="0 0 100% 100%">
          <rect x="0" y="0" rx="5" ry="5" width="50%" height="100%" />
          <rect x="52%" y="10" rx="5" ry="5" width="25%" height="8%" />
          <rect x="52%" y="30" rx="5" ry="5" width="28%" height="10%" />
          <rect x="52%" y="55" rx="5" ry="5" width="48%" height="40%" />
          <rect x="77%" y="140" rx="5" ry="5" width="23%" height="18%" />
        </ContentLoader>
      </div>
      <div className="article-card">
        <ContentLoader width={'100%'} height={'100%'} viewBox="0 0 100% 100%">
          <rect x="0" y="0" rx="5" ry="5" width="50%" height="100%" />
          <rect x="52%" y="10" rx="5" ry="5" width="25%" height="8%" />
          <rect x="52%" y="30" rx="5" ry="5" width="28%" height="10%" />
          <rect x="52%" y="55" rx="5" ry="5" width="48%" height="40%" />
          <rect x="77%" y="140" rx="5" ry="5" width="23%" height="18%" />
        </ContentLoader>
      </div>
    </>
  )
}

export function NewsArticleLoader(props) {
  return (
    <div className="news-article">
      <ContentLoader
        speed={2}
        width={'100%'}
        height={'100vh'}
        viewBox="0 0 100% 100vh"
        {...props}
      >
        <rect x="20%" y="0" rx="3" ry="3" width="60%" height="2rem" />
        <rect x="0" y="3rem" rx="3" ry="3" width="100%" height="1" />
        <rect x="0" y="4rem" rx="3" ry="3" width="25%" height="1.5rem" />
        <rect x="0" y="7rem" rx="3" ry="3" width="100%" height="15rem" />
        <rect x="0" y="23rem" rx="3" ry="3" width="70%" height="1rem" />
        <rect x="0" y="25rem" rx="3" ry="3" width="50%" height="1rem" />
        <rect x="0" y="27rem" rx="3" ry="3" width="30%" height="1rem" />
      </ContentLoader>
    </div>
  )
}

export function StorageFolderLoader({ num }) {
  return (
    <>
      {times(num, (i) => (
        <div className="img-block" key={'loader' + i}>
          <ContentLoader width={'100%'} height={'100%'}>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
      ))}
    </>
  )
}
