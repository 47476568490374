import React from 'react'
import { Container } from 'react-bootstrap'
import Menu from '../Menu/Menu'
import ScrollToTopButton from '../ScrollToTop/ScrollToTopButton'

export default function FrontPageLayout(props) {
  const { children, partner, footer } = props

  return (
    <Container fluid className="bg-light p-0 main-content">
      <Menu />
      <ScrollToTopButton />
      {children}
      {partner}
      {footer}
    </Container>
  )
}
