import { useEffect, useState } from 'react'
import credential from '../module/controller/Credential/credential'

export default function useAuth() {
  const [auth, setAuth] = useState(() => Boolean(credential.user))
  useEffect(() => {
    const callback = (user) => {
      setAuth(Boolean(user))
    }
    credential.on('authChange', callback)
    return () => credential.off('authChange', callback, false)
  }, [])
  return auth
}
