import React, { Suspense } from 'react'
import { Redirect } from 'react-router'
import Footer from '../components/Footer/Footer'
import FrontFooter from '../components/Footer/FrontFooter'
import PartnerIntro from '../components/Footer/PartnerIntro'
import FrontPageLayout from '../components/FrontPageLayout/FrontPageLayout'
import {
  HomePageLoading,
  ManagementCenterLoader,
} from '../components/Skeletons/CustomSkeletons'
import ManagementCenter from '../page/ManagementCenter/ManagementCenter'
import { importPage } from './util'

const footerMap = {
  default: <FrontFooter />,
  simplify: <Footer />,
}
const config = [
  {
    path: '/',
    exact: true,
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() => import(/* webpackChunkName: "Home" */ '../page/Home'))
    ),
  },
  {
    path: '/401',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(
          /* webpackChunkName: "UnauthorizedPage" */
          '../page/ErrorPage/UnauthorizedPage'
        )
      )
    ),
  },
  {
    // 2024 雲市集工業館
    path: '/grants/citd-musesai-event-2024',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(
          /* webpackChunkName: "TCloudEvent24" */ '../page/grants/TCloudEvent24'
        )
      )
    ),
  },
  {
    // 2024 SMB
    path: '/grants/smb-musesai-event-2024',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "SMBEvent24" */ '../page/grants/SMBEvent24')
      )
    ),
  },
  {
    // 2024 疫後計畫
    path: '/grants/transform-musesai-event-2024',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(
          /* webpackChunkName: "TransformEvent24" */ '../page/grants/TransformEvent24'
        )
      )
    ),
  },
  {
    path: '/news',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "NewsCenter" */ '../page/news/NewsCenter')
      )
    ),
  },
  {
    path: '/about',
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() => import(/* webpackChunkName: "About" */ '../page/About'))
    ),
  },
  {
    path: '/support',
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "Support" */ '../page/Support')
      )
    ),
  },
  {
    path: '/term',
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "Term" */ '../components/TermModal/Term')
      )
    ),
  },
  {
    path: '/signup',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "SignUp" */ '../page/SignUp')
      )
    ),
  },
  {
    path: '/login',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() => import(/* webpackChunkName: "Login" */ '../page/Login'))
    ),
  },
  {
    path: '/management-center',
    footer: footerMap.simplify,
    loader: <ManagementCenterLoader />,
    component: ({ auth }) =>
      auth ? <ManagementCenter /> : <Redirect to="/401" />,
    routes: [
      {
        path: '/management-center',
        exact: true,
        component: () => <Redirect to="/management-center/member" />,
      },
      {
        path: '/management-center/member',
        exact: true,
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "Member" */ '../page/ManagementCenter/Member'
            )
          )
        ),
      },
      {
        path: '/management-center/host-info',
        exact: true,
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "HostInfo" */ '../page/ManagementCenter/HostInfo'
            )
          )
        ),
      },
      {
        path: '/management-center/device-binding',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "DeviceBinding" */ '../page/ManagementCenter/DeviceBinding'
            )
          )
        ),
      },
      {
        path: '/management-center/member-order',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "MemberOrder" */ '../page/ManagementCenter/MemberOrder'
            )
          )
        ),
      },
      {
        path: '/management-center/plan-exchange-record',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "PlanExchangeRecord" */ '../page/ManagementCenter/PlanExchangeRecord'
            )
          )
        ),
      },
      {
        path: '/management-center/point-record',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "PointsRecord" */ '../page/ManagementCenter/PointsRecord'
            )
          )
        ),
      },
      {
        path: '/management-center/point-transfer-record',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "PointTransferRecord" */ '../page/ManagementCenter/PointTransferRecord'
            )
          )
        ),
      },
      {
        path: '/management-center/buy-points',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "BuyPoints" */ '../page/point/BuyPoints'
            )
          )
        ),
      },
      {
        path: '/management-center/download',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "Download" */ '../page/ManagementCenter/Download'
            )
          )
        ),
      },
      // 底下為舊版路由，隨著網站改版後已將連結拿掉，現已不使用這些頁面
      {
        path: '/management-center/team-list',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "TeamList" */ '../page/ManagementCenter/Team/TeamList'
            )
          )
        ),
      },
      {
        path: '/management-center/group-info',
        component: React.lazy(() =>
          importPage(() =>
            import(
              /* webpackChunkName: "GroupInfo" */ '../page/ManagementCenter/Team/GroupInfo'
            )
          )
        ),
      },
      {
        path: '/management-center/team-members',
        component: ({ currentTeamData }) =>
          currentTeamData ? (
            importPage(() =>
              import(
                /* webpackChunkName: "TeamMembers" */ '../page/ManagementCenter/Team/TeamMembers'
              )
            )
          ) : (
            <Redirect to="/management-center/member" />
          ),
      },
      {
        path: '/management-center/team-points-record',
        component: ({ currentTeamData }) =>
          currentTeamData ? (
            importPage(() =>
              import(
                /* webpackChunkName: "TeamPointsRecord" */ '../page/ManagementCenter/Team/TeamPointsRecord'
              )
            )
          ) : (
            <Redirect to="/management-center/member" />
          ),
      },
      {
        path: '/management-center/point-transfer',
        component: ({ currentTeamData }) =>
          currentTeamData ? (
            importPage(() =>
              import(
                /* webpackChunkName: "PointTransfer" */ '../page/ManagementCenter/Team/PointTransfer'
              )
            )
          ) : (
            <Redirect to="/management-center/member" />
          ),
      },
      {
        path: '/management-center/create-group',
        component: ({ userOwnTeam, userTeamLimit }) =>
          userOwnTeam.length >= userTeamLimit ? (
            <Redirect to="/management-center/member" />
          ) : (
            importPage(() =>
              import(
                /* webpackChunkName: "CreateGroup" */ '../page/ManagementCenter/Team/CreateGroup'
              )
            )
          ),
      },
      // fallback
      {
        path: '/management-center/*',
        component: () => <Redirect to="/management-center/member" />,
      },
    ],
  },
  {
    path: '/plan-intro/solution',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "PlanIntro" */ '../page/PlanIntro')
      )
    ),
  },
  {
    path: '/plan-intro/editor',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "PlanIntro" */ '../page/PlanIntro')
      )
    ),
  },
  {
    path: '/plan-intro/tool',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "PlanIntro" */ '../page/PlanIntro')
      )
    ),
  },
  {
    path: '/plan-intro/*',
    footer: footerMap.default,
    component: () => <Redirect to="/plan-intro/editor" />,
  },
  // 底下為舊版路由，隨著網站改版後已將連結拿掉，現已不使用這些頁面
  {
    path: '/classes-list',
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "ClassesList" */ '../page/ClassesList')
      )
    ),
  },
  {
    path: '/use-case',
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "Example" */ '../page/Example')
      )
    ),
  },
  {
    path: '/video-fixed',
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() =>
        import(
          /* webpackChunkName: "UseCaseContainer" */
          '../page/useCase/UseCaseContainer'
        )
      )
    ),
  },
  {
    path: '/video-dynamic',
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() =>
        import(
          /* webpackChunkName: "UseCaseContainer" */
          '../page/useCase/UseCaseContainer'
        )
      )
    ),
  },
  {
    path: '/data-track',
    footer: footerMap.default,
    partner: <PartnerIntro />,
    component: React.lazy(() =>
      importPage(() =>
        import(
          /* webpackChunkName: "UseCaseContainer" */
          '../page/useCase/UseCaseContainer'
        )
      )
    ),
  },
  // Fallback
  {
    path: '*',
    footer: footerMap.default,
    component: React.lazy(() =>
      importPage(() =>
        import(
          /* webpackChunkName: "ErrorPage" */
          '../page/ErrorPage/ErrorPage'
        )
      )
    ),
  },
]
const routes = config.map(
  ({
    partner,
    component: Component,
    footer,
    loader = <HomePageLoading />,
    ...other
  }) => ({
    ...other,
    component: (props) => (
      <FrontPageLayout partner={partner} footer={footer}>
        <Suspense fallback={loader}>
          <Component {...props} />
        </Suspense>
      </FrontPageLayout>
    ),
  })
)
export default routes
