export const dropdownSiteMap = [
  {
    name: '產品應用',
    itemList: [
      {
        name: 'Solution',
        link: '/plan-intro/solution',
      },
      {
        name: 'Editor',
        link: '/plan-intro/editor',
      },
      {
        name: 'Tool',
        link: '/plan-intro/tool',
      },
    ],
  },
]

export const grantsdropdownSiteMap = [
  {
    name: '補助方案',
    itemList: [
      {
        name: '2024 雲市集工業館補助方案',
        link: '/grants/citd-musesai-event-2024',
      },
      {
        name: '2024 智慧機上盒輔導計畫',
        link: '/grants/smb-musesai-event-2024',
      },
      {
        name: '2024 10人以上中小型製造業低碳及智慧化升級轉型個案補助方案',
        link: '/grants/transform-musesai-event-2024',
      },
    ],
  },
]
