import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function LoadingButton(props) {
  const {
    style = {},
    pending: loading,
    disabled,
    children,
    ...otherProps
  } = props
  return (
    <Button
      style={{
        opacity: loading ? 0.65 : 1,
        ...style,
      }}
      disabled={loading || disabled}
      {...otherProps}
    >
      {
        <>
          <CircularProgress
            size={16}
            variant="indeterminate"
            style={{
              visibility: !loading ? 'hidden' : 'visible',
              position: 'absolute',
              color: '#ccc',
            }}
          />
          <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
            {children}
          </div>
        </>
      }
    </Button>
  )
}
