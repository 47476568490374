const firebaseConfig = {
  apiKey: 'AIzaSyCe_VvTB1mfKNQWFFjX5pKeEbvV1bSxbd0',
  authDomain: 'hermesai-landing.firebaseapp.com',
  databaseURL: 'https://hermesai-landing-default-rtdb.firebaseio.com',
  projectId: 'hermesai-landing',
  storageBucket: 'hermesai-landing.appspot.com',
  messagingSenderId: '689786233511',
  appId: '1:689786233511:web:54ded0a214553952457182',
  measurementId: 'G-Z2KYJEHC33',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export const useEmulator =
  process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true'

if (useEmulator) {
  const domain = process.env.REACT_APP_FIREBASE_EMULATOR_HOST
  firebase.functions().useFunctionsEmulator(`http://${domain}:5001`)
  firebase.database().useEmulator(domain, 9003)
  firebase.auth().useEmulator(`http://${domain}:9099`)
  firebase.storage().useEmulator(domain, 9199)
}
