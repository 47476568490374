import { getCurrUtcDateTime } from '../../util'
import databaseCrud from './crud'

const baseRef = 'trial_point_application'

export function createTrialPointApplication(reason, create_by) {
  const id = new Date().getTime().toString()
  const create_time = getCurrUtcDateTime()
  const refStr = `${baseRef}/${id}`
  const data = {
    reason,
    id,
    status: 0,
    create_by,
    create_time,
    modify_by: create_by,
    modify_time: create_time,
  }
  return databaseCrud.create(refStr, data)
}

export function updateTrialPointApplication(id, status, modify_by) {
  const refStr = `${baseRef}/${id}`
  const modify_time = getCurrUtcDateTime()
  const data = {
    status,
    modify_by,
    modify_time,
  }
  return databaseCrud.update(refStr, data)
}
export async function getTrialPointApplicationById(id) {
  const refStr = `${baseRef}/${id}`
  const data = await databaseCrud.read(refStr)

  return data
}
export async function getTrialPointApplication(email) {
  const refStr = `${baseRef}`
  const data = email
    ? await databaseCrud.filterByChildProp(refStr, 'email', email)
    : await databaseCrud.read(refStr)

  return data ? Object.values(data) : []
}
