import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { ArrowRight } from '@material-ui/icons'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Partner01 from '../../assets/img/partner-customer/partner/default/01_GCP.png'
import Partner02 from '../../assets/img/partner-customer/partner/default/02_Azure.png'
import Partner03 from '../../assets/img/partner-customer/partner/default/03_iKala.png'
import Partner04 from '../../assets/img/partner-customer/partner/default/04_WiAdvance.png'
import Partner05 from '../../assets/img/partner-customer/partner/default/05_eCloudvalley.png'
import Partner06 from '../../assets/img/partner-customer/partner/default/06_Advantech.png'
import Partner07 from '../../assets/img/partner-customer/partner/default/07_Foxconn.png'
import Partner08 from '../../assets/img/partner-customer/partner/default/08_MIC.png'
import Partner09 from '../../assets/img/partner-customer/partner/default/09_HITACHI.png'
import Partner10 from '../../assets/img/partner-customer/partner/default/10_Intel.png'
import Partner11 from '../../assets/img/partner-customer/partner/fold/11_MACNICA.png'
import Partner12 from '../../assets/img/partner-customer/partner/fold/12_linnononn.png'
import Partner13 from '../../assets/img/partner-customer/partner/fold/13_AXIOMTEX.png'
import Partner14 from '../../assets/img/partner-customer/partner/fold/14_HighPower.png'
import Partner15 from '../../assets/img/partner-customer/partner/fold/15_Vecow.png'
import Partner16 from '../../assets/img/partner-customer/partner/fold/16_ECSI.png'
import Partner17 from '../../assets/img/partner-customer/partner/fold/17_3Egreen.png'
import Partner18 from '../../assets/img/partner-customer/partner/fold/18_Infinities.png'
import Partner19 from '../../assets/img/partner-customer/partner/fold/19_SunTige.png'
import Partner20 from '../../assets/img/partner-customer/partner/fold/20_dt42.png'
import Partner21 from '../../assets/img/partner-customer/partner/fold/21_FHNet.png'
import Partner22 from '../../assets/img/partner-customer/partner/fold/22_BlueSign.png'
import Partner23 from '../../assets/img/partner-customer/partner/fold/23_ITRI.png'
import Partner24 from '../../assets/img/partner-customer/partner/fold/24_III.png'
import Partner25 from '../../assets/img/partner-customer/partner/fold/25_ChengShiu.png'
import Partner26 from '../../assets/img/partner-customer/partner/fold/26_CISA.png'
import Partner27 from '../../assets/img/partner-customer/partner/fold/27_CPC.png'
import Partner28 from '../../assets/img/partner-customer/partner/fold/28_MIRDC.png'
import Chemical01 from '../../assets/img/partner-customer/customer/chemical/default/01_FPC.png'
import Chemical02 from '../../assets/img/partner-customer/customer/chemical/default/02_FPCC.png'
import Chemical03 from '../../assets/img/partner-customer/customer/chemical/default/03_NPC.png'
import Chemical04 from '../../assets/img/partner-customer/customer/chemical/default/04_FCFC.png'
import Chemical05 from '../../assets/img/partner-customer/customer/chemical/fold/05_PackProper.png'
import Chemical06 from '../../assets/img/partner-customer/customer/chemical/fold/06_ChuHungOilSeals.png'
import Information01 from '../../assets/img/partner-customer/customer/information/default/01_KYEC.png'
import Information02 from '../../assets/img/partner-customer/customer/information/default/02_SUMIKA.png'
import Information03 from '../../assets/img/partner-customer/customer/information/default/03_COMOSS.png'
import Information04 from '../../assets/img/partner-customer/customer/information/default/04_HIGHLIGHT.png'
import Information05 from '../../assets/img/partner-customer/customer/information/default/05_PFG.png'
import Information06 from '../../assets/img/partner-customer/customer/information/fold/06_ANTS.png'
import Information07 from '../../assets/img/partner-customer/customer/information/fold/07_HuaTai.png'
import Information08 from '../../assets/img/partner-customer/customer/information/fold/08_HJT.png'
import Livelihood01 from '../../assets/img/partner-customer/customer/livelihood/default/01_LongChen.png'
import Livelihood02 from '../../assets/img/partner-customer/customer/livelihood/default/02_HsnTungYang.png'
import Livelihood03 from '../../assets/img/partner-customer/customer/livelihood/default/03_ANKO.png'
import Livelihood04 from '../../assets/img/partner-customer/customer/livelihood/default/04_Jumpway.png'
import Livelihood05 from '../../assets/img/partner-customer/customer/livelihood/fold/05_HeYe.png'
import Livelihood06 from '../../assets/img/partner-customer/customer/livelihood/fold/06_MASSTEC.png'
import Livelihood07 from '../../assets/img/partner-customer/customer/livelihood/fold/07_Fenghsi.png'
import Livelihood08 from '../../assets/img/partner-customer/customer/livelihood/fold/08_TaiwanFisher.png'
import Livelihood09 from '../../assets/img/partner-customer/customer/livelihood/fold/09_HueiYoung.png'
import Livelihood10 from '../../assets/img/partner-customer/customer/livelihood/fold/10_SangSong.png'
import Metal01 from '../../assets/img/partner-customer/customer/metal/default/01_FFg.png'
import Metal02 from '../../assets/img/partner-customer/customer/metal/default/02_Fii.png'
import Metal03 from '../../assets/img/partner-customer/customer/metal/default/03_ShaYang.png'
import Metal04 from '../../assets/img/partner-customer/customer/metal/default/04_TEO.png'
import Metal05 from '../../assets/img/partner-customer/customer/metal/default/05_AeroWin.png'
import Metal06 from '../../assets/img/partner-customer/customer/metal/fold/06_COSMOS.png'
import Metal07 from '../../assets/img/partner-customer/customer/metal/fold/07_Foxconn.png'
import Metal08 from '../../assets/img/partner-customer/customer/metal/fold/08_TATA Motors.png'
import Metal09 from '../../assets/img/partner-customer/customer/metal/fold/09_SAI.png'
import Metal10 from '../../assets/img/partner-customer/customer/metal/fold/10_AIDC.png'
import Metal11 from '../../assets/img/partner-customer/customer/metal/fold/11_HOCOM.png'
import Metal12 from '../../assets/img/partner-customer/customer/metal/fold/12_USUN.png'
import Metal13 from '../../assets/img/partner-customer/customer/metal/fold/13_FeedBack.png'
import Metal14 from '../../assets/img/partner-customer/customer/metal/fold/14_HuangLiang.png'
import Metal15 from '../../assets/img/partner-customer/customer/metal/fold/15_JYR.png'
import Metal16 from '../../assets/img/partner-customer/customer/metal/fold/16_SEYI.png'
import Metal17 from '../../assets/img/partner-customer/customer/metal/fold/17_JUSTIME.png'
import Metal18 from '../../assets/img/partner-customer/customer/metal/fold/18_JS.png'
import Metal19 from '../../assets/img/partner-customer/customer/metal/fold/19_HoSong.png'
import Metal20 from '../../assets/img/partner-customer/customer/metal/fold/20_LANCER.png'
import Metal21 from '../../assets/img/partner-customer/customer/metal/fold/21_TAKISAWA.png'
import Metal22 from '../../assets/img/partner-customer/customer/metal/fold/22_ChangYong.png'
import Metal23 from '../../assets/img/partner-customer/customer/metal/fold/23_SAUTER.png'
import Metal24 from '../../assets/img/partner-customer/customer/metal/fold/24_FORTOP.png'
import Metal25 from '../../assets/img/partner-customer/customer/metal/fold/25_Cocen.png'
import Metal26 from '../../assets/img/partner-customer/customer/metal/fold/26_MAXCLAW.png'
import Metal27 from '../../assets/img/partner-customer/customer/metal/fold/27_Mountop.png'
import Metal28 from '../../assets/img/partner-customer/customer/metal/fold/28_WL.png'
import Metal29 from '../../assets/img/partner-customer/customer/metal/fold/29_ChenFull.png'
import Metal30 from '../../assets/img/partner-customer/customer/metal/fold/30_ABEL.png'
import Metal31 from '../../assets/img/partner-customer/customer/metal/fold/31_ShanFong.png'
import Metal32 from '../../assets/img/partner-customer/customer/metal/fold/32_KuoChuan.png'
import Metal33 from '../../assets/img/partner-customer/customer/metal/fold/33_VP.png'
import Metal34 from '../../assets/img/partner-customer/customer/metal/fold/34_Sanfit.png'
import Metal35 from '../../assets/img/partner-customer/customer/metal/fold/35_ACHB.png'
import Metal36 from '../../assets/img/partner-customer/customer/metal/fold/36_HOBA.png'
import Metal37 from '../../assets/img/partner-customer/customer/metal/fold/37_FSA.png'
import Metal38 from '../../assets/img/partner-customer/customer/metal/fold/38_LY.png'

const defaultPartnerLogo = [
  Partner01,
  Partner02,
  Partner03,
  Partner04,
  Partner05,
  Partner06,
  Partner07,
  Partner08,
  Partner09,
  Partner10,
]
const foldPartnerLogo = [
  Partner11,
  Partner12,
  Partner13,
  Partner14,
  Partner15,
  Partner16,
  Partner17,
  Partner18,
  Partner19,
  Partner20,
  Partner21,
  Partner22,
  Partner23,
  Partner24,
  Partner25,
  Partner26,
  Partner27,
  Partner28,
]

const customerTypes = [
  {
    id: 0,
    text: '化學工業',
    defaultLogo: [Chemical01, Chemical02, Chemical03, Chemical04],
    foldLogo: [Chemical05, Chemical06],
  },
  {
    id: 1,
    text: '資訊電子工業',
    defaultLogo: [
      Information01,
      Information02,
      Information03,
      Information04,
      Information05,
    ],
    foldLogo: [Information06, Information07, Information08],
  },
  {
    id: 2,
    text: '民生工業',
    defaultLogo: [Livelihood01, Livelihood02, Livelihood03, Livelihood04],
    foldLogo: [
      Livelihood05,
      Livelihood06,
      Livelihood07,
      Livelihood08,
      Livelihood09,
      Livelihood10,
    ],
  },
  {
    id: 3,
    text: '金屬機械加工業',
    defaultLogo: [Metal01, Metal02, Metal03, Metal04, Metal05],
    foldLogo: [
      Metal06,
      Metal07,
      Metal08,
      Metal09,
      Metal10,
      Metal11,
      Metal12,
      Metal13,
      Metal14,
      Metal15,
      Metal16,
      Metal17,
      Metal18,
      Metal19,
      Metal20,
      Metal21,
      Metal22,
      Metal23,
      Metal24,
      Metal25,
      Metal26,
      Metal27,
      Metal28,
      Metal29,
      Metal30,
      Metal31,
      Metal32,
      Metal33,
      Metal34,
      Metal35,
      Metal36,
      Metal37,
      Metal38,
    ],
  },
]

function PartnerIntro() {
  const [partnerOpen, setPartnerOpen] = useState(false)
  const [customerOpen, setCustomerOpen] = useState(false)
  const [currentCustomerType, setCurrentCustomerType] = useState(0)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const [moveDistance, setMoveDistance] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    checkIsMobile()
    window.addEventListener('resize', checkIsMobile)
    return () => {
      window.removeEventListener('resize', checkIsMobile)
    }
  }, [])

  function checkIsMobile() {
    if (window.innerWidth < 768) setIsMobile(true)
    else setIsMobile(false)
  }

  const onTouchStart = (e) => {
    setTouchEnd(null) / setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX)
    const touchEnds = e.targetTouches[0].clientX
    const distance = touchStart - touchEnds
    if (Math.abs(distance) > 20)
      document.querySelector('html').style['overflow-y'] = 'hidden'
    setMoveDistance((distance / window.innerWidth) * 100)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd

    if (Math.abs(distance) > 100) {
      setCurrentCustomerType((prev) =>
        distance > 0
          ? Math.min(prev + 1, customerTypes.length - 1)
          : Math.max(prev - 1, 0)
      )
    }
    setMoveDistance(0)
    document.querySelector('html').style['overflow-y'] = 'auto'
  }

  function onCustomerTypeChange(id) {
    setCurrentCustomerType(id)
  }

  return (
    <div className="partner-intro">
      <div className="partner-intro__title">{'全球企業首選\nAI應用科技'}</div>
      <div className="partner-intro__partner-container">
        <div className="partner-intro__partner-container__title">
          <span
            onClick={() => {
              setPartnerOpen((prev) => !prev)
            }}
          >
            <ArrowRight
              style={{
                transform: partnerOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: partnerOpen
                  ? 'all 0.3s ease-out'
                  : 'all 0.5s ease-in 0.4s',
              }}
            />
            合作夥伴
          </span>
        </div>
        <div className="partner-intro__partner-container__partners col-12">
          {defaultPartnerLogo.map((logo, idx) => {
            return (
              <div
                className="partner-intro__partner-container__partners__partner"
                key={idx}
              >
                <img src={logo} alt={`Partner${idx}`}></img>
              </div>
            )
          })}
        </div>
        <div
          className="partner-intro__partner-container__partners col-12"
          style={{ maxHeight: partnerOpen ? '300vh' : 0 }}
        >
          {foldPartnerLogo.map((logo, idx) => {
            return (
              <div
                className="partner-intro__partner-container__partners__partner"
                key={idx}
              >
                <img src={logo} alt={`Partner${idx}`}></img>
              </div>
            )
          })}
        </div>
      </div>
      <div className="partner-intro__customer-container">
        <div className="partner-intro__customer-container__title">
          <span
            onClick={() => {
              setCustomerOpen((prev) => !prev)
            }}
          >
            <ArrowRight
              style={{
                transform: customerOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: customerOpen
                  ? 'all 0.3s ease-out'
                  : 'all 0.5s ease-in 0.4s',
              }}
            />
            我們的客戶
          </span>
        </div>
        {!isMobile && (
          <nav className="customer-type">
            {customerTypes.map((customer) => {
              return (
                <div
                  key={`-${customer.id}--customerType`}
                  className={
                    currentCustomerType === customer.id
                      ? 'nav-link nav-link--active'
                      : 'nav-link'
                  }
                  onClick={() => {
                    onCustomerTypeChange(customer.id)
                  }}
                >
                  {`${customer.text}`}
                </div>
              )
            })}
          </nav>
        )}
        <div
          className="slider"
          style={{
            transform: `translateX(${
              0 - currentCustomerType * 100 - moveDistance
            }vw)`,
            transition:
              moveDistance !== 0
                ? 'none'
                : 'all 0.5s cubic-bezier(0, 0, 0.7, 0.7) 0s',
          }}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          {customerTypes.map(({ defaultLogo, foldLogo, text }, idx) => {
            return (
              <div className="col-12">
                {isMobile && <div className="mobile-customer-text">{text}</div>}
                <div
                  className="partner-intro__customer-container__customers"
                  style={{
                    maxHeight:
                      currentCustomerType +
                        (moveDistance > 0 ? 1 : moveDistance < 0 ? -1 : 0) ===
                        idx || currentCustomerType === idx
                        ? '100vh'
                        : 0,
                  }}
                >
                  {defaultLogo.map((logo, idx) => {
                    return (
                      <div
                        className="partner-intro__customer-container__customers__customer"
                        key={idx}
                      >
                        <img src={logo} alt={`Customer${idx}`}></img>
                      </div>
                    )
                  })}
                  {defaultLogo.length % 2 !== 0 && isMobile && (
                    <div
                      className="partner-intro__customer-container__customers__customer"
                      key={idx}
                    >
                      <img
                        src={foldLogo[0]}
                        alt={`Customer${idx}`}
                        style={{
                          maxHeight: customerOpen ? '100vh' : 0,
                          transition: customerOpen
                            ? 'all 0.8s ease-out'
                            : 'all 0.8s ease-out 0.3s',
                        }}
                      ></img>
                    </div>
                  )}
                </div>
                <div
                  className="partner-intro__customer-container__customers"
                  style={{
                    maxHeight: customerOpen
                      ? currentCustomerType +
                          (moveDistance > 0 ? 1 : moveDistance < 0 ? -1 : 0) ===
                          idx || currentCustomerType === idx
                        ? '300vh'
                        : 0
                      : 0,
                  }}
                >
                  {(defaultLogo.length % 2 !== 0 && isMobile
                    ? foldLogo.slice(1, foldLogo.length)
                    : foldLogo
                  ).map((logo, idx) => {
                    return (
                      <div
                        className="partner-intro__customer-container__customers__customer"
                        key={idx}
                      >
                        <img src={logo} alt={`Customer${idx}`}></img>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
        {isMobile && (
          <>
            <ArrowForwardIosIcon
              style={{
                position: 'absolute',
                fontSize: '2rem',
                right: '20%',
                top: 65,
                cursor:
                  currentCustomerType === customerTypes.length - 1
                    ? 'not-allowed'
                    : 'pointer',
                color: '#5a36a1',
                opacity:
                  currentCustomerType === customerTypes.length - 1 ? '0.3' : 1,
              }}
              onClick={() => {
                setCurrentCustomerType((prev) =>
                  Math.min(prev + 1, customerTypes.length - 1)
                )
              }}
            />
            <ArrowBackIosIcon
              style={{
                position: 'absolute',
                fontSize: '2rem',
                top: 65,
                left: '20%',
                cursor: currentCustomerType === 0 ? 'not-allowed' : 'pointer',
                color: '#5a36a1',
                opacity: currentCustomerType === 0 ? '0.3' : 1,
              }}
              onClick={() => {
                setCurrentCustomerType((prev) => Math.max(prev - 1, 0))
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}
export default withRouter(PartnerIntro)
