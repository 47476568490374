import { has, isFunction } from 'lodash'
import { ERROR_CODE_MAP, ExtendedError } from './ErrorCode'
import { getType } from './util'

export default class Emitter {
  constructor(events = []) {
    this.events = events
    this.eventHandlers = Object.fromEntries(
      events.map((evt) => [evt, { always: [], once: [] }])
    )
  }
  emit(event, args) {
    if (!this.events.includes(event)) {
      console.warn('wrong event name')
      return
    }
    this.eventHandlers[event].always.forEach((fn) => fn(...args))
    this.eventHandlers[event].once.forEach((fn) => fn(...args))
    this.eventHandlers[event].once.forEach((fn) => this.off(event, fn, true))
  }
  once(type, callback) {
    if (!has(this.eventHandlers, type)) {
      throw new ExtendedError(ERROR_CODE_MAP.IncorrectValue.id, {
        variable: 'type',
        value: type,
      })
    } else if (!isFunction(callback)) {
      throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
        variable: 'callback',
        expected_type: 'Function',
        curr_type: getType(callback),
      })
    }
    this.eventHandlers[type].once.push(callback)
  }
  on(type, callback) {
    if (!has(this.eventHandlers, type)) {
      throw new ExtendedError(ERROR_CODE_MAP.IncorrectValue.id, {
        variable: 'type',
        value: type,
      })
    } else if (!isFunction(callback)) {
      throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
        variable: 'callback',
        expected_type: 'Function',
        curr_type: getType(callback),
      })
    }
    this.eventHandlers[type].always.push(callback)
  }
  off(type, callback, isOnce) {
    if (!has(this.eventHandlers, type)) {
      throw new ExtendedError(ERROR_CODE_MAP.IncorrectValue.id, {
        variable: 'type',
        value: type,
      })
    }

    const onceOrAlways = isOnce ? 'once' : 'always'
    if (
      isFunction(callback) &&
      this.eventHandlers[type][onceOrAlways].includes(callback)
    ) {
      const index = this.eventHandlers[type][onceOrAlways].findIndex(
        (fn) => fn === callback
      )
      this.eventHandlers[type][onceOrAlways].splice(index, 1)
    } else {
      this.eventHandlers[type][onceOrAlways].length = 0
    }
  }
}
