import dayjs from 'dayjs'
import _ from 'lodash'
import { customAlphabet } from 'nanoid'
import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import { getCurrUtcDateTime, getType } from '../../util'
import databaseCrud from './crud'
import { fetchUser } from './userAndGroup'

const alphabet = '0123456789'
const customNanoid = customAlphabet(alphabet, 8)

export async function createCourseId() {
  let course_id = customNanoid()
  let isExist = await checkIfCourseExist(course_id)
  while (isExist) {
    course_id = customNanoid()
    isExist = await checkIfCourseExist(course_id)
  }
  return course_id
}

export async function checkIfCourseExist(course_id) {
  if (!_.isString(course_id)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'course_id',
      expected_type: 'String',
      curr_type: getType(course_id),
    })
  }
  const response = await databaseCrud.read(`/course/${course_id}`)
  return response !== null
}

export async function checkIfCourseTimeExist(course_id, hash) {
  if (!_.isString(hash)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'hash',
      expected_type: 'String',
      curr_type: getType(hash),
    })
  }
  const response = await databaseCrud.read(
    `/course/${course_id}/course_time/${hash}`
  )
  return response !== null
}

export async function fetchCourseList() {
  const response = await databaseCrud.read('/course')
  const data = response ? Object.values(response) : []
  return data
}

export async function fetchCourse(course_id) {
  if (!_.isString(course_id)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'course_id',
      expected_type: 'String',
      curr_type: getType(course_id),
    })
  }
  const response = await databaseCrud.read(`/course/${course_id}`)
  return response
}

export async function createCourse(course_name, create_by) {
  if (!_.isString(course_name)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'course_name',
      expected_type: 'String',
      curr_type: getType(course_name),
    })
  } else if (!_.isString(create_by)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'create_by',
      expected_type: 'String',
      curr_type: getType(create_by),
    })
  }
  const course_id = await createCourseId()
  const status = 0
  const time = getCurrUtcDateTime()
  const data = {
    course_id,
    course_name,
    status,
    create_time: time,
    create_by,
    modify_time: time,
    modify_by: create_by,
  }
  await databaseCrud.create(`/course/${course_id}`, data)
  return data
}

export async function updateCourse(course_id, course_name, modify_by) {
  if (!_.isString(course_id)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'course_id',
      expected_type: 'String',
      curr_type: getType(course_id),
    })
  } else if (!_.isString(course_name)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'course_name',
      expected_type: 'String',
      curr_type: getType(course_name),
    })
  } else if (!_.isString(modify_by)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'modify_by',
      expected_type: 'String',
      curr_type: getType(modify_by),
    })
  }
  const isCourseExist = await checkIfCourseExist(course_id)
  if (!isCourseExist) {
    throw new ExtendedError(ERROR_CODE_MAP.CourseNotFound.id, {
      course_id,
    })
  }
  const time = getCurrUtcDateTime()
  const data = {
    course_name,
    modify_time: time,
    modify_by,
  }
  await databaseCrud.update(`/course/${course_id}`, data)
  return data
}

export async function addCourseTime(option = {}) {
  if (!_.isObject(option)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option',
      expected_type: 'Object',
      curr_type: getType(option),
    })
  }

  const {
    course_id,
    course_time,
    create_by,
    due_time,
    end_time,
    enrollee_limit,
  } = option

  if (!_.isString(course_id)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.course_id',
      expected_type: 'String',
      curr_type: getType(course_id),
    })
  } else if (!_.isString(course_time)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.course_time',
      expected_type: 'String',
      curr_type: getType(course_time),
    })
  } else if (!_.isNil(enrollee_limit) && !_.isNumber(enrollee_limit)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.enrollee_limit',
      expected_type: 'Number',
      curr_type: getType(enrollee_limit),
    })
  } else if (!_.isString(end_time)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.end_time',
      expected_type: 'String',
      curr_type: getType(end_time),
    })
  } else if (!_.isString(create_by)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.create_by',
      expected_type: 'String',
      curr_type: getType(create_by),
    })
  } else if (!_.isString(due_time)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.due_time',
      expected_type: 'String',
      curr_type: getType(due_time),
    })
  }

  const currTime = getCurrUtcDateTime()
  const data = {
    start_time: course_time,
    due_time,
    status: 0,
    end_time,
    enrollee_limit,
    create_time: currTime,
    create_by,
    modify_time: currTime,
    modify_by: create_by,
  }
  await databaseCrud.push(`/course/${course_id}/course_time`, data)
  return data
}

export async function updateCourseTime(course_id, hash, option = {}) {
  if (!_.isString(course_id)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'course_id',
      expected_type: 'String',
      curr_type: getType(course_id),
    })
  } else if (!_.isObject(option)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option',
      expected_type: 'Object',
      curr_type: getType(option),
    })
  } else if (!_.isString(hash)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'hash',
      expected_type: 'String',
      curr_type: getType(hash),
    })
  }

  const { course_time, modify_by, due_time, end_time, enrollee_limit } = option

  if (!_.isString(course_time)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.course_time',
      expected_type: 'String',
      curr_type: getType(course_time),
    })
  } else if (!_.isNil(enrollee_limit) && !_.isNumber(enrollee_limit)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.enrollee_limit',
      expected_type: 'Number',
      curr_type: getType(enrollee_limit),
    })
  } else if (!_.isString(end_time)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.end_time',
      expected_type: 'String',
      curr_type: getType(end_time),
    })
  } else if (!_.isString(modify_by)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.modify_by',
      expected_type: 'String',
      curr_type: getType(modify_by),
    })
  } else if (!_.isString(due_time)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.due_time',
      expected_type: 'String',
      curr_type: getType(due_time),
    })
  }
  const isCourseTimeExist = await checkIfCourseTimeExist(course_id, hash)

  if (!isCourseTimeExist) {
    throw new ExtendedError(ERROR_CODE_MAP.CourseTimeNotFound.id, {
      course_id,
      course_time,
    })
  }
  const currTime = getCurrUtcDateTime()
  const data = {
    course_time,
    modify_by,
    due_time,
    end_time,
    enrollee_limit,
    modify_time: currTime,
  }
  await databaseCrud.update(`/course/${course_id}/course_time/${hash}`, data)
  return data
}

export async function fetchCourseEnrollee(course_id) {
  if (!_.isString(course_id)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'course_id',
      expected_type: 'String',
      curr_type: getType(course_id),
    })
  }
  const response = await databaseCrud.read(
    `/course/${course_id}/course_enrollee`
  )
  const enrollee = response
    ? Object.entries(response).map(([hash, obj]) => ({ ...obj, hash }))
    : []
  return enrollee
}

export async function registerCourse(option) {
  if (!_.isObject(option)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option',
      expected_type: 'Object',
      curr_type: getType(option),
    })
  }

  const { email, course_id, course_time, uid } = option

  if (!_.isString(course_id)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.course_id',
      expected_type: 'String',
      curr_type: getType(course_id),
    })
  } else if (!_.isString(course_time)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.course_time',
      expected_type: 'String',
      curr_type: getType(course_time),
    })
  } else if (!_.isString(uid)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.uid',
      expected_type: 'String',
      curr_type: getType(uid),
    })
  } else if (!_.isString(email)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.email',
      expected_type: 'String',
      curr_type: getType(email),
    })
  }

  const course = await fetchCourse(course_id)
  if (_.isNil(course)) {
    throw new ExtendedError(ERROR_CODE_MAP.CourseNotFound.id, {
      course_id,
      course_time,
    })
  }
  const courseEnrolleeList = Object.values(course.course_enrollee ?? {})
  const isRegistered = !_.isNil(
    courseEnrolleeList.find(
      (obj) => obj.email === email && obj.start_time === course_time
    )
  )

  if (isRegistered) {
    throw new ExtendedError(ERROR_CODE_MAP.CourseRegistered.id)
  }

  const curr_time = getCurrUtcDateTime()
  const { course_time: courseTimeMap } = course

  if (_.isNil(courseTimeMap)) {
    throw new ExtendedError(ERROR_CODE_MAP.CourseTimeNotFound.id, {
      course_time,
      course_id,
    })
  }
  const courseTimeData = Object.values(courseTimeMap).find(
    (obj) => obj.start_time === course_time
  )

  if (_.isNil(courseTimeData)) {
    throw new ExtendedError(ERROR_CODE_MAP.CourseTimeNotFound.id, {
      course_time,
      course_id,
    })
  }
  const { due_time, enrollee_limit } = courseTimeData
  const registeredEnrolleeCount = courseEnrolleeList.length
  const isDue = dayjs(curr_time).isAfter(due_time)

  if (isDue) {
    throw new ExtendedError(ERROR_CODE_MAP.EnrollmentClosed.id)
  } else if (
    _.isNumber(enrollee_limit) &&
    registeredEnrolleeCount >= enrollee_limit
  ) {
    throw new ExtendedError(ERROR_CODE_MAP.ExceedEnrolleeLimit.id, {
      limit: enrollee_limit,
    })
  }

  const course_enrollee = {
    email,
    start_time: course_time,
    status: 0,
    create_time: curr_time,
    create_by: email,
    modify_time: curr_time,
    modify_by: email,
  }
  const course_registration = {
    course_id,
    start_time: course_time,
    status: 0,
    create_time: curr_time,
    create_by: email,
    modify_time: curr_time,
    modify_by: email,
  }
  await databaseCrud.push(
    `/course/${course_id}/course_enrollee`,
    course_enrollee
  )
  await databaseCrud.push(
    `/user/${uid}/course_registration`,
    course_registration
  )
  return course_registration
}

export async function cancelRegistration(option = {}) {
  if (!_.isObject(option)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option',
      expected_type: 'Object',
      curr_type: getType(option),
    })
  }

  const {
    email,
    course_id,
    start_time,
    reason,
    remark = '',
    create_by,
  } = option

  if (!_.isString(email)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.email',
      expected_type: 'String',
      curr_type: getType(email),
    })
  } else if (!_.isString(course_id)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.course_id',
      expected_type: 'String',
      curr_type: getType(course_id),
    })
  } else if (!_.isString(start_time)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.course_time',
      expected_type: 'String',
      curr_type: getType(start_time),
    })
  } else if (!_.isNumber(reason)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.reason',
      expected_type: 'Number',
      curr_type: getType(reason),
    })
  } else if (!_.isNil(remark) && !_.isString(remark)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.remark',
      expected_type: 'String',
      curr_type: getType(remark),
    })
  } else if (!_.isString(create_by)) {
    throw new ExtendedError(ERROR_CODE_MAP.InvalidParameterType.id, {
      variable: 'option.create_by',
      expected_type: 'String',
      curr_type: getType(create_by),
    })
  }
  const userData = await fetchUser(email)
  if (!userData) {
    throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id, {
      email,
    })
  }
  const courseData = await fetchCourse(course_id)
  if (!courseData) {
    throw new ExtendedError(ERROR_CODE_MAP.CourseNotFound.id, {
      course_id,
    })
  }
  const { user_id, course_registration } = userData
  const { course_enrollee } = courseData
  const registrationHash = _.findKey(
    course_registration,
    (data) => data.course_id === course_id && data.start_time === start_time
  )
  const enrolleeHash = _.findKey(
    course_enrollee,
    (data) => data.start_time === start_time && data.email === email
  )
  const registrationRef = `/user/${user_id}/course_registration/${registrationHash}`
  const enrolleeRef = `/course/${course_id}/course_enrollee/${enrolleeHash}`
  const cancelRecord = {
    course_id,
    email,
    start_time,
    reason,
    remark,
    create_time: getCurrUtcDateTime(),
    create_by,
  }
  await databaseCrud.delete(registrationRef)
  await databaseCrud.delete(enrolleeRef)
  await databaseCrud.push('/course_registration_cancellation', cancelRecord)
  return cancelRecord
}
