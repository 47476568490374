import { ajax } from './ajax'
import { DOMAIN_MAP } from '../../constants'
import { getUserId } from '../../util'
import credential from '../../controller/Credential/credential'

export function signUp({
  user_id,
  password,
  user_name,
  group_id,
  domain_key,
  isTeam,
}) {
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  // 通常帳號建立者為當前登入者，有可能是後台管理員，也有可能是使用者自己
  // 但有個例外，從信箱驗證頁啟用帳號可以不用登入，所以這時候就沒有 credential.user / credential.group
  const create_by = getUserId(
    credential.user?.email ?? user_id,
    credential.group?.group_id ?? group_id
  )
  const data = {
    key,
    group_id,
    create_by,
    user_id,
    user_name,
    password,
    is_close: '1', // 1 是啟用，0 是關閉
    isTeam: isTeam ?? false,
    trial_point_type: '9', // 通用點數模式需要多傳這個參數
  }
  return ajax(`${domain}/scapi/v1/ai/user/create`, {
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  })
}
