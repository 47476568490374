import { useState } from 'react'
import { Form } from 'react-bootstrap'
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core'
import LoadingButton from '../LoadingButton/LoadingButton'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { messageTypeList } from '../../module/constants'
import { insertMessage } from '../../module/model/database/customerMessages'
import { sendCustomerMessageConfirmation } from '../../module/controller/Mail/Delivery'
import swal from 'sweetalert'

// i18n
import { FormattedMessage } from 'react-intl'
import { pick } from 'lodash'

const questionTypeList = messageTypeList.filter(({ hide }) => !hide)
const companyTypeList = [
  '汽車及其零件',
  '電子零組件',
  '機械設備',
  '電腦電子光學',
  '基本金屬',
  '化學原材料',
  '其他',
]
const timeSelectList = ['3 個月內', '6 個月內', '評估中']
const attendanceSelectList = ['3 - 5 人', '6 - 10 人', '10 - 15 人']
const classTypeList = ['實體', '線上']
const courseTypeList = ['數據類', '影像類', '兩種皆需要']
const dependsOnType = (type) =>
  yup.string().when('type', {
    is: (value) => value === type,
    then: yup.string().required('此欄位必填'),
    otherwise: yup.string(),
  })
const schema = {
  first_name: yup.string('請輸入名稱').required('此欄位必填'),
  last_name: yup.string('請輸入姓氏').required('此欄位必填'),
  email: yup
    .string('請輸入信箱')
    .email('請輸入有效的電子信箱')
    .required('此欄位必填'),
  description: yup.string('請輸入問題說明').required('此欄位必填'),
  phone: yup.string('請輸入連絡電話').required('此欄位必填'),
  type: yup.string('請輸入問題類型').required('此欄位必填'),
  // 申請 demo 相關欄位
  company_type: dependsOnType('1'),
  time_import: dependsOnType('1'),
  // 課程相關欄位
  attendance: dependsOnType('3'),
  class_type: dependsOnType('3'),
  course_type: dependsOnType('3'),
}

function ContactForm() {
  const [isSubmitting, setSubmitting] = useState(false)

  async function sendContactRequest(values) {
    setSubmitting(true)
    try {
      const formData = pick(values, [
        'first_name',
        'last_name',
        'email',
        'phone',
        'type',
      ])
      const descriptions = []
      switch (formData.type) {
        case '1':
          descriptions.push(`公司產業別: ${values.company_type}`)
          descriptions.push(`預計導入時間: ${values.time_import}`)
          descriptions.push('說明:')
          break
        case '3':
          descriptions.push(`預計上課人數: ${values.attendance}`)
          descriptions.push(`上課方式: ${values.class_type}`)
          descriptions.push(`預計課程內容: ${values.course_type}`)
          descriptions.push('說明:')
          break
        case '2':
        default:
          break
      }
      descriptions.push(values.description)
      formData.description = descriptions.join('\n')

      const messageId = await insertMessage(formData)
      sendCustomerMessageConfirmation({
        ...formData,
        type: messageTypeList.find(
          (obj) => obj.id.toString() === values.type.toString()
        )?.text,
        createTimestamp: messageId,
      })
      swal('感謝您的填寫', '我們會盡快與您聯繫', {
        icon: 'success',
        button: false,
        timer: 2200,
      })
    } catch (error) {
      swal('錯誤', '請聯絡系統管理員', 'error')
    } finally {
      setSubmitting(false)
    }
  }

  // formik
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      description: '',
      phone: '',
      type: questionTypeList[0].id.toString(),
      company_type: companyTypeList[0],
      time_import: timeSelectList[0],
      attendance: attendanceSelectList[0],
      class_type: classTypeList[0],
      course_type: courseTypeList[0],
    },
    validationSchema: yup.object(schema),
    onSubmit: (values) => {
      sendContactRequest(values)
    },
  })

  return (
    <div className="contact-form">
      <Typography variant="h5" className="text-center mb-4">
        <FormattedMessage id={'ContactUs'} defaultMessage="聯絡我們" />
      </Typography>
      <Form onSubmit={formik.handleSubmit}>
        <div className="container p-0 mb-4" id="contact-us">
          <div className="row m-0">
            <div className="col-6 pl-0">
              <TextField
                fullWidth
                name="first_name"
                id="first_name"
                label={<FormattedMessage id={'Name'} defaultMessage="姓名" />}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
              />
              <FormHelperText>
                {formik.touched.first_name && formik.errors.first_name}
              </FormHelperText>
            </div>
            <div className="col-6 p-0">
              <TextField
                fullWidth
                name="last_name"
                id="last_name"
                label={
                  <FormattedMessage id={'LastName'} defaultMessage="姓氏" />
                }
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
              />
              <FormHelperText>
                {formik.touched.last_name && formik.errors.last_name}
              </FormHelperText>
            </div>
            <div className="col-6 pl-0 mt-3">
              <TextField
                fullWidth
                name="email"
                id="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <FormHelperText>
                {formik.touched.email && formik.errors.email}
              </FormHelperText>
            </div>
            <div className="col-6 p-0 mt-3">
              <TextField
                fullWidth
                name="phone"
                id="phone"
                label={
                  <FormattedMessage id={'Phone'} defaultMessage="聯絡電話" />
                }
                type="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
              <FormHelperText>
                {formik.touched.phone && formik.errors.phone}
              </FormHelperText>
            </div>
            <div className="col-12 p-0 mt-3">
              <FormControl fullWidth>
                <InputLabel id="type-select">
                  <FormattedMessage
                    id={'TypeOfQuestion'}
                    defaultMessage="問題類型"
                  />
                </InputLabel>
                <Select
                  id="type"
                  name="type"
                  labelId="type-select"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                >
                  {questionTypeList.map(({ id, text }) => (
                    <MenuItem value={id.toString()} key={id}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.type && formik.errors.type}
                </FormHelperText>
              </FormControl>
            </div>
            {formik.values.type === '1' ? (
              <>
                <div className="col-12 p-0 mt-3">
                  <FormControl fullWidth>
                    <InputLabel id="company-type-select">貴司產業別</InputLabel>
                    <Select
                      id="company_type"
                      name="company_type"
                      labelId="company-type-select"
                      value={formik.values.company_type}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.company_type &&
                        Boolean(formik.errors.company_type)
                      }
                    >
                      {companyTypeList.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.company_type &&
                        formik.errors.company_type}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-12 p-0">
                  <div className="time-button-container d-flex mt-3">
                    <FormControl fullWidth component="fieldset">
                      <FormLabel component="legend" id="time_select">
                        預計導入時間
                      </FormLabel>
                      <RadioGroup
                        aria-label="time_import"
                        name="time_import"
                        row
                        value={formik.values.time_import}
                        onChange={formik.handleChange}
                      >
                        {timeSelectList.map((el, i) => {
                          return (
                            <FormControlLabel
                              value={el}
                              key={`${el}${i}`}
                              control={<Radio />}
                              label={el}
                            />
                          )
                        })}
                      </RadioGroup>
                      <FormHelperText>
                        {formik.touched.time_import &&
                          formik.errors.time_import}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </>
            ) : null}
            {formik.values.type === '3' ? (
              <>
                <div className="col-12 p-0">
                  <div className="time-button-container d-flex mt-3">
                    <FormControl fullWidth component="fieldset">
                      <FormLabel component="legend" id="time_select">
                        預計上課人數(最多15人)
                      </FormLabel>
                      <RadioGroup
                        aria-label="attendance"
                        name="attendance"
                        row
                        value={formik.values.attendance}
                        onChange={formik.handleChange}
                      >
                        {attendanceSelectList.map((el, i) => {
                          return (
                            <FormControlLabel
                              value={el}
                              key={`${el}${i}`}
                              control={<Radio />}
                              label={el}
                            />
                          )
                        })}
                      </RadioGroup>
                      <FormHelperText>
                        {formik.touched.attendance && formik.errors.attendance}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className="col-12 p-0">
                  <div className="time-button-container d-flex">
                    <FormControl fullWidth component="fieldset">
                      <FormLabel component="legend" id="time_select">
                        上課方式
                      </FormLabel>
                      <RadioGroup
                        aria-label="class_type"
                        name="class_type"
                        row
                        value={formik.values.class_type}
                        onChange={formik.handleChange}
                      >
                        {classTypeList.map((el, i) => {
                          return (
                            <FormControlLabel
                              value={el}
                              key={`${el}${i}`}
                              control={<Radio />}
                              label={el}
                            />
                          )
                        })}
                      </RadioGroup>
                      <FormHelperText>
                        {formik.touched.class_type && formik.errors.class_type}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className="col-12 p-0">
                  <div className="time-button-container d-flex">
                    <FormControl fullWidth component="fieldset">
                      <FormLabel component="legend" id="time_select">
                        預計課程內容(每堂課3小時為限)
                      </FormLabel>
                      <RadioGroup
                        aria-label="course_type"
                        name="course_type"
                        row
                        value={formik.values.course_type}
                        onChange={formik.handleChange}
                      >
                        {courseTypeList.map((el, i) => {
                          return (
                            <FormControlLabel
                              value={el}
                              key={`${el}${i}`}
                              control={<Radio />}
                              label={el}
                            />
                          )
                        })}
                      </RadioGroup>
                      <FormHelperText>
                        {formik.touched.course_type &&
                          formik.errors.course_type}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </>
            ) : null}

            <div className="col-12 p-0">
              <TextField
                fullWidth
                multiline
                rows={4}
                name="description"
                id="description"
                label={
                  <FormattedMessage id={'Description'} defaultMessage="說明" />
                }
                type="text"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
              />
              <FormHelperText>
                {formik.touched.description && formik.errors.description}
              </FormHelperText>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <LoadingButton
            type="submit"
            pending={isSubmitting}
            variant="outlined"
            className="loading-btn"
          >
            <FormattedMessage id={'Submit'} defaultMessage="確認送出" />
          </LoadingButton>
        </div>
      </Form>
    </div>
  )
}

export default ContactForm
