import React, { useState, useEffect, useContext } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { renderRoutes, matchRoutes } from 'react-router-config'
import { LogContext } from '../../Main'

// i18n
import { injectIntl } from 'react-intl'
import { i18n } from '../../module/util'

// components
import SideMenu from '../../components/SideMenu/SideMenu'

// 手機板選單
import SideMenuPhoneVer from '../../components/SideMenu/SideMenuPhoneVer'

// icon
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import DescriptionIcon from '@material-ui/icons/Description'
import { CloudDownload, Computer, ShoppingBasket } from '@material-ui/icons'
import { ReactComponent as PointRecordIcon } from '../../assets/img/icon/point-record-icon.svg'
import { ReactComponent as PointTransferRecordIcon } from '../../assets/img/icon/PointTransferRecord.svg'
import { ReactComponent as PointIcon } from '../../assets/img/icon/M_Point_icon_bold.svg'
import { ReactComponent as AddCircleIcon } from '@material-ui/icons/AddCircle'
import { ReactComponent as ListIcon } from '../../assets/img/icon/list.svg'
import { ReactComponent as TransferIcon } from '../../assets/img/icon/transfer.svg'
import { ReactComponent as PeopleIcon } from '../../assets/img/icon/customer.svg'
import { ReactComponent as ImportantDevicesIcon } from '../../assets/img/icon/important-devices.svg'

import credential from '../../module/controller/Credential/credential'
import routes from '../../routes'

function ManagementCenter(props) {
  const { intl } = props
  const childRoutes = matchRoutes(routes, props.location.pathname)[0].route
    .routes
  const {
    teamsData,
    nowSpace,
    currentTeamId,
    setCurrentTeamId,
    currentTeamData,
  } = useContext(LogContext)
  const [spaceTitle, setSpaceTitle] = useState('')
  const [groupMenu, setGroupMenu] = useState([])
  const [addGroupMenu, setAddGroupMenu] = useState([])
  const [isMobile, setIsMobile] = useState(false)

  const userTeamLimit = credential.user?.create_team_limit
    ? credential.user?.create_team_limit
    : 1
  const userOwnTeam = teamsData.filter((item) => item.auth === 0)

  const memberPages = [
    {
      label: i18n(intl, 'MemberProfile'),
      icon: <AccountCircleIcon />,
      path: '/management-center/member',
    },
    {
      label: '主機資訊',
      icon: <Computer />,
      path: '/management-center/host-info',
    },
    {
      label: i18n(intl, 'GroupList'),
      icon: <ListIcon />,
      path: '/management-center/team-list',
      hide: true,
    },
    {
      label: '綁定裝置管理',
      icon: <ImportantDevicesIcon />,
      path: '/management-center/device-binding',
      // hide: true,
    },
    {
      label: i18n(intl, 'OrderRecord'),
      icon: <DescriptionIcon />,
      path: '/management-center/member-order',
    },
    {
      label: '方案兌換紀錄',
      icon: <ShoppingBasket />,
      path: '/management-center/plan-exchange-record',
    },
    {
      label: i18n(intl, 'PointsRecord'),
      icon: <PointRecordIcon />,
      path: '/management-center/point-record',
    },
    {
      label: '點數轉移紀錄',
      icon: <PointTransferRecordIcon />,
      path: '/management-center/point-transfer-record',
      hide: false,
    },
    {
      label: i18n(intl, 'BuyPoint'),
      icon: <PointIcon />,
      path: '/management-center/buy-points',
    },
    {
      label: '檔案下載',
      icon: <CloudDownload />,
      path: '/management-center/download',
    },
  ]
  const addNewGroupPages = [
    {
      label: i18n(intl, 'CreateNewGroup'),
      icon: <AddCircleIcon />,
      path: '/management-center/create-group',
    },
  ]

  const mySpaceData = {
    team_name: i18n(intl, 'MySpace'),
    team_id: 'memberPages',
    path: '/management-center/member',
  }
  const createNewTeamData = {
    team_name: i18n(intl, 'CreateTeam'),
    team_id: 'addNewGroupPages',
  }

  const groupPages = [
    {
      label: i18n(intl, 'GroupInfo'),
      icon: <ListIcon />,
      path: '/management-center/group-info',
    },
    {
      label: i18n(intl, 'GroupMembers'),
      icon: <PeopleIcon />,
      path: '/management-center/team-members',
    },
    {
      label: i18n(intl, 'PointsRecord'),
      icon: <PointRecordIcon />,
      path: '/management-center/team-points-record',
    },
    {
      label: i18n(intl, 'PointsTransfer'),
      icon: <TransferIcon />,
      path: '/management-center/point-transfer',
    },
    {
      label: '檔案下載',
      icon: <CloudDownload />,
      path: '/management-center/download',
    },
  ]
  function filterGroupMenu(authCode) {
    const newMenu = groupPages.filter(
      (el) => el.label !== i18n(intl, 'PointsTransfer')
    )
    if (authCode !== 0) setGroupMenu(newMenu)
    else setGroupMenu(groupPages)
  }

  function filterAddNewGroupMenu(createTeamLimit) {
    if (
      userOwnTeam?.length >= createTeamLimit ||
      credential?.group?.status !== 1
    ) {
      const newMenu = addNewGroupPages.filter(
        (el) => el.label !== i18n(intl, 'CreateNewGroup')
      )
      setAddGroupMenu(newMenu)
    } else setAddGroupMenu(addNewGroupPages)
  }

  function checkIsMobile() {
    if (window.innerWidth < 570) setIsMobile(true)
    else setIsMobile(false)
  }

  useEffect(() => {
    filterAddNewGroupMenu(userTeamLimit)
    setCurrentTeamId(mySpaceData.team_id)
    setSpaceTitle(mySpaceData.team_name)
  }, [])

  useEffect(() => {
    checkIsMobile()
    window.addEventListener('resize', checkIsMobile)
    return () => {
      window.removeEventListener('resize', checkIsMobile)
    }
  }, [])

  useEffect(() => {
    filterGroupMenu(currentTeamData?.auth)
  }, [spaceTitle, currentTeamData, currentTeamId, nowSpace])

  return (
    <div className="management-center">
      <div className="side-nav">
        {isMobile ? (
          <SideMenuPhoneVer
            memberPages={memberPages}
            groupMenu={groupMenu}
            addGroupMenu={addGroupMenu}
            createNewTeamData={createNewTeamData}
            mySpaceData={mySpaceData}
          />
        ) : (
          <SideMenu
            memberPages={memberPages}
            groupMenu={groupMenu}
            addGroupMenu={addGroupMenu}
            createNewTeamData={createNewTeamData}
            mySpaceData={mySpaceData}
          />
        )}
      </div>
      <div className="page-container">
        <Switch>
          {renderRoutes(childRoutes, {
            currentTeamData: currentTeamData,
            userOwnTeam: userOwnTeam,
            userTeamLimit: userTeamLimit,
          })}
        </Switch>
      </div>
    </div>
  )
}

export default injectIntl(withRouter(ManagementCenter))
