import { Link, withRouter } from 'react-router-dom'
import {
  Button,
  Nav,
  Navbar,
  Dropdown,
  NavDropdown,
  Container,
} from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import swal from 'sweetalert'
import { AccountCircle, Language } from '@material-ui/icons'
import Logo from '../../assets/img/logo-landscape-bg-transparent-sm.png'
import { dropdownSiteMap, grantsdropdownSiteMap } from './SiteMap'
import InApp from 'detect-inapp'

// firebase
import credential from '../../module/controller/Credential/credential'
import { LogContext } from '../../Main'

// i18n
import { FormattedMessage, injectIntl } from 'react-intl'
import { deepCopy, i18n } from '../../module/util'
import useUser from '../../hook/useUser'
import _ from 'lodash'
import useAuth from '../../hook/useAuth'
import { LocaleContext } from '../..'
import { MARKET_URL } from '../../module/constants'

function Menu(props) {
  const { intl, history, location } = props
  const { setLocale } = useContext(LocaleContext)
  const [pathname, setPathName] = useState(location.pathname)
  const auth = useAuth(Boolean(credential.user))
  const user = useUser(credential.user)
  const [nowLanguage, setNowLanguage] = useState('繁體中文')
  const [teamsData, setTeamsData] = useState([])
  const { courseName, coursePath } = useContext(LogContext)
  const [showDropdownList, setShowDropdownList] = useState([])
  const [isLargeScreen, setIsLargeScreen] = useState(true)
  const [windowResizing, setWindowResizing] = useState(false)
  const [isScrollDown, setIsScrollDown] = useState(false)
  const [isExpired, setIsExpired] = useState(false)

  const inapp = new InApp(
    navigator.userAgent || navigator.vendor || window.opera
  )

  // const languageSwitch = ['繁體中文', '简体中文', 'English', '日本語']
  const languageSwitch = ['繁體中文', 'English']

  const documentTitleArray = [
    {
      path: '/',
      title: 'MusesAI - 一站式 AI 模型開發平台',
    },
    {
      path: '/use-case',
      title: `${i18n(intl, 'UseCase')} - MusesAI`,
    },
    {
      path: '/about',
      title: `${i18n(intl, 'AboutUs')} - MusesAI`,
    },
    {
      path: '/pricing',
      title: `${i18n(intl, 'Pricing')} - MusesAI`,
    },
    {
      path: '/product-intro',
      title: `${i18n(intl, 'ProductDescription')} - MusesAI`,
    },
    {
      path: '/support',
      title: `支援服務 - MusesAI`,
    },
    {
      path: '/support/videos',
      title: `支援服務 | 操作說明 - MusesAI`,
    },
    {
      path: '/support/qa',
      title: `支援服務 | 常見問答 - MusesAI`,
    },
    {
      path: '/classes-list',
      title: `課程報名 - MusesAI`,
    },
    {
      path: '/news',
      title: `最新消息 - MusesAI`,
    },
    {
      path: coursePath,
      title: `${courseName} - MusesAI`,
    },
    {
      path: '/management-center',
      title: `管理中心 - MusesAI`,
    },
    {
      path: '/management-center/member',
      title: `${i18n(intl, 'MemberProfile')} - MusesAI`,
    },
    {
      path: '/management-center/team-list',
      title: `${i18n(intl, 'GroupList')} - MusesAI`,
    },
    {
      path: '/management-center/member-order',
      title: `${i18n(intl, 'OrderRecord')} - MusesAI`,
    },
    {
      path: '/management-center/plan-exchange-record',
      title: `方案兌換紀錄 - MusesAI`,
    },
    {
      path: '/management-center/point-record',
      title: `${i18n(intl, 'PointsRecord')} - MusesAI`,
    },
    {
      path: '/management-center/buy-points',
      title: `${i18n(intl, 'BuyPoint')} - MusesAI`,
    },
    {
      path: '/management-center/group-info',
      title: `${i18n(intl, 'GroupInfo')} - MusesAI`,
    },
    {
      path: '/management-center/team-members',
      title: `${i18n(intl, 'GroupMembers')} - MusesAI`,
    },
    {
      path: '/management-center/team-points-record',
      title: '團隊點數使用紀錄 - MusesAI',
    },
    {
      path: '/management-center/point-transfer-record',
      title: '點數轉移紀錄 - MusesAI',
    },
    {
      path: '/management-center/point-transfer',
      title: `${i18n(intl, 'PointsTransfer')} - MusesAI`,
    },
    {
      path: '/management-center/download',
      title: '檔案下載 - MusesAI',
    },
    {
      path: '/video-fixed',
      title: `影像類(物件) - MusesAI`,
    },
    {
      path: '/video-fixed/vSop',
      title: `vSop 人因邏輯辨識 - MusesAI`,
    },
    {
      path: '/video-fixed/vCycle',
      title: `vCycle 產線標工計算 - MusesAI`,
    },
    {
      path: '/video-fixed/vCount',
      title: `vCount 產量自動記數 - MusesAI`,
    },
    {
      path: '/video-fixed/vAOI',
      title: `vAOI 基礎瑕疵檢測 - MusesAI`,
    },
    {
      path: '/data-track',
      title: `數據類 - MusesAI`,
    },
    {
      path: '/data-track/FeaLost',
      title: `FeaLost 機台閒置分析 - MusesAI`,
    },
    {
      path: '/video-dynamic',
      title: `影像類(動態) - MusesAI`,
    },
    {
      // 2024 工業館
      path: '/grants/citd-musesai-event-2024',
      title: `2023 工業館補助方案 - MusesAI`,
    },
    {
      // 2024 SMB
      path: '/grants/smb-musesai-event-2024',
      title: `2024 智慧機上盒輔導計畫  - MusesAI`,
    },
    {
      // 2024 疫後計畫
      path: '/grants/transform-musesai-event-2024',
      title: `2024 10人以上中小型製造業低碳及智慧化升級轉型個案補助方案 - MusesAI`,
    },

    { path: '/plan-intro/editor', title: '方案介紹 - Editor' },
    { path: '/plan-intro/solution', title: '方案介紹 - Solution' },
    { path: '/plan-intro/tool', title: '方案介紹 - Tool' },
  ]

  function setDocumentTitle(nowPath) {
    const titleName = documentTitleArray.filter(({ path }) => {
      return path === nowPath
    })
    document.title = titleName[0]?.title ?? 'MusesAI'
  }

  useEffect(() => {
    let lastScrollTop = 0
    const windowOnScroll = () => {
      const scrollTopPosition =
        window.pageYOffset || document.documentElement.scrollTop
      if (scrollTopPosition > lastScrollTop && scrollTopPosition > 0)
        setIsScrollDown(true)
      else setIsScrollDown(false)

      lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition
    }
    window.addEventListener('scroll', windowOnScroll)
    return () => window.removeEventListener('scroll', windowOnScroll)
  }, [])

  useEffect(() => {
    setShowDropdownList(dropdownSiteMap.map((x) => false))
    const userDefaultLang = localStorage.getItem(
      'MusesAI_Entry_User_Default_Language_Is'
    )
    if (userDefaultLang) {
      setNowLanguage(userDefaultLang)
      switch (userDefaultLang) {
        case '繁體中文':
          setLocale('zh-TW')
          break
        case 'English':
          setLocale('en')
          break
        case '简体中文':
          setLocale('zh-CN')
          break
        case '日本語':
          setLocale('ja')
          break
        default:
          setLocale('zh-TW')
          break
      }
    }
    return () => setNowLanguage('繁體中文')
  }, [])

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth <= 1200) {
      setIsLargeScreen(false)
    } else setIsLargeScreen(true)
  }, [windowResizing])

  useEffect(() => {
    let timeout
    const handleResize = () => {
      clearTimeout(timeout)

      setWindowResizing(true)

      timeout = setTimeout(() => {
        setWindowResizing(false)
      }, 200)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setPathName(location.pathname)
    window.scrollTo(0, 0)
    setDocumentTitle(location.pathname)
  }, [location, courseName, coursePath])

  useEffect(() => {
    if (inapp.browser === 'line') {
      window.location += '?openExternalBrowser=1'
    } else if (inapp.isInApp) swal('請使用「預設瀏覽器」開啟連結')
  }, [])

  async function signOut() {
    try {
      await credential.signOut()
      swal(intl.formatMessage({ id: 'LogoutSuccess' }), {
        icon: 'success',
        button: false,
      })
      history.push('/')
    } catch (error) {
      console.warn(error)
    }
  }

  function changeLanguage(lang) {
    setNowLanguage(lang)
    switch (lang) {
      case '繁體中文':
        setLocale('zh-TW')
        break
      case 'English':
        setLocale('en')
        break
      case '简体中文':
        setLocale('zh-CN')
        break
      case '日本語':
        setLocale('ja')
        break
      default:
        setLocale('zh-TW')
        break
    }
    localStorage.setItem('MusesAI_Entry_User_Default_Language_Is', lang)
  }

  const updateStatus = (value, index) => {
    const showData = deepCopy(showDropdownList)
    showData[index] = value
    setShowDropdownList(showData)
  }

  return (
    <>
      <Navbar
        sticky="top"
        expand="xl"
        className={
          isScrollDown
            ? 'navbar front-menu shadow'
            : 'navbar front-menu shadow scroll-top-fixed'
        }
        collapseOnSelect
      >
        <Container className="justify-content-between">
          <Navbar.Brand>
            <Nav.Link as={Link} eventKey="/" to="/" className="nav-logo">
              <img
                src={Logo}
                alt="MusesAI - 一站式 AI 模型開發平台"
                title="MusesAI - 一站式 AI 模型開發平台"
              />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav activeKey={location.pathname}>
              {/* 產品應用 */}
              {dropdownSiteMap
                .filter(({ hide }) => !hide)
                .map(({ name, itemList }, i) => {
                  return (
                    <Dropdown
                      className="custom-dropdown"
                      key={i}
                      onMouseEnter={(e) => {
                        if (!isLargeScreen) e.preventDefault()
                        else updateStatus(true, i)
                      }}
                      onMouseLeave={(e) => {
                        if (!isLargeScreen) e.preventDefault()
                        else updateStatus(false, i)
                      }}
                      onClick={(e) => updateStatus(!showDropdownList[i], i)}
                      showDropdownList={showDropdownList[i]}
                    >
                      <Dropdown.Toggle>{name}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        {itemList
                          .filter((item) => !item.hide)
                          .map((el) => {
                            return (
                              <div
                                className="dropdown-item d-inline-block m-0 p-0"
                                key={el.link}
                              >
                                <Nav.Link
                                  className="p-3"
                                  as={Link}
                                  eventKey={el.link}
                                  to={el.link}
                                >
                                  {el.name}
                                </Nav.Link>
                              </div>
                            )
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                })}
              <a
                className="nav-link"
                href={MARKET_URL}
                target="_self"
                rel="noopener"
              >
                購物中心
              </a>
              <Nav.Link
                as={Link}
                eventKey="/support/videos"
                to="/support/videos"
              >
                支援服務
              </Nav.Link>
              {/* 
              暫時不會用到先註解
              <Nav.Link
                // className="classes-btn"
                as={Link}
                eventKey="/classes-list"
                to="/classes-list"
              >
                課程專區
              </Nav.Link> */}
              <Nav.Link as={Link} eventKey="/news" to="/news">
                新聞中心
              </Nav.Link>
              {/* <Nav.Link
                as={Link}
                eventKey="/citd-musesai-event-2023"
                to="/citd-musesai-event-2023"
              >
                2023工業館補助方案
              </Nav.Link> */}
              {/* 補助方案選單 */}
              {grantsdropdownSiteMap
                .filter(({ hide }) => !hide)
                .map(({ name, itemList }, i) => {
                  return (
                    <Dropdown
                      className="custom-dropdown"
                      key={i}
                      onMouseEnter={(e) => {
                        if (!isLargeScreen) e.preventDefault()
                        else updateStatus(true, i)
                      }}
                      onMouseLeave={(e) => {
                        if (!isLargeScreen) e.preventDefault()
                        else updateStatus(false, i)
                      }}
                      onClick={(e) => updateStatus(!showDropdownList[i], i)}
                      showDropdownList={showDropdownList[i]}
                    >
                      <Dropdown.Toggle>{name}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        {itemList
                          .filter((item) => !item.hide)
                          .map((el) => {
                            return (
                              <div
                                className="dropdown-item d-inline-block m-0 p-0"
                                key={el.link}
                              >
                                <Nav.Link
                                  className="p-3"
                                  as={Link}
                                  eventKey={el.link}
                                  to={el.link}
                                >
                                  {el.name}
                                </Nav.Link>
                              </div>
                            )
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                })}
              {auth && credential.authenticated && (
                <Dropdown
                  className="use-platform-dropdown"
                  onClick={() => {
                    const data = Object.values(credential.team)
                    setTeamsData(data)
                  }}
                >
                  <Dropdown.Toggle
                    className="use-platform-btn"
                    disabled={isExpired}
                  >
                    <FormattedMessage
                      id={'UseAiPlatform'}
                      defaultMessage="使用系統"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => credential.toPlatform()}
                      title="使用系統(個人)"
                    >
                      使用系統(個人)
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={
                        credential?.user?.trial_platform_status === 1
                          ? ''
                          : 'd-none'
                      }
                      onClick={() => credential.toTrialPlatform()}
                      title="使用系統(試用)"
                    >
                      使用系統(試用)
                    </Dropdown.Item>
                    {teamsData.map(({ team_id, team_name }) => {
                      return (
                        <Dropdown.Item
                          key={team_id}
                          title={team_name}
                          onClick={() =>
                            credential.toPlatformAsTeamMember(team_id)
                          }
                        >
                          {team_name}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
            <Nav Nav activeKey={location.pathname}>
              {/* 
              翻譯需要重新整理翻譯，先註解
              <Nav.Item className="switch-lang">
                <NavDropdown
                  className="switch-lang__btn"
                  title={
                    <>
                      <Language />
                      <span className="mr-2 ml-2">{nowLanguage}</span>
                    </>
                  }
                >
                  {languageSwitch.map((lang) => {
                    return (
                      <NavDropdown.Item
                        onClick={() => {
                          changeLanguage(lang)
                        }}
                        key={lang}
                      >
                        {lang}
                      </NavDropdown.Item>
                    )
                  })}
                </NavDropdown>
              </Nav.Item> */}
              {auth ? (
                <>
                  <Nav.Item>
                    <NavDropdown
                      title={
                        <>
                          <AccountCircle />
                          <span className="ml-2 mr-2">
                            {user ? `Hi, ${user.user_name ?? ''}` : ''}
                          </span>
                        </>
                      }
                      id="collasible-nav-dropdown"
                    >
                      <NavDropdown.Item className="m-0 p-0" as={'div'}>
                        <Nav.Link
                          className="m-0 pt-2 pb-2"
                          as={Link}
                          eventKey="/management-center"
                          to="/management-center"
                        >
                          管理中心
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Divider></NavDropdown.Divider>
                      <NavDropdown.Item
                        className="m-0 pt-2 pb-2"
                        onClick={() => signOut()}
                      >
                        <FormattedMessage
                          id={'SignOut'}
                          defaultMessage="登出"
                        />
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav.Item>
                </>
              ) : (
                <div className="d-flex login-status-btn">
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      eventKey="/login"
                      to={'/login' + location.search}
                    >
                      <Button
                        variant="outline-secondary"
                        className="login-btn"
                        active={pathname === '/login'}
                      >
                        <FormattedMessage id={'Login'} defaultMessage="登入" />
                      </Button>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      eventKey="/signup"
                      to={'/signup' + location.search}
                    >
                      <Button
                        className="ml-2 sign-up-btn"
                        active={pathname === '/signup'}
                      >
                        <FormattedMessage id={'SignUp'} defaultMessage="註冊" />
                      </Button>
                    </Nav.Link>
                  </Nav.Item>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default withRouter(injectIntl(Menu))
