import { DOMAIN_MAP } from '../../constants'
import { getCurrUtcDateTime, getUserId } from '../../util'
import { ajax } from './ajax'

export function updateUser({
  email,
  group_id,
  modify_by,
  domain_key,
  is_close,
  user_name,
}) {
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  const user_id = getUserId(email, group_id)
  const data = {
    key,
    user_id,
    modify_by,
    modify_time: getCurrUtcDateTime(),
  }
  if (is_close) {
    data.is_close = is_close
  }
  if (user_name) {
    data.user_name = user_name
  }
  return ajax(`${domain}/scapi/v1/ai/user/update`, {
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
    method: 'PUT',
  })
}

export function enableUser({ email, group_id, modify_by, domain_key }) {
  return updateUser({
    email,
    group_id,
    modify_by,
    domain_key,
    is_close: '1',
  })
}

export function disableUser({ email, group_id, modify_by, domain_key }) {
  return updateUser({
    email,
    group_id,
    modify_by,
    domain_key,
    is_close: '0',
  })
}

export function updatePassword({
  userInfoList,
  domain_key,
  oldPassword,
  newPassword,
}) {
  const { domain, scapi_key: key } = DOMAIN_MAP[domain_key]
  const data = new URLSearchParams()
  data.append('key', key)
  data.append('oldpwd', oldPassword)
  data.append('newpwd', newPassword)
  data.append('confirmpwd', newPassword)
  userInfoList.forEach(({ group_id, email }) => {
    data.append('user_id_list[]', getUserId(email, group_id))
  })
  return ajax(`${domain}/scapi/v1/changepwd`, {
    method: 'POST',
    body: data,
  })
}
