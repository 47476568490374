import emailjs from 'emailjs-com'
import { authMap, hostInfoTypeMap } from '../../constants'
import { fetchAllAdministrator } from '../../model/database/admin'
import { fetchGroupById } from '../../model/database/group'
import {
  currencyFormatter,
  pointsFormatter,
  getFormattedDateTime,
} from '../../util'
import { fetchMailAvailability } from '../../model/database/mail'
import { fetchGroupUser } from '../../model/database/userAndGroup'
import logger from '@servtech/client-logger'
import { storagePaths } from '../../model/storage/storage'
import { AdminNotify, shouldSendNotify } from './Notify'

const userId = 'user_ukRhAyq0GepgMKJtYUlS7'
const serviceId = 'service_h41yg3a'
const templateId = {
  orderNotification: 'template_y46ml3t',
  custom: 'template_yhxer9o',
}
const devActionDomain = 'http://localhost:3001'
const actionDomain = 'https://www.musesai.com'
const sendLineNotify = firebase.functions().httpsCallable('lineNotify')

/**
 * 管理員通知
 * @param {string} mail_id
 * @param {object} mailProps - 通知參數
 * @param {string} mailProps.title - 通知標題
 * @param {string} mailProps.subTitle - 通知副標題
 * @param {object} mailProps.content - 通知內容，為鍵值形式，最後會輸出內容是條列式
 * @returns
 */
async function notifyAdmin(mail_id, mailProps) {
  if (!Object.values(shouldSendNotify).every(Boolean)) {
    return
  }
  const [adminList, mail] = await Promise.all([
    fetchAllAdministrator(),
    fetchMailAvailability().then((mailList) =>
      mailList.find((item) => item.mail_id === mail_id)
    ),
  ])
  const availableAuthList = Object.keys(authMap).filter((auth) => mail[auth])
  const recipient = adminList.reduce((a, admin) => {
    const hasAuth = availableAuthList.find((auth) => admin[auth])
    return hasAuth ? [...a, admin.email] : a
  }, [])
  const { subject, html_content } = getEmailMessage(mailProps)
  const lineArgs = {
    message: AdminNotify.getLineMessage(
      {
        ...mailProps,
        department: mail_id === 'payment_confirm' ? '會計' : 'PM',
      },
      recipient
    ),
    token: process.env.REACT_APP_LINE_NOTIFY_TOKEN || '',
  }

  return Promise.all([
    sendCustomContentMail(recipient, subject, html_content),
    shouldSendNotify.lineNotify ? sendLineNotify(lineArgs) : Promise.resolve(),
  ])
}

async function sendOrderNotification(order_id, order, to_email, user_name) {
  if (!shouldSendNotify.email) {
    return
  }
  const isInvoiceCompany = order.invoice_type === 'COMPANY'
  const templateParams = {
    to_email,
    order_id,
    create_time: order.create_time,
    points: pointsFormatter.format(order.points),
    invoice_type: isInvoiceCompany ? '公司' : '個人',
    invoice_company_name: isInvoiceCompany
      ? order.companyDetail.company_name
      : '',
    invoice_tax_id: isInvoiceCompany ? order.companyDetail.tax_id : '',
    price: currencyFormatter.format(order.price),
    user_name: user_name || '顧客',
  }
  try {
    await emailjs.send(
      serviceId,
      templateId.orderNotification,
      templateParams,
      userId
    )
  } catch (error) {
    console.warn(
      '寄送失敗',
      error,
      serviceId,
      templateId.test,
      templateParams,
      userId
    )
    logger.error('Fail to sending mail...', error)
  }
}

async function sendOrderConfirmation(order_id, order) {
  const group = await fetchGroupById(order.group_id)
  const isInvoiceCompany = order.invoice_type === 'COMPANY'
  const lineMessage = {
    title: '訂單確認',
    content: {
      訂單編號: order_id,
      企業編號: order.group_id,
      企業地址: group.company_address ?? '',
      統一編號: group.tax_id ?? '',
      聯絡人名稱: group.contact_person_name ?? '',
      聯絡人電話: group.contact_person_tel ?? '',
      發票類型: isInvoiceCompany ? '公司' : '個人',
      發票企業名稱: isInvoiceCompany ? order.companyDetail.company_name : '',
      發票統一編號: isInvoiceCompany ? order.companyDetail.tax_id : '',
      訂單時間: getFormattedDateTime(order.create_time),
      點數: pointsFormatter.format(order.points),
      金額: currencyFormatter.format(order.price),
      操作連結: `${actionDomain}/admin/order?order_id=${order_id}`,
    },
    subTitle:
      '您好，有筆新的訂單需要您的確認，請在確認完資訊後點擊下方連結完成操作',
  }
  if (!isInvoiceCompany) {
    delete lineMessage.content['發票企業名稱']
    delete lineMessage.content['發票統一編號']
  }
  return notifyAdmin('new_order_confirm', lineMessage)
}

async function sendPaymentConfirmation(order_id, order) {
  const group = await fetchGroupById(order.group_id)
  const action_url = `${actionDomain}/admin/order?order_id=${order_id}`
  const isInvoiceCompany = order.invoice_type === 'COMPANY'
  const lineMessage = {
    title: '訂單待付款確認',
    content: {
      訂單編號: order_id,
      企業編號: order.group_id,
      企業地址: group?.company_address ?? '',
      統一編號: group?.tax_id ?? '',
      聯絡人名稱: group?.contact_person_name ?? '',
      聯絡人電話: group?.contact_person_tel ?? '',
      發票類型: isInvoiceCompany ? '公司' : '個人',
      發票企業名稱: isInvoiceCompany ? order.invoice_company_name : '',
      發票統一編號: isInvoiceCompany ? order.invoice_tax_id : '',
      訂單時間: getFormattedDateTime(order.create_time),
      點數: pointsFormatter.format(order.points),
      金額: currencyFormatter.format(order.price),
      操作連結: action_url,
    },
    subTitle:
      '您好，有筆新的訂單需要您確認付款，請在確認此筆訂單款項到帳後點擊下方連結完成操作',
  }
  if (!isInvoiceCompany) {
    delete lineMessage.content['發票企業名稱']
    delete lineMessage.content['發票統一編號']
  }
  return notifyAdmin('payment_confirm', lineMessage)
}

async function sendAccountNumberConfirmation(order_id, order) {
  const group = await fetchGroupById(order.group_id)
  const action_url = `${actionDomain}/admin/order?order_id=${order_id}&info`
  const isInvoiceCompany = order.invoice_type === 'COMPANY'
  const lineMessage = {
    title: '更新訂單匯款帳號',
    content: {
      '訂單編號': order_id,
      '企業編號': order.group_id,
      '企業地址': group?.company_address ?? '',
      '統一編號': group?.tax_id ?? '',
      '聯絡人名稱': group?.contact_person_name ?? '',
      '聯絡人電話': group?.contact_person_tel ?? '',
      '發票類型': isInvoiceCompany ? '公司' : '個人',
      '發票企業名稱': isInvoiceCompany ? order.invoice_company_name : '',
      '發票統一編號': isInvoiceCompany ? order.invoice_tax_id : '',
      '訂單時間': getFormattedDateTime(order.create_time),
      '點數': pointsFormatter.format(order.points),
      '金額': currencyFormatter.format(order.total),
      '匯款帳號末 5 碼': order.account_number,
      '操作連結': action_url,
    },
    subTitle: `您好，用戶已經更新訂單 ${order_id} 的匯款帳號末 5 碼，請您盡速確認款項並點擊下方連結完成訂單操作`,
  }
  if (!isInvoiceCompany) {
    delete lineMessage.content['發票企業名稱']
    delete lineMessage.content['發票統一編號']
  }
  return notifyAdmin('payment_confirm', lineMessage)
}

function sendApplyPointsConfirmation(order_id, order) {
  const action_url = `${actionDomain}/admin/order?order_id=${order_id}`
  const lineMessage = {
    title: '更新點數確認',
    content: {
      訂單編號: order_id,
      企業編號: order.group_id,
      訂單時間: getFormattedDateTime(order.create_time),
      點數: pointsFormatter.format(order.points),
      操作連結: action_url,
    },
    subTitle:
      '您好，此筆訂單已經確認完成匯款，需要您確認是否更新點數，請在確認完資訊後點擊下方連結完成操作',
  }
  return notifyAdmin('apply_points_confirm', lineMessage)
}

function sendUserSignUpNotification(userInfo) {
  const action_url = `${actionDomain}/admin/account?email=${userInfo.email}`
  const lineMessage = {
    title: '新用戶註冊',
    content: {
      Email: userInfo.email,
      使用者名稱: userInfo.user_name,
      註冊時間: getFormattedDateTime(userInfo.create_time),
      操作連結: action_url,
    },
    subTitle: '您好，有新用戶註冊，可到入口網站查看帳號資訊',
  }
  return notifyAdmin('new_register', lineMessage)
}

async function sendAccountActivatedNotification(email, user_name) {
  const subject = '[MusesAI] 帳號啟用通知信'
  const to_email = email
  const html_content = `<h3>親愛的 ${user_name ?? '顧客'} 您好：</h3>
    <p>您的 MusesAI 帳號已通過審核</p>
    <p>如已完成信箱驗證就可以開始使用系統</p>
    <p>感謝您的配合</p>
    <p>MusesAI 歡迎您的加入</p>
   `
  await sendCustomContentMail(to_email, subject, html_content)
}

async function sendOrderApprovedNotification(email, user_name, order) {
  const subject = '[MusesAI] 訂單完成通知信'
  const to_email = email
  const html_content = `<h3>親愛的 ${user_name ?? '顧客'} 您好：</h3>
    <p>您的訂單(編號: ${order.order_id})已完成</p>
    <ul>
      <li>訂單編號：${order.order_id}</li>
      <li>訂單時間：${order.create_time}(UTC +0:00)</li>
      <li>點數：${pointsFormatter.format(order.points)}</li>
      <li>金額：${currencyFormatter.format(order.price)}</li>
    </ul>
    <p>您可以立即登入 <a href="${actionDomain}">MusesAI</a> 確認變更</p>
    <p>感謝您的耐心等候</p>
   `
  await sendCustomContentMail(to_email, subject, html_content)
}

async function sendCancelCourseRegistrationNotification(userInfo, courseInfo) {
  const subject = '[MusesAI] 課程取消通知信'
  const to_email = userInfo.email
  const html_content = `<h3>親愛的 ${userInfo.user_name ?? '顧客'} 您好：</h3>
    <p>您的課程報名已經取消</p>
    <ul>
      <li>課程名稱：${courseInfo.course_name}</li>
      <li>上課時間：${courseInfo.start_time} ~ ${courseInfo.end_time}</li>
      <li>取消原因：${courseInfo.cancel_reason}</li>
      <li>取消時間：${courseInfo.cancel_time}</li>
    </ul>
   `
  await sendCustomContentMail(to_email, subject, html_content)
}

async function sendCourseRegistrationNotification(userInfo, courseInfo) {
  const subject = '[MusesAI] 課程報名成功通知信'
  const to_email = userInfo.email
  const html_content = `<h3>親愛的 ${userInfo.user_name ?? '用戶'} 您好：</h3>
    <p>您已成功報名 ${courseInfo.course_name}</p>
    <ul>
      <li>課程名稱：${courseInfo.course_name}</li>
      <li>上課時間：${courseInfo.start_time} ~ ${courseInfo.end_time}</li>
    </ul>
   `
  await sendCustomContentMail(to_email, subject, html_content)
}

function sendCourseRegistrationConfirmation(userInfo, courseInfo) {
  const action_url = `${actionDomain}/admin/course?email=${userInfo.email}&id=${courseInfo.course_id}`
  const lineMessage = {
    title: '課程報名',
    content: {
      課程編號: courseInfo.course_id,
      課程名稱: courseInfo.course_name,
      課程時間: `${getFormattedDateTime(
        courseInfo.start_time
      )} ~ ${getFormattedDateTime(courseInfo.end_time)}`,
      Email: userInfo.email,
      使用者名稱: userInfo.user_name,
      公司名稱: userInfo.company_name,
      統編: userInfo.tax_id,
      聯絡人電話: userInfo.contact_person_tel,
      報名時間: getFormattedDateTime(userInfo.create_time),
      前往後臺檢視: action_url,
    },
    subTitle: '您好，課程有新用戶報名，相關資訊如下，請查看',
  }
  return notifyAdmin('new_course_apply', lineMessage)
}

function sendCustomerMessageConfirmation(messageInfo) {
  const action_url = `${actionDomain}/admin/messages?time=${messageInfo.createTimestamp}`
  const lineMessage = {
    title: '顧客意見',
    content: {
      Email: messageInfo.email,
      使用者名稱: `${messageInfo.last_name} ${messageInfo.first_name}`,
      連絡電話: messageInfo.phone,
      意見類型: messageInfo.type,
      內容: messageInfo.description,
      前往後臺檢視: action_url,
    },
    subTitle: '您好，收到新的顧客意見，相關資訊如下，請查看',
  }
  return notifyAdmin('new_customer_review', lineMessage)
}

function sendTrialPointApplication(messageInfo) {
  const action_url = `${actionDomain}/admin/trial_point?id=${messageInfo.id}`
  const lineMessage = {
    title: '申請試用點數',
    content: {
      Email: messageInfo.email,
      使用者名稱: messageInfo.user_name,
      公司名稱: messageInfo.company_name,
      單位: messageInfo.department,
      原因: messageInfo.reason,
      前往後臺檢視: action_url,
    },
    subTitle: '您好，收到新的試用點數申請，相關資訊如下，請查看',
  }
  return notifyAdmin('trial_points_apply', lineMessage)
}

function sendApplicationResult(email, user_name) {
  const subject = '[MusesAI] 試用點數申請結果通知信'
  const to_email = email
  const html_content = `<h3>親愛的 ${user_name ?? '顧客'} 您好：</h3>
    <p>您申請的試用點數已通過審核</p>
    <p>現在可以立即登入 <a href="${actionDomain}">MusesAI</a> 確認變更</p>
    <p>感謝您的耐心等候</p>
   `
  return sendCustomContentMail(to_email, subject, html_content)
}

async function sendUploadNotification(type, order) {
  const { order_id } = order
  const group = await fetchGroupById(order.group_id)
  const fileLabel = storagePaths[type].text
  if (!fileLabel) {
    throw new Error('更新類別錯誤')
  }
  const action_url = `${actionDomain}/admin/order?order_id=${order_id}`
  const lineMessage = {
    title: `訂單${fileLabel}上傳成功`,
    content: {
      訂單編號: order_id,
      企業編號: order.group_id,
      企業地址: group.company_address ?? '',
      稅籍編號: group.tax_id ?? '',
      聯絡人名稱: group.contact_person_name ?? '',
      聯絡人電話: group.contact_person_tel ?? '',
      訂單時間: getFormattedDateTime(order.create_time),
      點數: pointsFormatter.format(order.points),
      金額: currencyFormatter.format(order.total),
      操作連結: action_url,
    },
    subTitle: `您好，訂單已上傳${fileLabel}，可到入口網站查看訂單資訊`,
  }
  return notifyAdmin('new_upload', lineMessage)
}

async function sendCustomUploadSuccessNotification(type, order) {
  const { order_id } = order
  const groupUserList = await fetchGroupUser(order.group_id)
  const user = groupUserList.find((obj) => obj.is_group_admin)
  const { user_name, email } = user
  const fileLabel = storagePaths[type].text
  if (!fileLabel) {
    throw new Error('更新類別錯誤')
  }
  const subject = `[MusesAI] ${fileLabel}上傳成功通知信`
  const to_email = email
  const html_content = `<h3>親愛的 ${user_name ?? '顧客'} 您好：</h3>
    <p>您的訂單(編號: ${order_id})已成功上傳${fileLabel}</p>
    <ul>
      <li>訂單編號：${order_id}</li>
      <li>訂單時間：${order.create_time}(UTC +0:00)</li>
      <li>點數：${pointsFormatter.format(order.points)}</li>
      <li>金額：${currencyFormatter.format(order.total)}</li>
    </ul>
    <p>您可以立即登入 <a href="${actionDomain}">MusesAI</a> 檢視上傳的${fileLabel}</p>
    <p>感謝您的耐心等候</p>
   `
  return sendCustomContentMail(to_email, subject, html_content)
}

function sendCustomPrivateHostInfo(type, user, hostInfo) {
  const { user_name, email } = user
  const label = hostInfoTypeMap[type]
  if (!label) {
    throw new Error('資訊類別錯誤')
  }
  const subject = `[MusesAI] ${label}`
  const to_email = email
  let html_content = `<h3>親愛的 ${user_name ?? '顧客'} 您好：</h3>
    <p>以下為您的${label}</p>`
  switch (type) {
    case 'login_info':
      html_content += `
        <ul>
          <li>帳號：${hostInfo.login_info.account}</li>
          <li>密碼：${hostInfo.login_info.password}</li>
        </ul>
        `
      break
    case 'remote_connection_info':
      html_content += `
        <ul>
          <li>遠端軟體：${hostInfo.remote_connection_info.type}</li>
          <li>ID：${hostInfo.remote_connection_info.id}</li>
          <li>密碼：${hostInfo.remote_connection_info.password}</li>
        </ul>
        `
      break
    case 'musesai_inference_info':
      html_content += `
        <ul>
          <li>網址：${hostInfo.musesai_inference_info.id}</li>
          <li>帳號：${hostInfo.musesai_inference_info.admin}</li>
          <li>密碼：${hostInfo.musesai_inference_info.password}</li>
        </ul>
        `
      break
    default:
      throw new Error('資訊類別錯誤')
  }
  return sendCustomContentMail(to_email, subject, html_content)
}

async function sendCustomContentMail(to_email, subject, html_content) {
  if (!shouldSendNotify.email) {
    return
  }
  const templateParams = {
    to_email,
    from_name: 'MusesAI',
    reply_to: 'no_reply',
    html_content,
    subject,
  }
  try {
    await emailjs.send(serviceId, templateId.custom, templateParams, userId)
  } catch (error) {
    console.warn(
      '寄送失敗',
      error,
      serviceId,
      templateId.test,
      templateParams,
      userId
    )
    logger.error('Fail to sending mail...', error)
  }
}

function getEmailMessage(lineMessage) {
  const { title, content, subTitle } = lineMessage
  const subject = `[MusesAI] ${title}`
  let html_content = `<h3>${subTitle}</h3><ul>`
  Object.entries(content).forEach(([title, value]) => {
    html_content +=
      title === '內容'
        ? `<li style="white-space: pre-wrap;">內容: <br>${value}</li>`
        : `<li >${title}: ${value}</li>`
  })
  html_content += '</ul>'

  return { subject, html_content }
}
export {
  sendOrderNotification,
  sendOrderConfirmation,
  sendPaymentConfirmation,
  sendAccountNumberConfirmation,
  sendApplyPointsConfirmation,
  sendUserSignUpNotification,
  sendAccountActivatedNotification,
  sendOrderApprovedNotification,
  sendCancelCourseRegistrationNotification,
  sendCourseRegistrationNotification,
  sendCourseRegistrationConfirmation,
  sendCustomerMessageConfirmation,
  sendTrialPointApplication,
  sendApplicationResult,
  sendUploadNotification,
  sendCustomUploadSuccessNotification,
  sendCustomPrivateHostInfo,
  sendCustomContentMail,
}
