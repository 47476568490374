import { BrowserRouter as Router, Switch } from 'react-router-dom'
import React, { createContext, useState, useEffect } from 'react'
import credential from './module/controller/Credential/credential'
import 'bootstrap/dist/css/bootstrap.min.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import swal from 'sweetalert'
import { checkIfUserDisabled } from './module/model/database/userAndGroup'
import useAuth from './hook/useAuth'
import {
  HomePageLoading,
  ManagementCenterLoader,
} from './components/Skeletons/CustomSkeletons'

import routes from './routes'
import { renderRoutes } from 'react-router-config'

export const LogContext = createContext(null)

const afterFirstGetAuth = new Promise((res) =>
  credential.once('authChange', res)
)
let isFirstGetAuth = true

function Main(props) {
  const auth = useAuth(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [teamsData, setTeamsData] = useState([])
  const [currentTeamData, setCurrentTeamData] = useState([])
  const [currentTeamId, setCurrentTeamId] = useState('')
  const [nowSpace, setNowSpace] = useState('memberPages')
  const [coursePath, setCoursePath] = useState('')
  const [courseName, setCourseName] = useState('')
  const [platformId, setPlatformId] = useState('')

  useEffect(() => {
    const url_str = window.location.href
    const url = new URL(url_str)
    const uuidFromUrl = url.searchParams.get('uuid')
    if (uuidFromUrl) {
      swal(
        '購買點數流程',
        '1. 登入系統（新用戶註冊需先完成信箱驗證才能購買點數及轉移）\n2. 前往「點數儲值」頁面購買點數\n3. 儲值完成後，前往「綁定裝置管理」頁面將點數轉移至您所購買的系統',
        {
          icon: 'info',
          type: 'info',
          className: 'device-binding-hint',
        }
      )
      setPlatformId(uuidFromUrl)
    }
  }, [])

  useEffect(() => {
    Aos.init({
      duration: 1200,
    })
    if (isFirstGetAuth) {
      afterFirstGetAuth.then(async (user) => {
        let currentUser = await credential.authenticate(user)
        if (currentUser) {
          const isDisabled = await checkIfUserDisabled(currentUser.email)
          if (isDisabled) {
            await credential.signOut()
          }
          currentUser = !isDisabled
        }
        if (currentUser) {
          await credential.init()
          credential.setSessionExpireTimer(() => {
            swal('您的登入狀態已逾時', '請重新登入', 'info')
          })
          setCurrentTeamId('memberPages')
          const teamsDataFromDB = Object.values(credential.team)
          setTeamsData(teamsDataFromDB)
        }
        setPageLoading(false)
        isFirstGetAuth = false
      })
    } else if (credential.user) {
      setPageLoading(false)
    } else {
      setPageLoading(false)
    }
  }, [])

  useEffect(() => {
    const teamsDataFromDB = Object.values(credential.team)
    const data = teamsDataFromDB.filter((el) => {
      return el.team_id === currentTeamId
    })
    setCurrentTeamData(data[0])
  }, [currentTeamId, currentTeamData])

  function LoaderHandler() {
    if (window.location.pathname.includes('/management-center'))
      return <ManagementCenterLoader />
    else return <HomePageLoading />
  }

  return pageLoading ? (
    <LoaderHandler />
  ) : (
    <LogContext.Provider
      value={{
        loginStatus: auth,
        teamsData,
        setTeamsData,
        currentTeamData,
        setCurrentTeamData,
        currentTeamId,
        setCurrentTeamId,
        nowSpace,
        setNowSpace,
        courseName,
        setCourseName,
        coursePath,
        setCoursePath,
        platformId,
        setPlatformId,
      }}
    >
      <Router>
        <Switch>
          {renderRoutes(routes, {
            auth,
          })}
        </Switch>
      </Router>
    </LogContext.Provider>
  )
}

export default Main
