const zh_TW = {
  AboutUs: '關於我們',
  UseCase: '使用案例',
  Pricing: '計價方式',
  Solution: '解決方案',
  ContactUs: '聯絡我們',
  Name: '姓名',
  LastName: '姓氏',
  Phone: '連絡電話',
  ProductKnowHow: '產品問題',
  Description: '說明',
  MemberProfile: '會員資料',
  MemberAccount: '會員帳號',
  OrderRecord: '訂單紀錄',
  BuyPoint: '點數儲值',
  PointsRecord: '點數使用紀錄',
  certificationLetterCannotSentContinuouslyPleaseTryAgainLater:
    '認證信不得連續發送，請稍後再試',
  Account: '帳號',
  AccountManagement: '帳號管理',
  Password: '密碼',
  UseAiPlatform: '使用系統',
  SignOut: '登出',
  Login: '登入',
  LoginSuccess: '登入成功',
  LogoutSuccess: '登出成功',
  SignUp: '註冊',
  SignUpSuccess: '註冊成功',
  TypeOfQuestion: '問題類型',
  Submit: '確認送出',
  RequestDemo: '申請專人演示',
  StartForFree: '開始免費試用',
  ProductFeature: '產品特色',
  MusesAIFeature: 'MusesAI特色',
  RedirectToHomePageAfterThreeSec: '將在3秒後跳轉至首頁',
  PleaseEnterCorrectEmail: '請輸入正確的 email 格式',
  WrongPassword: '密碼錯誤',
  AccountNotExist: '帳號不存在',
  Error: '錯誤',
  UnexpectedErrorPleaseContactSystemAdmin: '發生未預期錯誤，請聯絡系統管理員！',
  PleaseEnterYourAccount: '請輸入帳號',
  RequiredFields: '此欄位必填',
  PasswordMustBeAtLeastSixDigits: '密碼最小長度為 6 個字',
  PleaseEnterAlphabetOrNumbers: '請輸入英數字',
  PleaseContinueEmailAuth: '請接續完成信箱認證',
  AlreadyGotAccount: '已有帳號？',
  UserName: '使用者名稱',
  SetAUniqueIdentificationCodeForAGroupOfCompanies: '設定一組企業的唯一識別碼',
  PleaseEnterAlphabetAndNumbersCaseSensitive: '請輸入英數字（區分大小寫）',
  SignUpNewEnterpriseAccount: '註冊新的企業帳號',
  CompanyName: '公司名稱',
  CompanyAddress: '公司地址',
  ContactPersonName: '聯絡人名稱',
  ContactPersonEmail: '聯絡人信箱',
  ContactPersonPhone: '聯絡人電話',
  SameAsUserName: '同使用者名稱',
  SameAccount: '同帳號',
  TaxId: '統一編號',
  RefAgentId: '供應商帳號',
  SendVerificationLetter: '寄送驗證信',
  SendVerificationLetterError: '發送認證信錯誤',
  MailboxVerification: '信箱驗證',
  Verified: '已驗證',
  Unverified: '未驗證',
  Activated: '已啟用',
  NotActivated: '未啟用',
  Refuse: '拒絕',
  SendingSuccess: '成功發送',
  PleaseCheckTheVerificationLetterInYourMailbox: '請至信箱查看驗證信',
  NeedToWaitForXSecToSendAgain: '等待 {sec} 秒才能再次送出',
  PleaseContactTheSystemAdmin: '請聯絡系統管理員',
  FailedToGetTheRemainingPoints: '拿剩餘點數失敗',
  RemainingPoints: '剩餘點數',
  Status: '狀態',
  CreateTime: '建立時間',
  EnterpriseInformation: '企業資料',
  EnterpriseAccount: '企業帳號',
  EnterpriseName: '企業名稱',
  EnterpriseAddress: '企業地址',
  EnterpriseTel: '企業電話',
  OrderNumber: '訂單編號',
  Type: '類別',
  PriceA: '金額',
  PriceB: '價格',
  Amount: '數量',
  SubTotal: '小計',
  TotalPoints: '點數總計',
  Cancel: '取消',
  OrderCreateTime: '訂單時間',
  // BuyPoints.js
  user_name: '使用者名稱',
  contact_person_name: '聯絡人名稱',
  contact_person_tel: '聯絡人電話',
  ChoosePlan: '選擇方案',
  ConfirmOrder: '確認訂單',
  OrderEstablished: '訂單成立',
  DownloadQuotation: '下載報價單',
  DownloadTerms: '下載同意書',
  SignBackTheQuotationAndSendItBackToServtech: '報價單簽回回傳科智',
  SendMoneyAccordingToTheQuotedAccountAndNotifyServtechViaEmail:
    '依報價單帳號匯款並 Email 通知科智',
  ServtechConfirmReceiptOfMoney: '科智確認收到款項',
  ServtechProvidePointsAndEmailNotification: '科智提供點數並 Email 通知',
  PurchaseProcess: '購買流程',
  IAlreadyUnderstandThePurchaseProcedure: '我已了解購買程序',
  NextStep: '下一步',
  And: '並',
  Points: '點數',
  Dot: '點',
  PleaseDragTheScrollToViewTheFullForm: '請拖動卷軸查看完整表單',
  TurnBack: '返回',
  YourSystemIsIOS: '您的系統為IOS',
  CurrentlyDoesNotSupportIOSSystemOperationPleaseUseComputerToOperate:
    '目前暫不支援IOS系統操作，請以電腦進行操作，謝謝！',
  Quotation: '報價單',
  Terms: '同意書',
  ProjectPoint: '專案點數',
  Fail: '失敗',
  ErrorOccurred: '發生錯誤',
  PleaseSelectAtLeastOnePlan: '請至少選擇一個方案',
  FailedToPlaceOrder: '下訂單失敗',
  OrderCompleted: '訂單已完成',
  ThanksForPurchase: '感謝您的購買',
  RedirectToOrderPageInXSec: '將於 {sec} 秒後前往訂單頁面',
  ConfirmEdit: '確認編輯',
  Edit: '編輯',
  ManagementRights: '管理權限',
  True: '是',
  False: '否',
  Verify: '審核',
  ProjectId: '專案編號',
  ProjectType: '專案類型',
  ActionType: '動作類別',
  StartTime: '起始時間',
  EndTime: '結束時間',
  TotalTimeMinutes: '總耗時（分）',
  UsePoints: '使用點數',
  EditSuccess: '編輯成功',
  Mode: '模式',
  YourMemberDataIsIncomplete: '您的會員資料不完整',
  YourMemberDataIsIncompletePleaseFillInCompleteInformationBeforeBuyPoint:
    '您的會員/企業資料不完整，請填寫完整資料後再進行購點動作，謝謝！',
  PleaseFillInTheRequiredFields: '請確實填寫必填欄位!',
  IncorrectPattern: '格式不正確!',
  AgentIdCanOnlyEditOnce: '供應商帳號只能編輯一次',
  YouCanNotEditAgainAfterConfirm:
    '確認後您將無法修改供應商帳號，確定要更改嗎？',
  MustBeCompanyMail: '必須是公司郵件',
  PleaseFillInTheCompanyMail: '請填入公司郵件',
  MySpace: '我的空間',
  GroupInfo: '團隊資料',
  GroupMembers: '團隊成員',
  PointsTransfer: '轉移點數',
  CreateSuccess: '新增成功',
  CreateNewGroup: '建立新團隊',
  TeamName: '團隊名稱',
  EnterTeamName: '請輸入團隊名稱',
  TeamDescription: '團隊簡介',
  OptionalField: '此為選填欄位',
  CreateConfirm: '建立確認',
  CreateTeam: '新增團隊',
  GroupList: '團隊列表',
  ProductDescription: '產品說明',
  AboutPage: {
    'About-Content':
      '    MusesAi平台透過網頁設定即可建立模型，不用動用到任何程式碼，真正實現AI 民主化，僅需要少數圖像即可進行視覺辨識，簡單進入產線，快速達到工廠設立的ROI。\n\r\n\r    市面上鮮少有專屬製造業的AI應用工具，我們希望能達成的使命，如同如早期網頁設計，需要Coding才能達成，但隨著當年FrontPage與Dreamweaver問世，降低網頁設計開發門檻，讓各式各樣的人才都能透過工具去完成心中的期許，奠定網路產業的偉大時代。同樣地，AI開發工具的趨勢正在轉變各個產業，而MusesAi更適用於製造業中仰賴人力的工作站，我們期待您能透過製造業專屬的AI工具，感受到AI可以是簡單、快速地對生產線帶來幫助，也期待能與您走在趨勢前緣、一起成長。',
    'CompanyProfile': '公司簡介',
    'IntroCardInfo-Automation-Title': '自動化整合(軟硬件整合)',
    'IntroCardInfo-Automation-Content':
      '全自製開發通訊標準(TMTC)，向下延伸至各種硬件與設備，有效採集各種設備，並超過90%設備對接率，不受品牌與型號限制，進而打入亞洲及歐美市場。',
    'IntroCardInfo-SystemIntegration-Title': '系統整合(軟件整合)',
    'IntroCardInfo-SystemIntegration-Content':
      '將客戶各場域內相關軟件進行整合，無論是ERP、MES、APS等系統，與本公司內部進行系統整合與對接，透過統一平台進行可視化。',
    'IntroCardInfo-Management-Title': '管理顧問(IT+IE顧問服務)',
    'IntroCardInfo-Management-Content':
      '透過精實管理從管理議題上切入客戶問題中心，將管顧能量帶入客戶各個廠域內，從工廠最基本問題”人員問題”開始解決，將狀況切分成各個面向，並且有效解決客戶痛點。',
    'IntroCardInfo-DataAnalysis-Title': '​智慧化(數據分析)',
    'IntroCardInfo-DataAnalysis-Content':
      '從自動化、系統整合、管理顧問以及平台應用，將自身工廠議題延伸至上下游供應鏈，透過本公司核心能力，將智能化發揮最大效益 ，打造屬於客戶專屬的智能化工廠。',
  },
  UseCasePage: {
    'Banner-Title':
      '簡單標記圖像服務、使⽤深度學習技術與辨識動作邏輯模組，降低開發難度與時間投⼊',
    'Banner-SubTitle':
      'Using AI Framework, UTILIZING AI has never been EASIER...',
    'SolutionInfo-PCB-Title': 'PCB 組裝',
    'SolutionInfo-PCB-Content': {
      Part_1: '作業程序檢查',
      Part_2: '標準工時計算',
    },
    'SolutionInfo-Metal-Title': '金屬加工',
    'SolutionInfo-Metal-Content': {
      Part_1: '作業程序檢查',
      Part_2: '標準工時計算',
    },
    'SolutionInfo-Electronic-Title': '電子產品加工',
    'SolutionInfo-Electronic-Content': {
      Part_1: '作業程序檢查',
      Part_2: '標準工時計算',
    },
    'SolutionInfo-Factory-Title': '廠區巡檢',
    'SolutionInfo-Factory-Content': {
      Part_1: '人員閒置',
      Part_2: '設備異常偵測',
      Part_3: '設備警示燈辨識',
      Part_4: '工作、物件位置巡查',
    },
  },
  PricingPage: {
    'Banner-Title': 'Start for free, pay as you grow',
    'Banner-SubTitle': 'State of the art solutions for every AI skill level',
    'Price-Description': '計價說明',
    'Price-Description-Content': {
      content_1: '1. 1點 5000元，可執⾏模型訓練或預測1⼩時。',
      content_2: '2. 最低購買單位為 40 點。',
      content_3: '3. 不同專案之間點數需分開購買，不能互通。',
    },
    'ContactUsToKnowDetail': '聯絡專人了解更多',
    'PricePlan-Free-Title': '試用方案',
    'PricePlan-Free-PaymentNum': '0',
    'PricePlan-Free-PaymentUnit': '元',
    'PricePlan-Free-Info': {
      Info_1: '註冊企業帳號',
    },
    'PricePlan-Base-Title': '基本方案',
    'PricePlan-Base-PaymentNum': '40',
    'PricePlan-Base-PaymentUnit': '點',
    'PricePlan-Base-Info': {
      Info_1: '適用於小型企業',
    },
    'PricePlan-Advanced-Title': '進階方案',
    'PricePlan-Advanced-PaymentNum': '80',
    'PricePlan-Advanced-PaymentUnit': '點',
    'PricePlan-Advanced-Info': {
      Info_1: '適用於中型企業',
    },
    'PricePlan-Compare': '方案比較',
    'PricePlan-FreePlan': '免費方案',
    'PricePlan-PayPlan': '付費方案',
    'PricePlan-Compare-TrainSOP-Title': '訓練模型 SOP 糾錯',
    'PricePlan-Compare-ShowResult-Title': '查看訓練結果報告',
    'PricePlan-Compare-DownloadTrainModule-Title': '下載訓練模型',
  },
  HomePage: {
    // BannerTitleOnTop: '讓人好做事',
    // BannerTitleOnBottom: '讓事省時間',
    // BannerSubtitle: '不用寫程式即可簡單做出 AI 應用，快來體驗一站式的 AI 工具',
    BannerTitleOnTop: 'AI模型開發工具',
    BannerTitleOnBottom: '領導品牌',
    BannerSubtitleTop: 'AI其實一點也不難，你需要的是MusesAI',
    BannerSubtitleBottom: '讓人好做事，讓事省時間',
    DiverseUsageScenarios: '多樣態的使用情境',
    FeatureCardInfo: {
      Card_1: {
        Title: '跨越程式',
        Content:
          'AI平台透過網頁操作，即可建立模型，不用IT撰寫任何程式碼，實現產生AI模型自主化。',
      },
      Card_2: {
        Title: '快速實作',
        Content:
          'AI平台僅要少數圖像即可開始進行視覺辨識，簡單快速產生模型，達到您設定的ROI。',
      },
      Card_3: {
        Title: '降低門檻',
        Content: '簡單網頁使用，一步流程帶領，讓使用者更專注在問題的解決。',
      },
    },
    SituationCardInfo: {
      Card_1: {
        Topic: '人',
        TextArea_1: {
          Title: '電子圍籬',
          Content: '人機協同合作安全監督指導',
        },
        TextArea_2: {
          Title: '作業程序檢查',
          Content: '檢測成品遺漏與提升產品質量',
        },
        TextArea_3: {
          Title: '人員閒置/標準工時計算',
          Content: '提升生產線效率與產能',
        },
      },
      Card_2: {
        Topic: '機',
        TextArea_1: {
          Title: '設備異常偵測',
          Content: '主動式偵測設備非標準行為',
        },
        TextArea_2: {
          Title: '設備警示燈辨識',
          Content: '無感即時採集設備狀態',
        },
      },
      Card_3: {
        Topic: '料',
        TextArea_1: {
          Title: '工件/物料位置巡檢',
          Content: '追蹤物料位置與成本控管',
        },
        TextArea_2: {
          Title: '工件/物料編碼識別',
          Content: '辨識加工後不清晰的編號',
        },
        TextArea_3: {
          Title: '工件/物料缺陷檢測',
          Content: '篩選出不良或缺陷的位置',
        },
      },
      Card_4: {
        Topic: '環',
        TextArea_1: {
          Title: '設備漏油/漏液',
          Content: '動態保養設備與降低生產不良',
        },
        TextArea_2: {
          Title: '異常溫度/煙霧/異物偵測',
          Content: '預防工作環境之危險因素',
        },
      },
    },
  },
}

export default zh_TW
