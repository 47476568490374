import _ from 'lodash'
import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'
import { getCurrUtcDateTime } from '../../util'
import databaseCrud from './crud'
import { checkIfUserExist } from './userAndGroup'

export async function createTeam(team_id, teamData) {
  await databaseCrud.create(`/team/${team_id}`, teamData)
}

export function getTeamMemberId(team_id, email) {
  return btoa(`${team_id}${email}`)
}

export async function insertMember(team_id, { email, create_by, auth = 0 }) {
  const isUserExist = await checkIfUserExist(email)
  if (!isUserExist) {
    throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id, {
      email,
    })
  }
  const teamData = await fetchTeamData(team_id)

  if (!teamData) {
    throw new ExtendedError(ERROR_CODE_MAP.TeamNotFound.id, {
      team_id,
    })
  }
  const { member } = teamData
  const authCountMap = _.chain(member)
    .groupBy('auth')
    .mapValues((ar) => ar.length)
    .value()
  if (member.findIndex((obj) => obj.email === email) >= 0) {
    throw new ExtendedError(ERROR_CODE_MAP.AlreadyInTeam.id, {
      email,
    })
  } else if (auth === 0 && authCountMap[auth] >= 1) {
    throw new ExtendedError(ERROR_CODE_MAP.ExceedAdministratorLimit.id, {
      limit: 1,
    })
  } else if (auth === 1 && authCountMap[auth] >= 3) {
    throw new ExtendedError(ERROR_CODE_MAP.ExceedDeputyAdministratorLimit.id, {
      limit: 3,
    })
  } else if (member.length > 100) {
    throw new ExtendedError(ERROR_CODE_MAP.ExceedTeamMemberLimit.id, {
      limit: 100,
    })
  }
  const teamMemberId = getTeamMemberId(team_id, email)
  const create_time = getCurrUtcDateTime()
  await databaseCrud.create(`/team_member/${teamMemberId}`, {
    team_id,
    email,
    create_by,
    auth,
    modify_by: create_by,
    create_time,
    modify_time: create_time,
  })
}

export async function updateMember(
  team_id,
  { email, auth, modify_by },
  isReinsert = false
) {
  const isUserExist = await checkIfUserExist(email)
  if (!isUserExist) {
    throw new ExtendedError(ERROR_CODE_MAP.UserNotFound.id, {
      email,
    })
  }
  const teamData = await databaseCrud.read(`/team/${team_id}`)

  if (!teamData) {
    throw new ExtendedError(ERROR_CODE_MAP.TeamNotFound.id, {
      team_id,
    })
  }
  const member = await databaseCrud
    .filterByChildProp('/team_member', 'team_id', team_id)
    .then((data) => Object.values(data))
  const authCountMap = _.chain(member)
    .groupBy('auth')
    .mapValues((ar) => ar.length)
    .value()
  const isRemoveMember = [9, 10].includes(auth)
  if (member.findIndex((obj) => obj.email === email) < 0) {
    throw new ExtendedError(ERROR_CODE_MAP.ExceedAdministratorLimit.id, {
      email,
    })
  } else if (auth === 0 && authCountMap[auth] >= 1) {
    throw new ExtendedError(ERROR_CODE_MAP.ExceedAdministratorLimit.id, {
      limit: 1,
    })
  } else if (auth === 1 && authCountMap[auth] >= 3) {
    throw new ExtendedError(ERROR_CODE_MAP.ExceedDeputyAdministratorLimit.id, {
      limit: 3,
    })
  } else if (!isRemoveMember && isReinsert && member.length > 100) {
    throw new ExtendedError(ERROR_CODE_MAP.ExceedTeamMemberLimit.id, {
      limit: 100,
    })
  }
  const teamMemberId = getTeamMemberId(team_id, email)
  const modify_time = getCurrUtcDateTime()
  await databaseCrud.update(`/team_member/${teamMemberId}`, {
    auth,
    modify_by,
    modify_time,
  })
}

export async function checkIfTeamExist(team_id) {
  const response = await databaseCrud.read(`/team/${team_id}`)
  return response !== null
}

export async function fetchTeamMemberList(team_id) {
  const response = await databaseCrud.filterByChildProp(
    '/team_member',
    'team_id',
    team_id
  )
  return response
    ? Object.values(response).filter(({ auth }) => [0, 1, 2].includes(auth))
    : []
}

export async function fetchTeamData(team_id) {
  const team = await databaseCrud.read(`/team/${team_id}`)
  if (!team) {
    return
  }
  const member = await fetchTeamMemberList(team_id)
  return {
    ...team,
    member,
  }
}

export async function fetchTeamByUser(email, showAll) {
  const teamMap = await databaseCrud.filterByChildProp(
    '/team_member',
    'email',
    email
  )
  if (!teamMap) {
    return {}
  }
  const teamIdList = _.map(
    Object.values(teamMap).filter(
      ({ auth }) => showAll || [0, 1, 2].includes(auth)
    ),
    'team_id'
  )
  const teamDataList = await Promise.all(
    teamIdList.map((team_id) => databaseCrud.read(`/team/${team_id}`))
  )
  const result = {}

  teamDataList.forEach((data) => {
    const {
      team_id,
      team_name,
      domain_key,
      team_description,
      create_by: team_create_by,
      create_time: team_create_time,
    } = data
    const teamMemberId = getTeamMemberId(team_id, email)
    const {
      auth,
      create_time: member_create_time,
      create_by: member_create_by,
    } = teamMap[teamMemberId]
    result[team_id] = {
      team_id,
      auth,
      team_name,
      team_description,
      domain_key,
      team_create_by,
      team_create_time,
      member_create_by,
      member_create_time,
    }
  })
  return result
}

export async function fetchTeamMemberAuth(team_id, email) {
  const userTeamMap = await databaseCrud.filterByChildProp(
    '/team_member',
    'email',
    email
  )
  const user = userTeamMap
    ? Object.values(userTeamMap).find((obj) => obj.team_id === team_id)
    : null
  return user?.auth
}

export async function fetchAllTeam() {
  const team = await databaseCrud.read(`/team`)
  return team ? Object.values(team) : []
}
