// 統一處理 fetch 的錯誤，因為 fetch 不會拋出主動拋出狀態碼 4XX / 5XX 的錯誤
export default function customFetch(...args) {
  return fetch(...args).then((resp) => {
    // status code not in 200-299
    if (!resp.ok) {
      throw new Error(resp.statusText)
    }
    return resp
  })
}
