import logger from '@servtech/client-logger'

function init() {
  logger.configure('loki', {
    url: 'https://cloud.servtech.com.tw:58888/loki/api/v1/push',
    // 本機測試用，上線時請改成 true
    excludeLocal: true,
  })
  logger.plugins.loki.labels = {
    app: 'musesai-landing',
    host: window.location.host,
    agent: window.navigator.userAgent,
    version: process.env.REACT_APP_VERSION,
  }
  const excepts = ['remote']
  logger.enablePlugins(excepts)
  if (process.env.REACT_APP_PRODUCTION !== 'true') {
    window.logger = logger
  }
}

export default init
