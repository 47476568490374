import credential from '../../controller/Credential/credential'
import { getCurrUtcDateTime } from '../../util'
import databaseCrud from './crud'

export async function fetchMailAvailability() {
  const ref = '/mail'
  const value = await databaseCrud.read(ref)
  return value
    ? Object.entries(value).map(([mail_id, obj]) => ({ ...obj, mail_id }))
    : []
}

export async function updateMailAvailability(mail_id, option = {}) {
  const obj = {
    modify_time: getCurrUtcDateTime(),
    modify_by: credential.user.email,
    ...option,
  }
  await databaseCrud.update('/mail/' + mail_id, obj)
  return obj
}
