import credential from '../../controller/Credential/credential'
import { getCurrUtcDateTime } from '../../util'
import databaseCrud from './crud'

export async function checkIsAdmin(data, type = 'email') {
  let admin
  switch (type) {
    case 'uid':
      admin = await databaseCrud.read('/admin/' + data)
      break
    case 'email':
    default:
      admin = await databaseCrud.filterByChildProp('/admin', 'email', data)
      break
  }
  return !!admin
}
export async function getQualifiedUserUid(email) {
  const userMap = await databaseCrud.filterByChildProp('/user', 'email', email)
  const user = userMap ? Object.values(userMap)[0] : null
  return user?.status === 1 ? user.user_id : null
}
export async function setAdmin(uid, option = {}) {
  const ref = `/admin/${uid}`
  const {
    email = '',
    confirm_order = false,
    confirm_payment = false,
    apply_points = false,
    customer_service = false,
    manage_course = false,
    activate_account = false,
    content_management = false,
    agent_management = false,
    points_management = false,
  } = option
  const adminObj = {
    email,
    confirm_order,
    confirm_payment,
    apply_points,
    customer_service,
    manage_course,
    activate_account,
    content_management,
    agent_management,
    points_management,
    super: false,
    create_time: getCurrUtcDateTime(),
    create_by: credential.user.email,
  }
  await databaseCrud.create(ref, adminObj)
  return adminObj
}
export async function updateAdmin(uid, option = {}) {
  const obj = {
    modify_time: getCurrUtcDateTime(),
    modify_by: credential.user.email,
    ...option,
  }
  await databaseCrud.update('/admin/' + uid, obj)
  return obj
}
export function fetchAdminInfo(uid) {
  const ref = '/admin/' + uid
  return databaseCrud.read(ref)
}
export async function fetchAllAdministrator() {
  const ref = '/admin'
  const value = await databaseCrud.read(ref)
  return value
    ? Object.entries(value).map(([uid, obj]) => ({ ...obj, uid }))
    : []
}
export function removeAdministrator(uid) {
  const ref = '/admin/' + uid
  return databaseCrud.delete(ref)
}
