import _ from 'lodash'
import { ERROR_CODE_MAP, ExtendedError } from '../../ErrorCode'

class DatabaseCrud {
  getConn() {
    return firebase.database()
  }
  async create(ref, option) {
    try {
      const conn = this.getConn()
      await conn.ref(ref).set(option)
      return option
    } catch (error) {
      const customError = new ExtendedError(
        ERROR_CODE_MAP.DatabaseCrudError.id,
        {
          error,
          method: 'create',
          ref,
        }
      )
      customError.print()
      throw customError
    }
  }
  async read(ref) {
    try {
      const conn = this.getConn()
      const snapshot = await conn.ref(ref).once('value')
      return snapshot.val()
    } catch (error) {
      const customError = new ExtendedError(
        ERROR_CODE_MAP.DatabaseCrudError.id,
        {
          error,
          method: 'read',
          ref,
        }
      )
      throw customError
    }
  }
  async filterByChildProp(ref, key, value) {
    try {
      const conn = this.getConn()
      const snapshot = await conn
        .ref(ref)
        .orderByChild(key)
        .equalTo(
          _.isBoolean(value) || _.isNumber(value) ? value : value.toString()
        )
        .once('value')
      return snapshot.val()
    } catch (error) {
      const customError = new ExtendedError(
        ERROR_CODE_MAP.DatabaseCrudError.id,
        {
          error,
          method: 'filterByChildProp',
          ref,
        }
      )
      customError.print()
      throw customError
    }
  }
  async update(ref, option) {
    try {
      const conn = this.getConn()
      await conn.ref(ref).update(option)
      return option
    } catch (error) {
      const customError = new ExtendedError(
        ERROR_CODE_MAP.DatabaseCrudError.id,
        {
          error,
          method: 'update',
          ref,
        }
      )
      customError.print()
      throw customError
    }
  }
  push(ref, option) {
    try {
      const conn = this.getConn()
      return conn.ref(ref).push(option)
    } catch (error) {
      const customError = new ExtendedError(
        ERROR_CODE_MAP.DatabaseCrudError.id,
        {
          error,
          method: 'push',
          ref,
        }
      )
      customError.print()
      throw customError
    }
  }
  async delete(ref) {
    try {
      const conn = this.getConn()
      await conn.ref(ref).remove()
    } catch (error) {
      const customError = new ExtendedError(
        ERROR_CODE_MAP.DatabaseCrudError.id,
        {
          error,
          method: 'delete',
          ref,
        }
      )
      customError.print()
      throw customError
    }
  }
  async checkIsExist(ref) {
    try {
      const conn = this.getConn()
      const snapshot = await conn.ref(ref).once('value')
      return snapshot.exists()
    } catch (error) {
      const customError = new ExtendedError(
        ERROR_CODE_MAP.DatabaseCrudError.id,
        {
          error,
          method: 'checkIsExist',
          ref,
        }
      )
      customError.print()
      throw customError
    }
  }
}

const databaseCrud = new DatabaseCrud()

export default databaseCrud
