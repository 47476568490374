import { useEffect, useState } from 'react'
import credential from '../module/controller/Credential/credential'

export default function useUser(initialState = credential.user) {
  const [user, setUser] = useState(initialState)
  useEffect(() => {
    const callback = (user) => {
      setUser(user ? { ...user } : null)
    }
    credential.on('userChange', callback)
    return () => credential.off('userChange', callback, false)
  }, [])
  return user
}
