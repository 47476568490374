import React, { Suspense } from 'react'
import { HomePageLoading } from '../components/Skeletons/CustomSkeletons'
import { importPage } from './util'

const config = [
  {
    path: '/admin',
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "Admin" */ '../page/admin/Admin')
      )
    ),
  },
  {
    path: '/auth',
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "Auth" */ '../page/auth/Auth')
      )
    ),
  },
]
if (process.env.REACT_APP_PRODUCTION === 'false') {
  config.push({
    path: '/test',
    component: React.lazy(() =>
      importPage(() =>
        import(/* webpackChunkName: "Test" */ '../page/test/Test')
      )
    ),
  })
}
const routes = config.map(({ component: Component, ...other }) => ({
  ...other,
  component: (props) => (
    <Suspense fallback={<HomePageLoading />}>
      <Component {...props} />
    </Suspense>
  ),
}))
export default routes
