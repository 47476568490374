import './module/firebase.init'
import React, { useState, useEffect, createContext } from 'react'
import ReactDOM from 'react-dom'
import Main from './Main'
import './Main.scss'
import { IntlProvider } from 'react-intl'
import flatten from 'flat'
import en from './assets/i18n/en'
import zh from './assets/i18n/zh-tw'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
import initLogger from './module/logger'

const ga = firebase.analytics()
initLogger()
export const LocaleContext = createContext()
const Root = () => {
  const [locale, setLocale] = useState('zh-TW')
  let messages

  useEffect(() => {
    window.APP_VERSION = process.env.REACT_APP_VERSION
  }, [])

  switch (locale) {
    case 'zh-TW':
      messages = zh
      break
    case 'en':
      messages = en
      break
    default:
      messages = zh
      break
  }
  return (
    <React.StrictMode>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <IntlProvider
          messages={flatten(messages)}
          locale={locale}
          key={locale}
          defaultLocale="zh-TW"
        >
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Main setLocale={setLocale} />
          </MuiPickersUtilsProvider>
        </IntlProvider>
      </LocaleContext.Provider>
    </React.StrictMode>
  )
}
ReactDOM.render(<Root />, document.getElementById('root'))
