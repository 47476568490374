import { map } from 'lodash'
import customFetch from './fetch'

/**
 * 最基本的下載檔案
 * @param {string} content - anchor 元素 href 的值，可以是 URL、Data URI、Blob URL
 * @param {string} filename - 檔案名稱
 */
export const downloadFile = (() => {
  const link = document.createElement('a')
  document.body.appendChild(link)

  return (content, filename = '', isEncoded = false) => {
    const encodedUri = isEncoded ? content : encodeURI(content)

    link.setAttribute('href', encodedUri)
    link.setAttribute('download', filename)
    link.click()
  }
})()
/**
 * @typedef {Object} Column
 * @property {string} title - 標題
 * @property {string} field - 欄位名稱
 * @property {function} [render] - 渲染函式
 */
/**
 * 取得 CSV 內容陣列
 * @param {Column[]} column 欄位陣列
 * @param {object[]} dataList 資料陣列
 * @returns
 */
export function getCsvBodyArray(column, dataList) {
  const head = map(column, 'title')
  const body = dataList.map((data) =>
    column.map(({ field, render }) =>
      render ? render(data[field], data) : data[field] ?? ''
    )
  )
  return [head, ...body]
}

/**
 * 下載 CSV（透過 Data URI）
 * @param {string[][]} contentArray - 內容陣列
 * @param {string} filename
 */
export function downloadCsv(contentArray, filename) {
  const content =
    'data:text/csv;encoding=big5,' +
    contentArray.map((arr) => arr.join(',')).join('\n')
  downloadFile(content, `${filename}.csv`)
}
/**
 * 下載 Blob
 * @param {blob} blob
 * @param {string} filename
 */
export function downloadFromBlob(blob, filename) {
  const url = URL.createObjectURL(blob)
  downloadFile(url, filename)
  URL.revokeObjectURL(url)
}
/**
 * 先透過 AJAX 取得檔案再下載
 * @param {string} url - API URL
 * @param {string} filename
 */
export async function downloadFromApi(url, filename) {
  const resp = await customFetch(url)
  // Storage API 須設定 CORS 才能取得 Content-Disposition header
  const contentDisposition = resp.headers.get('Content-Disposition')
  const fileNameFromHeader =
    contentDisposition?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1] ??
    ''
  const blob = await resp.blob()
  downloadFromBlob(blob, filename || fileNameFromHeader)
}
